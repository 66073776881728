import {Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

export const ICONS = [
  {name: 'apps', path: 'apps.svg'},
  {name: 'award', path: 'award.svg'},
  {name: 'bullhorn', path: 'bullhorn.svg'},
  {name: 'calendar', path: 'calendar.svg'},
  {name: 'chart-bar', path: 'chart-bar.svg'},
  {name: 'chevron-right', path: 'chevron-right.svg'},
  {name: 'circle-user', path: 'circle-user.svg'},
  {name: 'construction', path: 'construction.svg'},
  {name: 'exclamation-circle', path: 'exclamation-circle.svg'},
  {name: 'financials', path: 'financials.svg'},
  {name: 'handshake', path: 'handshake.svg'},
  {name: 'kabob_horiz', path: 'kabob-horizontal.svg'},
  {name: 'learn-more', path: 'learn-more.svg'},
  {name: 'link', path: 'link.svg'},
  {name: 'logo', path: 'logo.svg'},
  {name: 'logout', path: 'logout.svg'},
  {name: 'mail', path: 'mail.svg'},
  {name: 'plus-square', path: 'plus-square.svg'},
  {name: 'portal', path: 'portal.svg'},
  {name: 'profile', path: 'profile.svg'},
  {name: 'reports', path: 'reports.svg'},
  {name: 'search', path: 'search.svg'},
  {name: 'settings', path: 'settings.svg'},
  {name: 'signs-post', path: 'signs-post.svg'},
  {name: 'sliders', path: 'sliders.svg'},
  {name: 'support', path: 'support.svg'},
  {name: 'tick-square', path: 'tick-square.svg'},
  {name: 'user', path: 'user.svg'},
  {name: 'users-solid', path: 'users-solid.svg'},
  {name: 'users', path: 'users.svg'}
];

const CCARE_ICONS = [
  {name: 'ccare-logo', path: 'ccare/logo.svg'}
];

const ALLY_ICONS = [
  {name: 'ally-logo', path: 'ally/logo.svg'}
];

const PPAY_ICONS = [
  {name: 'powerpay-logo', path: 'powerpay/logo.svg'}
];

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private ICON_ASSETS_PATH = '/assets/img/icons/';

  constructor(private iconReg: MatIconRegistry, private sanitizer: DomSanitizer) {
  }

  registerIcons() {
    const icons = ICONS.concat(CCARE_ICONS, ALLY_ICONS, PPAY_ICONS);
    for (const icon of icons) {
      this.iconReg.addSvgIcon(icon.name, this.sanitizeIcon(icon.path));
    }
  }

  sanitizeIcon(iconPath: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.ICON_ASSETS_PATH + iconPath);
  }
}
