<div class="page-modal-content">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" class="py-2 my-5"></mat-progress-bar>
  <div *ngIf="!loading">
    <h2 class="patient">Patient Information</h2>
    <p class="description">Patient ID: {{info.patientID}}</p>
    <div class="section patient">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <p class="label">Name</p>
          <p class="value">{{info.firstName}} {{info.lastName}}</p>
          <p class="label">Mobile Phone Number</p>
          <p class="value">{{info.phoneNumber}}</p>
          <p class="label">Address</p>
          <p class="value">
            <span>{{info.address}}</span><br/>
            <span *ngIf="info.address2">{{info.address2}}<br/></span>
            <span class="me-2">{{info.city}}</span>
            <span class="me-2">{{info.state}}</span>
            <span class="me-2">{{info.zipCode}}</span>
          </p>
        </div>
        <div class="col-sm-12 col-md-6 p-0">
          <p class="label">Email</p>
          <p class="value" [title]="info.emailAddress">{{info.emailAddress}}</p>
          <p class="label">Date Of Birth</p>
          <p class="value">{{info.dOB | date:'MM/dd/yyyy'}}</p>
          <p class="label" *ngIf="info.sSN">SSN</p>
          <p class="value" *ngIf="info.sSN">***-**-{{ info.sSN | slice: -4 }}</p>
        </div>
      </div>
    </div>
    <h2 class="procedure">Loan Information</h2>
    <div class="section procedure">
      <!--      <div class="item">-->
      <!--        <div class="label">Amount to be Financed:</div>-->
      <!--        <div class="value">${{info.loanAmtInitial}}</div>-->
      <!--      </div>-->
      <!--      <div class="item" *ngIf="info.downPayment">-->
      <!--        <div class="label">Down Payment:</div>-->
      <!--        <div class="value">${{info.downPayment}}</div>-->
      <!--      </div>-->
      <div class="item">
        <div class="label">Amount financed:</div>
        <div class="value">${{info.loanAmt}}</div>
      </div>
      <div class="item" *ngIf="!isAlly">
        <div class="label">Down Payment:</div>
        <div class="value">${{info.downPayment}}</div>
      </div>
      <div class="item" *ngIf="info.serviceDate">
        <div class="label">Service date:</div>
        <div class="value">{{info.serviceDate | date:'MM/dd/yyyy'}}</div>
      </div>
      <!--      <div class="item" *ngIf="info.serviceProcedure">-->
      <!--        <div class="label">Procedures:</div>-->
      <!--        <div class="value">{{info.serviceProcedure}}</div>-->
      <!--      </div>-->
      <div class="item" *ngIf="info.financedBy">
        <div class="label">Financing By:</div>
        <div class="value">{{info.financedBy}}</div>
      </div>
    </div>
    <div class="actions">
      <button mat-button mat-dialog-close class="button button-primary">Close</button>
    </div>
  </div>
</div>

