import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-error-banner',
  templateUrl: './error-banner.component.html',
  styleUrls: ['./error-banner.component.scss']
})
export class ErrorBannerComponent implements OnInit {
  @Input() message = '';
  @Input() type = 'error';

  constructor() {
  }

  ngOnInit(): void {
    this.message = typeof this.message === 'string' ? this.message : 'There is something wrong with your application';
  }
}
