import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditAppModalComponent } from 'src/app/components/audit-app-modal/audit-app-modal.component';
import { CancelLoanModalComponent } from 'src/app/components/cancel-loan-modal/cancel-loan-modal.component';
import { PatientInfoModalComponent } from 'src/app/components/patient-info-modal/patient-info-modal.component';
import { ResendInviteModalComponent } from 'src/app/components/resend-invite-modal/resend-invite-modal.component';
import { AppStorageKeys } from 'src/app/configs/app-storage-keys';
import { BopApplicationItem } from 'src/app/models/application-item';
import { BranchLocationModel } from 'src/app/models/branch-location-model';
import { BranchModel } from 'src/app/models/branch-model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MainService } from 'src/app/services/main.service';
import { select, Store } from '@ngrx/store';
import { getCurrentRoute } from '../../store/router/router.selector';
import { AppState } from '../../store/app.state';
import { forkJoin, of } from 'rxjs';
import { TransferLocationComponent } from '../../components/transfer-location/transfer-location.component';
import { SuccessModalComponent } from '../../components/shared/success-modal/success-modal.component';
import { UserRoleService } from 'src/app/services/user-role.service';

@Component({
  selector: 'app-applications-page',
  templateUrl: './applications-page.component.html',
  styleUrls: ['./applications-page.component.scss']
})
export class ApplicationsPageComponent implements OnInit, OnDestroy {
  hasBackdrop = true;
  loading = true;
  branch: BranchModel | null = null;
  customerGuid: string | null = "";
  branchGuid: string | null = "";
  private subscriptions: { [key: string]: any } = {};
  error: string = '';

  displayedColumns: string[] = ['lastName', 'firstName', 'financedBy', 'patientID', 'startDate', 'status', 'loanStatusDesc'];

  rows: number = 15;
  first: number = 0;
  searchFilter: string = "";
  applications: BopApplicationItem[] = [];
  singleLocationOnly = false;
  canTransferApp = false;

  constructor(public dialog: MatDialog,
    private apiService: ApiService,
    private customerService: CustomerService,
    private main: MainService,
    private title: Title,
    private roleService: UserRoleService,
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store<AppState>,) {
    this.title.setTitle("Covered Account");
  }

  ngOnInit(): void {
    localStorage.removeItem(AppStorageKeys.bopAppsLastSearchStorageKey);
    this.customerGuid = this.route.snapshot.params['customerGuid'];
    this.branchGuid = this.route.snapshot.params['branchGuid'];
    this.customerService.setCustomerGuid(this.customerGuid);
    this.customerService.setBranchGuid(this.branchGuid || "");
    this.loading = true;

    this.subscriptions.routerSelector = this.store$
      .pipe(select(getCurrentRoute))
      .subscribe((route: any) => {
        this.customerGuid = route.params.customerGuid;
        this.branchGuid = route.params.branchGuid;
        this.singleLocationOnly = route.queryParams.single === 'true';
        forkJoin([
          this.getApplications(),
          this.customerService.getBranchWithParams(this.customerGuid as string, this.branchGuid as string),
          this.customerService.setCustomerSettingWithParams(this.customerGuid as string, this.branchGuid as string)
        ]).subscribe((results) => {
        });
      });

    this.customerService.branch$.subscribe((branch) => {
      if (branch && branch.branchGuid)
        this.branch = branch;
    });

    this.main.setPageWidth(false);
    this.main.refreshApps$.subscribe(refresh => {
      if (refresh) {
        this.getApplications();
      }
    });
    this.roleService.userRoles$.subscribe(_roles => {
      this.canTransferApp = this.roleService.hasRole('BOP_APP_MENU_TRANSFER_APPLICATION');
    });
  }

  getApplications(resetSearch: boolean = true) {
    this.apiService.get(`app/bop-applications/${this.customerGuid}/${this.branchGuid}`)
      .subscribe((res: BopApplicationItem[]) => {
        if (resetSearch) {
          this.searchFilter = '';
        }
        res.forEach(r => r.startDate = new Date(r.startDate));
        this.applications = res.sort((a,b) => { return new Date(a.startDate) < new Date(b.startDate) ? 1 : -1 });
        this.loading = false;
      },
        (err: any) => {
          this.loading = false;
        }
      );

    return of([]);
  }

  getStatusDesc(statusId: string, desc: string) {
    if (statusId == "8")
      return desc;

    if (statusId == "16"
      || statusId == "201"
      || statusId == "246")
      return desc;

    return 'In Progress';
  }

  doMenu(app: BopApplicationItem, action: string) {
    switch (action) {
      case 'info':
        this.showPatientInfo(app.patientGUID);
        break;
      case 'resend':
        this.resendInvite(app.patientGUID);
        break;
      case 'adjustment':
        this.showAdjustment(app.patientGUID);
        break;
      case 'cancel':
        this.cancelLoan(app.patientGUID);
        break;
      case 'audit':
        this.auditApp(app.patientGUID);
        break;
      case 'transfer':
        this.transferApp(app);
        break;
      default:
      // code block
    }
  }

  openContract(url: string) {
    window.open(url, "_blank");
  }

  showPatientInfo(targetGuid: string) {
    this.main.addClassToBody();
    const dialogRef = this.dialog.open(PatientInfoModalComponent, {
      data: { patientGuid: targetGuid, branchGuid: this.branchGuid, customerGuid: this.customerGuid },
      width: '640px',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'app-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.main.removeClassFromBody();
    });
  }

  transferApp(app: BopApplicationItem) {
    this.main.addClassToBody();
    this.error = '';
    const dialogRef = this.dialog.open(TransferLocationComponent, {
      data: { customerGuid: this.customerGuid, selected: this.branchGuid, dialogType: 'transfer' },
      width: '640px',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'app-dialog'
    });

    dialogRef.afterClosed().subscribe((loc: BranchLocationModel) => {
      this.main.removeClassFromBody();
      if (loc && loc.branchGUID != this.branchGuid) {
        this.main.showLoading();
        const model = {
          CustomerGuid: this.customerGuid,
          CampaignGuid: this.customerGuid,
          TargetGuid: app.patientGUID,
          Value: loc.branchGUID
        };
        const body = JSON.stringify(model);
        this.apiService.postStringResponse(`app/transfer-app`, body)
          .subscribe((res: any) => {
            this.main.dismissLoading();
            if (res && res === 'success') {
              this.showSuccessModal();
              this.removeApplication(app);
            } else {
              this.error = "Oops... Error occurred";
            }
          },
            (err: any) => {
              this.main.dismissLoading();
              const msg = err.error.text || "Error occurred";
              this.error = "Oops... " + msg;
            }
          );
      }
    });
  }

  showSuccessModal() {
    this.main.addClassToBody();
    const dialogRef = this.dialog.open(SuccessModalComponent, {
      data: { header: 'Success', message: 'Application Transferred OK!' },
      width: '640px',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'app-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.main.removeClassFromBody();
      this.handleResult(result);
    });
  }

  resendInvite(targetGuid: string) {
    this.main.addClassToBody();
    const dialogRef = this.dialog.open(ResendInviteModalComponent, {
      data: {
        patientGuid: targetGuid,
        branchGuid: this.branchGuid,
        customerGuid: this.customerGuid,
        branchState: this.branch.state
      },
      width: '640px',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'app-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.main.removeClassFromBody();
    });
  }

  showAdjustment(targetGuid: string) {
    this.router.navigate([`home/${this.customerGuid}/admin/${this.branchGuid}/applications/${targetGuid}`]);
  }

  auditApp(targetGuid: string) {
    this.main.addClassToBody();
    const dialogRef = this.dialog.open(AuditAppModalComponent, {
      data: { patientGuid: targetGuid, branchGuid: this.branchGuid, customerGuid: this.customerGuid },
      width: '640px',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'app-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.main.removeClassFromBody();
      this.handleResult(result);
    });
  }

  cancelLoan(targetGuid: string) {
    this.main.addClassToBody();
    const dialogRef = this.dialog.open(CancelLoanModalComponent, {
      data: { patientGuid: targetGuid, branchGuid: this.branchGuid, customerGuid: this.customerGuid },
      width: '640px',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'app-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.main.removeClassFromBody();
      this.handleResult(result);
    });
  }

  notValid(field: string, val: string) {
    if (!val) return false;
    if (!field) return true;
    return field.trim().toLocaleUpperCase().indexOf(val.trim().toLocaleUpperCase()) === -1 ? true : false;
  }

  handleResult(result: string | null = null) {
    if (result === 'updated' || result === 'completed') {
      this.getApplications(false);
    }
  }

  disableMenuLink(cond1: string, cond2: string) {
    if (cond1 && cond2) {
      if (cond1.toUpperCase() === "TRUE" && cond2.toUpperCase() === "TRUE")
        return false;
    }

    return true;
  }

  private removeApplication(app: BopApplicationItem) {
    for (var i = 0; i < this.applications.length; i++) {
      if (this.applications[i].patientGUID === app.patientGUID) {
        this.applications = this.applications.splice(i, 1);
        break;
      }
    }
  }

  onCancel() {
    this.router.navigate([`/home/` + this.customerGuid + '/admin']);
  }

  ngOnDestroy(): void {
    try {
      if (this.subscriptions && this.subscriptions.routerSelector)
        this.subscriptions.routerSelector.unsubscribe();
    } catch (error) {
      console.error(`Error applications page.OnDestroy: ${JSON.stringify(error)}`);
    }
  }
}
