import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PatientInfoModalData} from 'src/app/models/app-modal-data';
import {ApiService} from 'src/app/services/api.service';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faCircleCheck} from '@fortawesome/free-regular-svg-icons';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-application-start',
  templateUrl: './application-start.component.html',
  styleUrls: ['./application-start.component.scss']
})
export class ApplicationStartComponent implements OnInit {
  step = 1;
  iconCheck = faCircleCheck as IconProp;
  formObj: any;
  amountFinanced = 0;
  submitting = false;
  error = '';
  startUrl = '';

  constructor(public dialogRef: MatDialogRef<ApplicationStartComponent>,
              @Inject(MAT_DIALOG_DATA) public data: PatientInfoModalData,
              private apiService: ApiService,
              private authService: AuthService) {
    this.onAction(data.action);
  }

  ngOnInit(): void {
    this.error = "";
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onAction(action: BopAppActions) {
    this.error = "";
    this.formObj = action.formObject;
    this.amountFinanced = action.amount;
    this.step = action.step;
    if (action.step <= 0) {
      this.dialogRef.close('canceled');
    }
  }

  stayHere() {
    this.authService.logout(this.startUrl);
  }

  goStep(action: BopAppActions) {
    this.error = "";
    if (action.step === 3) {
      this.submitting = true;
      var json = JSON.stringify(JSON.stringify(this.formObj));
      var path = '';
      if (this.data.customerGuid) {
        path = `app/submit-app/${this.data.customerGuid}/${this.data.branchGuid}`;
      }

      this.apiService.post(path, json)
        .subscribe((res: any) => {
            if (res) {
              if (res.COACK && res.COACK.ERRSTATUS && res.COACK.ERRSTATUS.ERRORNUM == 0) {
                this.startUrl = (this.formObj.LeadInformation.SendAppLink.toLowerCase() === 'device') && res.COACK.ERRSTATUS.STARTURL;
                if (this.startUrl) {
                  this.stayHere();
                } else {
                  this.step = action.step;
                  this.submitting = false;
                }

              } else {
                this.amountFinanced = action.amount;
                this.error = res.COACK.ERRSTATUS.ERRORDESCRIPTION || "Error Occurred";
                this.submitting = false;
              }
            }
          },
          (err: any) => {
            console.error(err);
            this.submitting = false;
          }
        );
    } else if (action.type === 'close') {
      this.close();
    } else {
      this.step = action.step;
      this.onAction(action);
    }
  }

  close() {
    this.dialogRef.close('completed');
  }
}

export interface BopAppActions {
  step: number;
  amount: number;
  formObject: Object;
  type?: string;
}
