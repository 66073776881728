import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { CustomerModel } from 'src/app/models/customer-model';
import { Validation } from 'src/app/models/validation';
import { CustomerService } from 'src/app/services/customer.service';
import { LookupService } from 'src/app/services/lookup.service';
// import {SmartyStreetsService} from '../../../services/vendor/smartystreets.service';

interface ISmartSreetsAddress {
  street_line?: string;
  secondary?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

@Component({
  selector: 'app-cc-input',
  templateUrl: './cc-input.component.html',
  styleUrls: ['./cc-input.component.scss']
})
export class CcInputComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() isRequired: boolean = false;
  @Input() readonly = false;
  @Input() maxLen: number = 100;
  @Input() id?: string = '';
  @Input() label: string = "";
  @Input() type: string = "text";
  @Input() placeholder: string = "placeholder";
  @Input() val: string = "";
  @Input() classes = "";
  @Input() isNumber = false;
  @Input() autocomplete = false
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  @Input() mask = '';
  @Input() errorMessage: string = "This field is invalid";
  
  blockPOBox: boolean = false;
  addressOptions: ISmartSreetsAddress[] = [];
  poValidaiton: Validation;

  toastKey: string = 'ccInputToast';
  toastPos: string = 'center';
  preventDupMsg: boolean = false;
  constructor(
    // private smartyStreetsService: SmartyStreetsService,
    private lookupService: LookupService,
    private messageService: MessageService,
    private customerService: CustomerService
    ) {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
    // if (this.autocomplete) {      
    //   this.customerService.customer$.subscribe((cust: CustomerModel | null) => {
    //     if (cust) {
    //       this.blockPOBox = cust.blockPOBoxAddress.toLowerCase() == 'true';
    //     }
    //   });
    //   this.lookupService.getValidationByName('No PO Box')
    //   .then((validations: Validation[]) => {
    //     if (validations && validations.length > 0) {
    //       this.poValidaiton = validations[0];
    //     }
    //   });
    //   this.group.controls[this.controlName].valueChanges.subscribe(value => {
    //     this.onAutoCompleteChange(value);
    //   });
    // }
  }

  onAutoCompleteChange(term: string) {
    // if (!term || term.trim() === "") {      
    //   this.addressOptions = [];
    //   return;
    // }

    // let blockInput: boolean = this.verifyPOBox(term);
    
    // if (blockInput) {
    //   this.group.controls["address1"].setValue("");
    //   this.group.controls['address2'].setValue("");
    //   this.group.controls['city'].setValue("");
    //   this.group.controls['state'].setValue("");
    //   this.group.controls['zip'].setValue("");
    //   return;
    // }

    // this.smartyStreetsService.fetchAddressesPro(term).subscribe((data: any) => {
    //   this.addressOptions = data.suggestions as any[];
    // }, () => {
    //   this.addressOptions = [];
    // });
  }

  onAutoCompleteOptionSelected(address: ISmartSreetsAddress, skipAddressLine1?: boolean) {
    // if (!skipAddressLine1) {
    //   let blockInput: boolean = this.verifyPOBox(address.street_line, false);
    //   if (!blockInput) this.group.controls['address1'].setValue(address.street_line);
    // }
    // this.group.controls['address2'].setValue(address.secondary);
    // this.group.controls['city'].setValue(address.city);
    // this.group.controls['state'].setValue(address.state);
    // this.group.controls['zip'].setValue(address.zipcode);
  }

  onAutoCompleteBlur() {
    // const addressLine1 = this.group.controls['address1'].value;
    // const selectedOptions = this.addressOptions.filter(
    //   (option: ISmartSreetsAddress) => option.street_line === addressLine1
    // );
    // if (!selectedOptions.length) {
    //   this.onAutoCompleteOptionSelected({}, true);
    // }
  }

  verifyPOBox(term: string, showMsg: boolean = true): boolean {
    return false;
    // if (this.blockPOBox && this.poValidaiton && this.poValidaiton.regularExpression) {
    //   const regEx: RegExp = new RegExp(this.poValidaiton.regularExpression);
    //   let testTerm = regEx.test(term);
    //   if (!testTerm && showMsg) {
    //       this.messageService.add({
    //         severity: 'error',
    //         key: this.toastKey,
    //         summary: 'Not Allowed',
    //         detail: 'Use of "PO Box" is not allowed'
    //       });
    //   }
    //   return !testTerm;
    // }
    // else {
    //   return false;
    // }
  }

}
