import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PatientAppDetails} from '../../models/patient-app-details';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {faCheckCircle, faXmarkCircle} from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {CustomerService} from '../../services/customer.service';
import {MainService} from '../../services/main.service';
import {ApiService} from '../../services/api.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {getCurrentRoute} from '../../store/router/router.selector';
import {forkJoin} from 'rxjs';
import {getDateObjFromString, maskDecimal} from '../../services/util.service';
import {BranchModel} from '../../models/branch-model';
import {Router} from '@angular/router';
import {adjustServiceDateValidator} from '../../validators/service-date-validator';
import {MatDialog} from '@angular/material/dialog';
import {AdjustmentModalComponent} from '../../components/adjustment-modal/adjustment-modal.component';
import {LookupModel} from '../../models/lookup-model';

@Component({
  selector: 'app-adjust-application-page',
  templateUrl: './adjust-application-page.component.html',
  styleUrls: ['./adjust-application-page.component.scss']
})
export class AdjustApplicationPageComponent implements OnInit {
  @ViewChild('submitCta') submitBtn : ElementRef<HTMLButtonElement>;
  loading = true;
  info: PatientAppDetails = new PatientAppDetails;
  newAmount = new UntypedFormControl(0);
  disabled = false;
  iconCheck = faCheckCircle as IconProp;
  iconErr = faXmarkCircle as IconProp;
  error = '';
  minDate: Date = new Date();
  maxDate: Date = new Date();
  serviceDateEditable = false;
  minLoanAmt = '1';
  maxLoanAmt = '1000000';
  customerGuid = '';
  branchGuid = '';
  patientGuid = '';
  private subscriptions: { [key: string]: any } = {};
  branch: BranchModel | null = null;
  adjustForm = this.fb.group({
    newAmount: ['', Validators.required],
    serviceDate: [new Date(), [
      Validators.required
    ]]
  }, {updateOn: 'blur'});

  reasons: LookupModel[] = [];

  constructor(public dialog: MatDialog,
              private customerService: CustomerService,
              private fb: UntypedFormBuilder,
              private main: MainService,
              private apiService: ApiService,
              private store$: Store<AppState>,
              private router: Router) {
  }

  ngOnInit(): void {
    this.subscriptions.routerSelector = this.store$
      .pipe(select(getCurrentRoute))
      .subscribe((route: any) => {
        this.customerGuid = route.params.customerGuid;
        this.branchGuid = route.params.branchGuid;
        this.patientGuid = route.params.patientGuid;
        forkJoin([
          this.customerService.getSelectedBranch(this.customerGuid as string, this.branchGuid as string),
          this.customerService.getCustomerSettings(this.customerGuid as string, this.branchGuid as string),
          this.getDetails()
        ]).subscribe((results) => {
          this.branch = results[0] as BranchModel;
          this.info = results[2] as PatientAppDetails;
          this.getReasons();
          this.initServiceDate(results[1]);
          this.loading = false;
        });
      });

    this.maxDate.setDate(this.maxDate.getDate() + 90);
  }

  getReasons() {
    this.loading = true;
    this.apiService.get(`app/bop-lookups/${this.customerGuid}`)
      .subscribe((res: any) => {
          this.loading = false;
          this.reasons = res.cancelReasons || [];
        },
        (err: any) => {
          console.error(err);
          this.loading = false;
        }
      );
  }

  getDetails(): any {
    return this.apiService.get(`app/patient-app-details/${this.customerGuid}/${this.branchGuid}/${this.patientGuid}`);
  }

  initServiceDate(customerSettings?: any) {
    // this.minLoanAmt = customerSettings.minLoanAmt;
    this.maxLoanAmt = customerSettings.maxLoanAmt;
    const existingServiceDate = getDateObjFromString(this.info.serviceDate);
    const maxServiceDate = getDateObjFromString(this.info.serviceDateMax);
    this.serviceDateEditable = new Date(this.info.serviceDate) > new Date();
    this.minDate = new Date(existingServiceDate);
    this.maxDate = new Date(maxServiceDate);
    this.adjustForm = this.fb.group({
      newAmount: [this.info.loanAmtInitial, Validators.required],
      serviceDate: [existingServiceDate, [
        Validators.required,
        adjustServiceDateValidator
      ]],
      reason: ['', Validators.required]
    }, {updateOn: 'blur'});
  }

  onAmountChange() {
    this.error = '';
    let val = this.adjustForm.controls['newAmount'].value;
    if (val) {
      val = val.replace(',', '');
      const masked = maskDecimal(val);
      if (masked != val) {
        this.adjustForm.controls['newAmount'].patchValue(masked);
      }
    }
  }

  onSubmit(): boolean {
    this.error = '';
    if (!this.hasChanges()) {
      this.error = 'Change either Service date or financed amount';
      return false;
    }

    const newAmt = this.cleanAmount(this.adjustForm.controls.newAmount.value);
    const min = this.cleanAmount(this.minLoanAmt.toString());
    if (newAmt > this.cleanAmount(this.info.loanAmtInitial)) {
      this.error = "New amount is greater than current finance amount";
      return false;
    } else if (newAmt < min) {
      this.error = `Amount must be between $${maskDecimal(this.minLoanAmt.toString())} and $${maskDecimal(this.info.loanAmtInitial.toString())}`;
      return false;
    } else {
      this.adjustForm.controls.newAmount.setErrors(null);
      this.adjustForm.updateValueAndValidity();
    }

    if (this.adjustForm.invalid) {
      this.adjustForm.controls.serviceDate.markAsTouched();
      this.adjustForm.controls.reason.markAsTouched();
      this.adjustForm.updateValueAndValidity();
      return false;
    }

    const selectedReasonId = this.adjustForm.controls.reason.value;
    if (this.serviceDateEditable && this.adjustForm.invalid) {
      this.adjustForm.controls.serviceDate.markAsTouched();
      return false;
    }

    this.main.addClassToBody();
    const dialogRef = this.dialog.open(AdjustmentModalComponent, {
      data: {
        patientGuid: this.patientGuid,
        branchGuid: this.branchGuid,
        customerGuid: this.customerGuid,
        newAmount: this.cleanAmount(this.adjustForm.controls.newAmount.value),
        serviceDate: new Date(this.adjustForm.controls.serviceDate.value),
        DoctorName: this.info.doctorName,
        financingBy: this.info.financedBy,
        patientInfo: this.info,
        reasonDesc: this.reasons.find((reason: LookupModel) => reason.id === selectedReasonId)!.desc,
        reasonId: Number.parseInt(selectedReasonId)
      },
      width: '640px',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'app-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.main.removeClassFromBody();
      this.handleResult(result);
    });
    return true;
  }

  getCurrentAmount() {
    return this.cleanAmount(this.info.loanAmtInitial);
  }

  handleResult(result: string | null = null) {
    if (result === 'updated' || result === 'completed') {
      this.onCancel();
    }
  }

  hasChanges() {
    var currAmount = this.getCurrentAmount();
    var newAmt = this.cleanAmount(this.adjustForm.controls.newAmount.value);
    var amtChanged = currAmount !== newAmt;
    //var amtChanged = false;
    var dtChanged = new Date(this.adjustForm.controls.serviceDate.value).setHours(0, 0, 0, 0) !== new Date(getDateObjFromString(this.info.serviceDate)).setHours(0, 0, 0, 0);
    return amtChanged || dtChanged;
  }

  cleanAmount(amt: string) {
    if (!amt) return 0;
    return Number(amt.replace(',', '').replace(' ', ''));
  }

  onCancel() {
    this.router.navigate([`/home/` + this.customerGuid + '/admin/' + this.branchGuid + '/applications']);
  }
}
