<div [formGroup]="group" class="app-date-picker {{klass}}"
     [ngClass]="{'special-input-container': isSpecial, 'form-control-container': !isSpecial}" *ngIf="group">
  <div class="error-message">
    <span *ngIf="control.touched && control.invalid"
          class="error text-danger">
      {{ errorMessage }}
    </span>
  </div>
  <div
    [ngClass]="{'special-input': isSpecial, 'form-floating': !isSpecial, 'error': control.touched && control.invalid}"
    class="date">
    <input class="form-control"
           #myinput
           matInput
           [matDatepicker]="picker"
           (keyup)="onKeyUp($event)"
           (beforeinput)="onKeyPress($event)"
           [formControlName]="controlName"
           (blur)="onBlur($event)"
           maxLength="10"
           [max]="maxDate"
           [min]="minDate"
           [placeholder]="placeholder"
           [ngClass]="group.controls[controlName].touched && group.controls[controlName]?.invalid ? 'is-invalid' : ''"
           type="tel"
    />
    <label *ngIf="label && !isSpecial" [for]="id"
           [ngClass]="{'required': isRequired}">{{label}}</label>
    <mat-icon svgIcon="calendar" (click)="picker.open()" tabindex="0"
              (keyup.enter)="picker.open()"></mat-icon>
    <mat-datepicker #picker></mat-datepicker>
  </div>
</div>
