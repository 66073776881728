import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {CustomerService} from './customer.service';
import {ReportItemModel} from '../models/report-item-model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient,
              private customerService: CustomerService,
              private api: ApiService) {
  }

  getReports(customerGuid?: string): Observable<ReportItemModel[]> {
    return this.api.get(`reports/customer-reports/` + customerGuid);
  }

  getEmbedConfig(endpoint: string): Observable<ConfigResponse> {
    return this.http.get<ConfigResponse>(endpoint);
  }
}

export interface ConfigResponse {
  Id: string;
  EmbedUrl: string;
  EmbedToken: {
    Token: string;
  };
}

export interface PowerbiToken {
  accessToken: string;
  idToken: string;
}

export interface EmbeddedReportConfig {
  embedReport: EmbedReport;
  embedToken: string;
}

export interface EmbedReport {
  embedUrl: string;
  reportId: string;
  reportName: string;
}
