import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, AuthError, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { CustomerService } from './services/customer.service';
import { MainService } from './services/main.service';
import { IconService } from './services/icon.service';
import { AppStorageKeys } from './configs/app-storage-keys';
import { ThemeService } from './services/theme.service';
import { Title } from '@angular/platform-browser';
import { UserRoleService } from './services/user-role.service';
import { ToastService } from './services/toast.service';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = "Test";
  isIframe = false;
  authenticated = false;
  showHideSidenav = true;
  fullWidth = false;
  containerClass = 'container';
  urlPath = '';
  showSelectProvider = false;
  providerName = '';
  private readonly _destroying$ = new Subject<void>();

  PortalName: string = 'Covered Portal';
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  toastKey: string = 'appCompToast';
  toastPos: string = 'bottom-center';
  toastSub: Subscription = {} as Subscription;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private main: MainService,
    private customerService: CustomerService,
    private authService: AuthService,
    private meta: Meta,
    private msalService: MsalService,
    private roleService: UserRoleService,
    private iconService: IconService,
    private themeService: ThemeService,
    private toastService: ToastService,
    private titleService: Title) {
    this.meta.addTag({ name: 'app_version', content: environment.appVersion }, true);
    this.setTitleAndFavIcon();
    this.themeService.loadTheme();
    const url = this.router.url;
    const arr = url.split('/');
    const customerGuid = arr[2];
    this.customerService.setCustomerGuid(customerGuid);
    this.roleService.loadUserRoles();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('/') !== -1) {
          const arr = event.url.split('/');
          this.urlPath = arr[1];
          this.showSelectProvider = this.urlPath === 'select-provider';
        }
      }
    });
  }

  setTitleAndFavIcon() {
    let hostname = window.location.hostname;
    if (hostname.indexOf('ally') > -1) {
      this.titleService.setTitle('Ally Bank Portal');
      this.favIcon.href = "/assets/images/icons/favicon-ally.png";
    } else if (hostname.indexOf('powerpay') > -1) {
      this.titleService.setTitle('PowerPay Portal');
      this.favIcon.href = "/assets/images/icons/favicon-powerpay.png";
    } else {
      this.titleService.setTitle('Covered Portal');
      this.favIcon.href = "/assets/images/icons/favicon.png";
    }
  }

  ngOnInit() {
    this.toastSub = this.toastService.toastPos$
      .subscribe((position: string) => {
        this.toastPos = position;
      });
    // Customize SVG icons, register and sanitize
    this.iconService.registerIcons();

    this.subscribeLoginFailure();
    this.subscribeLoggedIn();
    this.subscribeSilentLogin();
    this.isIframe = window !== window.parent && !window.opener;
    var id = this.activatedRoute.snapshot.queryParams['customerGuid'];
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.setAuthenticated();
      });

    this.main.fullWidth$.subscribe(w => {
      this.fullWidth = w;
      this.containerClass = w ? 'container-fluid' : 'container';
    })
  }

  setAuthenticated() {
    this.authenticated = this.msalService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this.toastSub.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  subscribeLoggedIn() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        var payload = <AuthenticationResult>result.payload;
        this.authService.authenticated(payload);
      });
  }

  subscribeSilentLogin() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        // var payload = <AuthenticationResult>result.payload;
        // this.authService.authenticated(payload);
      });
  }

  subscribeLoginFailure() {
    this.broadcastService.msalSubject$
      .subscribe((result: EventMessage) => {
        if (result && result.error) {
          var error = <AuthError>result.error;
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (error && error.errorMessage.indexOf('AADB2C90118') > -1) {
            this.authService.resetPassword();
          }

          if (error && error.errorMessage.indexOf('AADB2C90091') > -1) {
            this.setAuthenticated();
            if (!this.authenticated) {
              this.authService.login();
            }
          }
        }
      });
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      activeAccount = accounts[0];
    }
    if (activeAccount) {
      if (activeAccount.homeAccountId) {
        localStorage.setItem(AppStorageKeys.homeAccountId, activeAccount.homeAccountId);
      }
      this.authService.authenticatedAccount(activeAccount);
      this.msalService.instance.setActiveAccount(activeAccount);
    }
  }
}

