<div *ngIf="patientInfoForm">
  <div class="row">
    <div class="col-md-6 col-xs-12 col-lg-6">
      <app-cc-input
        controlName="firstName"
        [group]="patientInfoForm"
        label="First Name"
        [isRequired]="true"
        errorMessage="First Name is required">
      </app-cc-input>
    </div>
    <div class="col-md-6 col-xs-12 col-lg-6">
      <app-cc-input
        controlName="lastName"
        [group]="patientInfoForm"
        label="Last Name"
        [isRequired]="true"
        errorMessage="Last Name is required">
      </app-cc-input>
    </div>
    <div class="col-md-6 col-xs-12 col-lg-6">
      <app-cc-tel-input
        controlName="phone"
        [group]="patientInfoForm"
        label="Valid Cell Phone Number"
        [isRequired]="enforce18Over"
        errorMessage="Invalid Cell Phone Number">
      </app-cc-tel-input>
    </div>
    <div class="col-md-6 col-xs-12 col-lg-6">
      <app-cc-input
        controlName="email"
        [group]="patientInfoForm"
        label="Valid Email Address"
        type="email"
        [isRequired]="enforce18Over"
        errorMessage="Invalid Email Address">
      </app-cc-input>
    </div>
    <div class="col-md-6 col-xs-12 col-lg-6">
      <app-cc-date-picker
        controlName="dob"
        [group]="patientInfoForm"
        label="Date of Birth (MM/DD/YYYY)"
        placeholder="Date of Birth (MM/DD/YYYY)"
        [minDate]="dobMinDate"
        [maxDate]="dobMaxDate"
        errorMessage="Must be 18 years old"
      >
      </app-cc-date-picker>
    </div>
    <div class="col-md-6 col-xs-12 col-lg-6">
      <app-cc-input
        controlName="ssn"
        type="ssn"
        [group]="patientInfoForm"
        label="Social Security Number"
        [isNumber]="true"
        mask="000-00-0000"
        [isRequired]="false"
        errorMessage="Invalid SSN">
      </app-cc-input>
    </div>
    <div class="col-md-6 col-xs-12 col-lg-6">
      <app-cc-input
        controlName="address1"
        [group]="patientInfoForm"
        label="Street Address"
      >
      </app-cc-input>
    </div>
    <div class="col-md-6 col-xs-12 col-lg-6">
      <app-cc-input
        controlName="address2"
        [group]="patientInfoForm"
        label="Street Address 2">
      </app-cc-input>
    </div>
    <div class="col-md-6 col-xs-12 col-lg-6">
      <app-cc-input
        controlName="city"
        [group]="patientInfoForm"
        label="City">
      </app-cc-input>
    </div>
    <div class="col-md-6 col-xs-12 col-lg-6">
      <div class="state-zip">
        <div class="state">
          <app-cc-select
            controlName="state"
            [group]="patientInfoForm"
            type="states"
            klass="select state"
            label="State">
          </app-cc-select>
        </div>
        <div class="zip">
          <app-cc-input
            controlName="zip"
            [group]="patientInfoForm"
            label="Zipcode"
            [isNumber]="true"
            [maxLen]="5"
            [mask]="INPUT_FORMAT.zipCode.mask"
          >
          </app-cc-input>
        </div>
      </div>
    </div>
  </div>
</div>
