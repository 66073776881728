import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private themes: string[] = ["ally", "powerpay"];
  public Theme: string = "ccare";
  private defaultTheme: string = "ccare";

  constructor() { }

  loadTheme() {
    this.Theme = this.defaultTheme;
    let hostname = window.location.hostname;
    for (let i = 0; i < this.themes.length; i++) {
      const element = this.themes[i];
      if (hostname.indexOf(this.themes[i]) > -1) {
        this.Theme = this.themes[i];
        break;
      }        
    }
  }

  overrideTheme(theme: string) {
    this.Theme = this.themes.find(t => t.toLowerCase() === theme) || this.defaultTheme;
  }

  getHeaderComponentName(): string {
    return this.Theme + 'Header';
  }

  getFooterComponentName(): string {
    return this.Theme + 'Footer';
  }
}
