import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {LookupModel} from 'src/app/models/lookup-model';
import {LookupService} from 'src/app/services/lookup.service';

@Component({
  selector: 'app-cc-select',
  templateUrl: './cc-select.component.html',
  styleUrls: ['./cc-select.component.scss']
})
export class CcSelectComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() isRequired: boolean = false;
  @Input() id?: string;
  @Input() options: LookupModel[] = [];
  @Input() label: string = '';
  @Input() klass: string = '';
  @Output() changed = new EventEmitter<LookupModel>();
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  @Input() errorMessage: string = "This field is invalid";

  @Input()
  get type(): string {
    return this._type;
  }

  set type(type: string) {
    this._type = (type && type.trim()) || '';
    if (type === 'states') {
      this.options = this.lookupService.getStates();
      // this.options.unshift(new LookupModel('', 'State'));
    }
  }

  private _type = '';

  constructor(private lookupService: LookupService) {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
    this.group.controls[this.controlName].valueChanges.subscribe(value => {
      this.changed.emit(value);
    });
  }

}
