<div class="page-view breadcrumbs" *ngIf="branch?.branchId">
  <a tabindex="0" (click)="onCancel()" (keyup.enter)="onCancel()" href="javascript:void(0)">
    Select Location</a> <span>&gt;</span> <span>{{ branch?.branchName + '  - ' + branch?.branchId}}</span>
</div>
<div class="page-view__loading card" *ngIf="loading">
  <div class="card-body">
    <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
  </div>
</div>
<div class="page-view form" *ngIf="!loading">
  <div class="page-view__body application">
    <h2>Adjustment</h2>
    <p class="page-view__description text-center">
      Patient ID: {{info.patientID}}
    </p>
    <app-error-banner *ngIf="error"
                      [message]="error"></app-error-banner>
    <div class="section patient">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <p class="label">Name</p>
          <p class="value">{{info.firstName}} {{info.lastName}}</p>
          <p class="label">Mobile Phone Number</p>
          <p class="value">{{info.phoneNumber}}</p>
          <p class="label">Address</p>
          <p class="value">
            <span>{{info.address}}</span><br/>
            <span *ngIf="info.address2">{{info.address2}}<br/></span>
            <span class="me-2">{{info.city}}</span>
            <span class="me-2">{{info.state}}</span>
            <span class="me-2">{{info.zipCode}}</span>
          </p>
        </div>
        <div class="col-sm-12 col-md-6 p-0">
          <p class="label">Email</p>
          <p class="value">{{info.emailAddress}}</p>
          <p class="label">Date Of Birth</p>
          <p class="value">{{info.dOB | date:'MM/dd/yyyy'}}</p>
          <p class="label" *ngIf="info.sSN">SSN</p>
          <p class="value" *ngIf="info.sSN">***-**-{{ info.sSN | slice: -4 }}</p>
        </div>
      </div>
    </div>
    <div class="section procedure">
      <h3 class="text-center">Procedure Information</h3>
      <form [formGroup]="adjustForm" (ngSubmit)="submitBtn.nativeElement.focus(); onSubmit()">
        <fieldset>
          <div class="item financing-by" *ngIf="info.financedBy">
            <div class="label">Financing By</div>
            <div class="value">{{info.financedBy}}</div>
          </div>
          <div class="item">
            <div class="label">Current Financed Amount</div>
            <div class="value">${{info.loanAmtInitial}}</div>
          </div>
          <div class="item">
            <div class="label">New Amount to Be Financed</div>
            <div class="value">
              <div class="special-input-container">
                <div class="special-input amount"
                     [ngClass]="{'error': error, 'is-invalid': newAmount && newAmount.touched && newAmount.invalid ? 'is-invalid' : ''}"
                     [formGroup]="adjustForm"
                >
                  <p *ngIf="error" class="error-message">{{error}}</p>
                  <input
                    class="form-control"
                    placeholder="0.00"
                    formControlName="newAmount"
                    (blur)="onAmountChange()"
                    pattern="\d*"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="label">Service Date (optional)</div>
            <div *ngIf="serviceDateEditable" class="value">
              <app-cc-date-picker
                controlName="serviceDate"
                [group]="adjustForm"
                [addDays]="60"
                [isSpecial]="true"
                placeholder="MM/DD/YYYY"
                klass="adjust-application"
                message="Service Date must be in the future and must be between {{minDate.toLocaleDateString('en-029')}} and
                {{maxDate.toLocaleDateString('en-029')}}">
              </app-cc-date-picker>
            </div>
            <div *ngIf="!serviceDateEditable" class="value">{{info.serviceDate | date:'MM/dd/yyyy'}}</div>
          </div>
          <div class="item reasons">
            <div class="label">Reason for adjustment</div>
            <div class="value">
              <app-cc-select
                controlName="reason"
                [group]="adjustForm"
                type="reasons"
                klass="select reason"
                label="Reason for adjustment"
                [options]="reasons"
                [isRequired]="true"
              >
              </app-cc-select>
            </div>
          </div>
          <div class="page-view__actions">
            <button class="button button-primary" type="submit" #submitCta>ADJUST TREATMENT PLAN</button>
            <button class="button button-medium button-secondary" type="button" (click)="onCancel()">Cancel</button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</div>
