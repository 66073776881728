import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from '@azure/msal-angular';
import {HostNameCheckComponent} from './components/host-name-check/host-name-check.component';
import {ReportsPageComponent} from './pages/reports-page/reports-page.component';
import {ApplicationPageComponent} from './pages/application-page/application-page.component';
import {PatternsComponent} from './components/patterns/patterns.component';
import {LocationsPageComponent} from './pages/locations-page/locations-page.component';
import {ApplicationsPageComponent} from './pages/applications-page/applications-page.component';
import {LearnMoreComponent} from './pages/learn-more/learn-more.component';
import {SupportComponent} from './pages/support/support.component';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {SelectProviderPageComponent} from './pages/select-provider-page/select-provider-page.component';
import {AdjustApplicationPageComponent} from './pages/adjust-application-page/adjust-application-page.component';
import { ApplicantTransferComponent } from './pages/applicant-transfer/applicant-transfer.component';
import { RemittanceReportComponent } from './pages/remittance-report/remittance-report.component';

const routes: Routes = [
  {path: 'zHealthCheck-DO_NOT_DELETE', component: HostNameCheckComponent, pathMatch: 'full'},
  {path: 'select-provider', component: SelectProviderPageComponent, canActivate: [MsalGuard]},
  {path: '', component: HomePageComponent, canActivate: [MsalGuard], data: {hideSideNav: true, fullHeight: true}},
  {
    path: ':customerGuid',
    component: HomePageComponent,
    canActivate: [MsalGuard],
    data: {hideSideNav: true, fullHeight: true}
  },
  {
    path: 'home/:customerGuid',
    children: [
      {
        path: '',
        component: HomePageComponent,
        pathMatch: 'full',
        canActivate: [MsalGuard],
        data: {hideSideNav: true, fullHeight: true}
      },
      {
        path: 'branches',
        children: [
          {path: '', component: LocationsPageComponent, pathMatch: 'full', canActivate: [MsalGuard]},
          {
            path: ':branchGuid/application',
            component: ApplicationPageComponent,
            pathMatch: 'full',
            canActivate: [MsalGuard]
          },
          {
            path: ':branchGuid/applicant-transfer',
            component: ApplicantTransferComponent,
            pathMatch: 'full',
            canActivate: [MsalGuard]
          }
        ]
      },
      {
        path: 'admin',
        children: [
          {path: '', component: LocationsPageComponent, pathMatch: 'full', canActivate: [MsalGuard]},
          {
            path: ':branchGuid/applications',
            component: ApplicationsPageComponent,
            pathMatch: 'full',
            canActivate: [MsalGuard]
          },
          {
            path: ':branchGuid/applications/:patientGuid',
            component: AdjustApplicationPageComponent,
            pathMatch: 'full',
            canActivate: [MsalGuard]
          },
          {
            path: ':branchGuid/application',
            component: ApplicationPageComponent,
            pathMatch: 'full',
            canActivate: [MsalGuard]
          }
        ]
      },
      {
        path: 'reports',
        children: [
          {path: '', component: ReportsPageComponent, pathMatch: 'full', canActivate: [MsalGuard]},
          {path: 'remittance', component: RemittanceReportComponent, pathMatch: 'full', canActivate: [MsalGuard]}
        ]
      },
      {path: 'learn-more', component: LearnMoreComponent, pathMatch: 'full', canActivate: [MsalGuard]},
      {path: 'support', component: SupportComponent, pathMatch: 'full', canActivate: [MsalGuard]},
    ]
  },
  {path: 'ui/patterns', component: PatternsComponent, pathMatch: 'full', canActivate: [MsalGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
