<div class="page-view">
  <div class="page-view__loading card" *ngIf="!(crossroads$ | async)">
    <div class="card-body">
      <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
    </div>
  </div>
  <div *ngIf="(crossroads$ | async)" class="crossroads">
    <div class="crossroads__item" *ngFor="let item of (crossroads$ | async)"
         (click)="navTo(item)">
      <div [title]="item.title"
           class="crossroads__item-logo {{item.klass}}">
        <mat-icon [svgIcon]="(item.icon || '')"></mat-icon>
      </div>
      <div class="crossroads__item-title">{{item.title}}</div>
    </div>
  </div>
</div>
