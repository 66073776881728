import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomerService} from '../../services/customer.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {loadCrossroads} from '../../store/provider/provider.actions';
import {Observable} from 'rxjs';
import {ICrossroadLink} from '../../models/customer-model';
import {getCrossroads} from '../../store/provider/provider.selector';
import {Router} from '@angular/router';
import {getCurrentRoute} from '../../store/router/router.selector';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {
  expanded = false;
  customerGuid: string | null = '';
  branchGuid: string | null = '';
  crossroads$: Observable<ICrossroadLink[]> | undefined;
  crossroadLinks: ICrossroadLink[] | undefined = undefined;
  crossroadUrl = '';
  hideSideNav = false;
  private subscriptions: { [key: string]: any } = {};
  url = '';

  constructor(
    private customerService: CustomerService,
    private store$: Store<AppState>,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.routerSelector = this.store$
      .pipe(select(getCurrentRoute))
      .subscribe((route: any) => {
        this.customerGuid = route.params.customerGuid;
        this.hideSideNav = route?.data?.hideSideNav;
        this.url = route.url;
        this.expanded = false;
        if (this.customerGuid) {
          this.crossroadUrl = '/home/' + this.customerGuid;
          this.customerService.setCustomerGuid(this.customerGuid);
        } else {
          if (route.url != '/select-provider')
            this.router.navigate([`/select-provider`]);
        }
      });

    this.crossroads$ = this.store$.select(getCrossroads);
    let linkSub = this.crossroads$.subscribe({
      next: (links: ICrossroadLink[]) => {
        this.crossroadLinks = links.filter(l => l.app && l.app === "bop");
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {
        linkSub.unsubscribe();
      }
    });

    this.store$.dispatch(loadCrossroads({customerGuid: this.customerGuid}));
  }

  toggleSidenav() {
    this.expanded = !this.expanded;
  }

  navTo(item: ICrossroadLink) {
    if (this.customerGuid === '00000000-0000-0000-0000-000000000000') {
      this.router.navigate(['select-provider']);
    }

    if (!!item.isExternal) {
      window.open(item.url, item.target);
    } else {
      this.router.navigate([item.url]);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.routerSelector.unsubscribe();
  }
}
