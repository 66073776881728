import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ccare-footer',
  templateUrl: './ccare-footer.component.html',
  styleUrls: ['./ccare-footer.component.scss']
})
export class CcareFooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
