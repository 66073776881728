<div class="d-flex justify-content-center align-items-center h-100">
    <div class="loading-container">
        <div class="d-flex justify-content-center">
            <div class="spinner-grow text-info" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
        </div>

    </div>
</div>