<div class="page-view">
  <div *ngIf="loading">
    <div class="text-2xl">Loading...</div>
    <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
  </div>

  <div *ngIf="!loading && hasError">
    <app-error-banner *ngIf="error" [message]="error"></app-error-banner>
  </div>

  <div *ngIf="!loading && !hasError && showList">

    <span class="text-2xl font-medium">Select a report to review</span>
    <div class="mt-4">
      <div class="cds-card p-4">
        <div class="grid">
          <div class="col-12 pt-4">

            <p-table #dt [value]="reports" styleClass="p-datatable-lg mr-4 mb-4 col-12" [paginator]="true"
              [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="first" [showCurrentPageReport]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" responsiveLayout="scroll"
              [globalFilterFields]="filterFields" [rows]="15" [rowHover]="true">
              <ng-template pTemplate="caption">
                <div class="d-flex flex-row-reverse mb-3">
                    <div class="p-1">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                        </span>
                    </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr scope="row">
                  <th>Name</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-report>
                <tr>
                  <td>{{report.reportName}}</td>
                  <td>{{report.reportDescription}}</td>
                  <td class="flex flex-row-reverse">
                    <covered-button id="btnView{{report.reportID}}" class="outline" (buttonClick)="select(report)"><span class="px-4">View</span></covered-button>                    
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [colSpan]="3">No Reports found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>