<div [formGroup]="group" class="select mb-4" *ngIf="group">
  <div class="error-message">
    <span *ngIf="group && group.controls[controlName].touched && group.controls[controlName]?.invalid">
    {{ errorMessage }}
      </span>
  </div>
  <mat-form-field class="{{klass}}" [ngClass]="{'required': isRequired}">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-select
      [ngClass]="group && group.controls[controlName].touched && group.controls[controlName]?.invalid ? 'is-invalid' : ''"
      [formControlName]="controlName"
      panelClass="{{'dropdown-panel ' + klass}}"
      disableOptionCentering
    >
      <mat-option [value]="">{{label}}</mat-option>
      <mat-option *ngFor="let item of options" [value]="item.id">{{item.desc}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
