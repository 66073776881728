import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportItemModel } from 'src/app/models/report-item-model';
import { ReportService } from 'src/app/services/report.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {
  @Input() customerGuid: string | undefined = '';
  @Output() selected = new EventEmitter<ReportItemModel>();

  loading: boolean = true;
  showList: boolean = false;

  hasError: boolean = false;
  error: string = '';

  reports: ReportItemModel[] = [];
  first: number = 0;

  filterFields: string[] = ['reportName', 'reportDescription', 'workspaceId'];

  constructor(
    private reportService: ReportService,
    private router: Router,
    private toastService: ToastService
    ) {
  }

  ngOnInit(): void {
    let reportSub = this.reportService.getReports(this.customerGuid).subscribe({
      next: (res: ReportItemModel[]) => {
        this.loading = false;
        this.showList = true;
        this.reports = res;
        this.addLocalReports();        
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to pull list of reports. See log for details.'
        }, 'center');
        console.error('Error from API: ', err);
        this.loading = false;
        this.hasError = true;
        this.error = err.message || "Unknown Error";
      },
      complete: () => { reportSub.unsubscribe(); }
    });
  }
  
  addLocalReports() {
    // Provider Remittance Report
    let remitRpt = new ReportItemModel();
    remitRpt.pBIAccessID = "";
    remitRpt.reportDescription = "Provides the current settlement remittance.";
    remitRpt.reportID = "prov-remit-rpt";
    remitRpt.reportName = "Remittance Report";
    remitRpt.reportPowerBIID = "";
    remitRpt.routeUrl = `/home/${this.customerGuid}/reports/remittance`;
    remitRpt.workspaceID = "prov-remit-rpt-local";
    this.reports.push(remitRpt);

  }

  select(report: ReportItemModel) {
    if (report.routeUrl && report.routeUrl.length > 0) {
      this.router.navigate([`${report.routeUrl}`]);
    }
    else {
      this.selected.emit(report);
    }
  }

}
