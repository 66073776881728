import {createReducer, on} from '@ngrx/store';
import {loadCrossroadsSuccess} from './provider.actions';
import {crossRoadsAdapter, initialState} from './provider.state';


const _crossroadsReducer = createReducer(
  initialState,
  on(loadCrossroadsSuccess, (state, action) => {
    return crossRoadsAdapter.setAll(action.links, {
      ...state,
    });
  })
);

export function crossroadsReducer(state: any, action: any) {
  return _crossroadsReducer(state, action);
}
