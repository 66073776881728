import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BranchModel} from 'src/app/models/branch-model';
import {ApiService} from 'src/app/services/api.service';
import {AuthService} from 'src/app/services/auth.service';
import {CustomerService} from 'src/app/services/customer.service';
import {MainService} from 'src/app/services/main.service';
import {Title} from '@angular/platform-browser';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {select, Store} from '@ngrx/store';
import {getCurrentRoute} from '../../store/router/router.selector';
import {AppState} from '../../store/app.state';
import {RouteService} from '../../services/router.service';

@Component({
  selector: 'app-locations-page',
  templateUrl: './locations-page.component.html',
  styleUrls: ['./locations-page.component.scss']
})
export class LocationsPageComponent implements OnInit, OnDestroy {
  locations: BranchModel[] = [];
  customerGuid: string | undefined = '';
  loading = false;
  private subscriptions: { [key: string]: any } = {};
  isAdminFlow: boolean = false;

  displayedColumns: string[] = ['branchName', 'branchId', 'address', 'zip'];
  dataSource = new MatTableDataSource<BranchModel>();
  searchControl = new UntypedFormControl();
  filterSearch$: Subscription = new Subscription();

  constructor(private activatedRoute: ActivatedRoute,
              private apiService: ApiService,
              private authService: AuthService,
              private main: MainService,
              private router: Router,
              private title: Title,
              private customerService: CustomerService,
              private store$: Store<AppState>,
              private routerService: RouteService) {
    // this.title.setTitle("Covered Account");
  }

  ngOnInit(): void {
    this.main.setPageWidth(false);
    this.subscriptions.routerSelector = this.store$
      .pipe(select(getCurrentRoute))
      .subscribe((route: any) => {
        this.customerGuid = route.params.customerGuid;
        this.isAdminFlow = route.url.split('/').slice(-1)[0] === 'admin';
        this.customerService.setCustomerGuid(this.customerGuid, true);
        this.getLocations();
      });
  }

  applyFilters() {
    this.filterSearch$ = this.searchControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((filterValue: string) => {
      this.dataSource.filter = filterValue;
    });
  }

  /**
   * Redirect to Begin application if only one location is existing
   */
  getLocations(): boolean {
    if (this.loading || this.locations.length) {
      return false;
    }
    this.loading = true;
    this.apiService.get(`provider/locations/${this.customerGuid}`)
      .subscribe((res: any) => {
          this.loading = false;
          this.locations = res || [];
          this.dataSource.data = res || [];
          const loc = this.dataSource.data[0];
          if (this.dataSource.data.length === 1 && this.checkSkipLocation(loc)) {
            this.router.navigate([`home/${loc.customerGuid}`]);
          } else if (this.dataSource.data.length === 1) {
            this.goToBranch(loc, true);
          }
          this.applyFilters();
        },
        (err: any) => {
          this.loading = false;
        }
      );
    return true;
  }

  checkSkipLocation(loc: BranchModel): boolean {
    const referralUrl = this.routerService.getReferralUrl().split('?')[0].split('#')[0];
    if (this.isAdminFlow) {
      return `/home/${loc.customerGuid}/admin/${loc.branchGuid}/applications` === referralUrl;
    } else {
      return `/home/${loc.customerGuid}/branches/${loc.branchGuid}/application` === referralUrl;
    }
  }

  goToBranch(loc: BranchModel, singleLocationExists?: boolean) {
    this.customerService.setBranch(loc);
    let url = '';
    if (this.isAdminFlow) {
      url = `home/${loc.customerGuid}/admin/${loc.branchGuid}/applications`;
    } else {
      url = `home/${loc.customerGuid}/branches/${loc.branchGuid}/application`;
    }
    url = singleLocationExists ? `${url}?single=true` : `${url}`;
    this.router.navigateByUrl(url);
  }

  ngOnDestroy(): void {
    try {
      if (this.filterSearch$)
        this.filterSearch$.unsubscribe();
      if (this.subscriptions && this.subscriptions.routerSelector)
        this.subscriptions.routerSelector.unsubscribe();
    } catch (error) {
      console.error(`Error locations page.OnDestroy: ${JSON.stringify(error)}`);
    }
  }

}
