<div *ngIf="authenticated" class="full-height">
  <div *ngIf="showSelectProvider" class="full-height">
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </div>
  <div *ngIf="!showSelectProvider" class="full-height">
    <app-header-nav></app-header-nav>
    <mat-drawer-container *ngIf="authenticated" class="admin-container" autosize hasBackdrop="false">
      <mat-drawer *ngIf="showHideSidenav" #drawer class="admin-sidenav" mode="over" opened="true" disableClose="true">
        <div>
          <app-side-nav></app-side-nav>
        </div>
      </mat-drawer>
      <div class="admin-sidenav-content">
        <div [ngClass]="containerClass">
          <router-outlet *ngIf="!isIframe"></router-outlet>
        </div>
        <app-footer></app-footer>
      </div>
    </mat-drawer-container>
  </div>
</div>

<covered-toast [key]="toastKey" [position]="toastPos" [preventDuplicates]="false"></covered-toast>

