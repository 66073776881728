import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppStorageKeys } from "../configs/app-storage-keys";
import { IUserGuids } from "../models/user-guids";
import { AuthService } from "./auth.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        var userGuid = '';
        var jsonUser = localStorage.getItem(AppStorageKeys.loggedInuserGuid);
        if (jsonUser) {
            var result = <IUserGuids>JSON.parse(jsonUser);
            userGuid = result.loggedUserGuid || '';
        }
      let headers = {};

      if (!req.url.includes('us-autocomplete-pro')) {
        headers = {
          headers: req.headers.set('loggedInUserId', userGuid)
        };
      }

      const authReq = req.clone(headers);
        return next.handle(authReq)
        .pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.auth.logout();
                    return throwError('');
                }
                else {
                    return throwError(err);                                
                }
            })
        );
    }
}
