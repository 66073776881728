<div class="text-center">
    <p class="h4 mb-1 header">Application</p>
</div>
<mat-progress-bar *ngIf="loading" mode="indeterminate" class="py-2 my-5"></mat-progress-bar>
<div *ngIf="!loading">
    <app-application-entry 
    [readonly]="readonly"
    [appDetails]="details">
</app-application-entry>
</div>
<div mat-dialog-actions class="d-flex justify-content-center">
    <button mat-button mat-dialog-close class="btn-main w50">Close</button>
  </div>