import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BranchLocationModel} from 'src/app/models/branch-location-model';
import {ApiService} from 'src/app/services/api.service';
import {MainService} from 'src/app/services/main.service';
import {MatTableDataSource} from '@angular/material/table';
import {BranchModel} from '../../models/branch-model';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AppSearchModel} from '../../models/app-search-model';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';

export interface transferLocationData {
  customerGuid: string | null;
  selected: string | null;
  dialogType: string;
}

@Component({
  selector: 'app-transfer-location',
  templateUrl: './transfer-location.component.html',
  styleUrls: ['./transfer-location.component.scss']
})
export class TransferLocationComponent implements OnInit, OnDestroy {

  loading = false;
  locations: BranchLocationModel[] = [];
  selectedBranch: SelectionModel<BranchLocationModel> = {} as SelectionModel<BranchLocationModel>;


  displayedColumns: string[] = ['branchName', 'branchID', 'address', 'zip'];
  dataSource = new MatTableDataSource<BranchModel>();
  searchControl = new UntypedFormControl();
  filterSearch$: Subscription = new Subscription();
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator = {} as MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<TransferLocationComponent>,
    private api: ApiService,
    private main: MainService,
    @Inject(MAT_DIALOG_DATA) public data: transferLocationData,
  ) {
  }

  ngOnInit(): void {
    this.selectedBranch = new SelectionModel<BranchLocationModel>(false, []);
    this.getBranches();
  }

  getBranches() {
    this.locations = [];
    this.loading = true;
    this.api.get(`provider/provider-branches/${this.data.customerGuid}`)
      .subscribe((res: any) => {
          this.searchControl.setValue('');
          this.dataSource = new MatTableDataSource(res || []);
          this.setDataSource(null);
          this.applyFilters();
          this.loading = false;
        },
        (err: any) => {
          console.error(err);
          this.loading = false;
          this.main.showSnackBar("Error Occurred loading locations");
        }
      );
  }

  applyFilters() {
    this.filterSearch$ = this.searchControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe((filterValue: string) => {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
    });
  }

  transfer() {
    this.dialogRef.close(this.selectedBranch);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  private setDataSource(model: AppSearchModel | null) {
    this.dataSource.sort = this.sort;
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  ngOnDestroy(): void {
  }
}
