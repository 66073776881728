<div class="page-view">
    <div *ngIf="loading">
        <div class="text-2xl">Loading...</div>
        <covered-progress-bar [style]="{'height': '0.5rem'}"></covered-progress-bar>
    </div>

    <div *ngIf="!loading && hasError">
        <app-error-banner *ngIf="error" [message]="error"></app-error-banner>
    </div>

    <div *ngIf="!loading && !hasError && showCard">

        <span class="text-2xl font-medium">Settlement Remittance Report</span>
        <p-breadcrumb [model]="bcItems"></p-breadcrumb>

        <div class="mt-4">
            <div class="cds-card p-4">
                <div class="row">
                    <div class="col-sm-12 col-md-3">
                        <covered-dropdown id="ddBranch" placeholder="Select Branch" [items]="branches"
                            optionLabel="branchName" [displayFirst]="false" [showClear]="false"
                            [(ngModel)]="selectedBranch" (ngModelChange)="branchSelected($event)" [required]="true">
                        </covered-dropdown>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <covered-datepicker id="dtPayment1" [(ngModel)]="startDate" placeholder="Start Date">
                        </covered-datepicker>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <covered-datepicker id="dtPayment2" [(ngModel)]="endDate" placeholder="End Date">
                        </covered-datepicker>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <covered-button id="btnReport" label="Show Report" (buttonClick)="showReport()">
                        </covered-button>
                    </div>
                </div>

                <div class="grid">
                    <div class="col-6">

                    </div>
                    <div *ngIf="showFiles" class="col-12 pt-4">
                        <p-table #dtFiles [value]="fileIds" styleClass="p-datatable-lg mr-4 mb-4 col-12"
                            [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="fileFirst"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [columns]="fileColumns" responsiveLayout="scroll" [rows]="15" [rowHover]="true">
                            <ng-template pTemplate="caption">
                                <div class="flex">
                                    <div class="flex justify-content-start">
                                        <covered-button id="btnExport" label="Download CSV" class="outlined"
                                            (buttonClick)="dtFiles.paginator=false;dtFiles.exportCSV();dtFiles.paginator=true;"
                                            [disabled]="!fileIds.length">
                                        </covered-button>
                                    </div>
                                    <!-- <div class="flex flex-grow-1 justify-content-end">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" aria-hidden="true"></i>
                                        <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                            (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                                    </span>
                                </div> -->
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <ng-template ngFor let-col [ngForOf]="fileColumns" let-i="index">
                                        <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field"
                                            class="font-medium" scope="col">{{ col.header }}
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </th>
                                        <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                                    </ng-template>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-file>
                                <tr class="cursor-pointer">
                                    <td>{{file.referenceCode}}</td>
                                    <td>{{file.branchName}}</td>
                                    <td>{{file.customerName}}</td>
                                    <td>{{file.amount | currency }}</td>
                                    <td>{{file.sentDate | date: 'MM/dd/yyyy' }}</td>
                                    <td>
                                        <covered-button id="btnExportgg" label="View Details" class="outlined"
                                            (buttonClick)="selectRemitFile(file)">
                                        </covered-button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td [colSpan]="3">No remittance files found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    <div *ngIf="showRemits" class="col-12 pt-4">
                        <p-table #dtRemits [value]="remitIds" styleClass="p-datatable-lg mr-4 mb-4 col-12"
                            [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" [(first)]="remitFirst"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [columns]="remitColumns" responsiveLayout="scroll" [rows]="15" [rowHover]="true">
                            <ng-template pTemplate="caption">
                                <div class="flex">
                                    <div class="flex justify-content-start">
                                        <covered-button id="btnExport" label="Download CSV" class="outlined"
                                            (buttonClick)="dtRemits.paginator=false;dtRemits.exportCSV();dtRemits.paginator=true;"
                                            [disabled]="!remitIds.length">
                                        </covered-button>
                                    </div>
                                    <!-- <div class="flex flex-grow-1 justify-content-end">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" aria-hidden="true"></i>
                                        <input #txtSearch type="text" class="w-15rem" pInputText placeholder="Search"
                                            (input)="dt.filterGlobal(txtSearch.value, 'contains')" />
                                    </span>
                                </div> -->
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <ng-template ngFor let-col [ngForOf]="remitColumns" let-i="index">
                                        <th *ngIf="col.show && col.sortable" [pSortableColumn]="col.field"
                                            class="font-medium" scope="col">{{ col.header }}
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </th>
                                        <th *ngIf="col.show && !col.sortable" scope="col">{{ col.header }}</th>
                                    </ng-template>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-remit>
                                <tr>
                                    <td>{{remit.loanNumber}}</td>
                                    <td>{{remit.patientID}}</td>
                                    <td>{{remit.firstName}}</td>
                                    <td>{{remit.lastName}}</td>
                                    <td>{{remit.originationDate | date: 'MM/dd/yyyy' }}</td>
                                    <td>{{remit.serviceDate | date: 'MM/dd/yyyy' }}</td>
                                    <td>{{remit.loanTerm}}</td>
                                    <td>{{remit.totalAmount | currency }}</td>
                                    <td>{{remit.purchasePricePercentage }}</td>
                                    <td>{{remit.feeToCovered | currency }}</td>
                                    <td>{{remit.dueToProvider | currency }}</td>
                                    <td>{{remit.locationID}}</td>
                                    <td>{{remit.locationName}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td [colSpan]="3">No remittance files found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>