import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserRoleModel } from '../models/user-role-model';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  private _userRoles = new BehaviorSubject<UserRoleModel[]>([]);
  userRoles$ = this._userRoles.asObservable();
  private customerGuid = '';

  constructor(private custService: CustomerService,
    private apiService: ApiService) { }

  loadUserRoles(){
    var roles = this._userRoles.getValue();
    if (!roles || !roles.length) {
      this.custService.customerGuid$.subscribe(cust => {
        if (cust){
          this.apiService.get(`csr/user-site-features/${cust}`)
          .subscribe((res: any) => {
            this._userRoles.next(res);
          },
            (err: any) => {
              this._userRoles.next([]);
              console.error(err);
            }
          );
        }
      });
    };

  }

  hasRole(feature: string){
    var roles = this._userRoles.getValue();
    if (!feature || !roles || !roles.length) return false;
    var i = roles.findIndex(item => item.code.toUpperCase().trim() === feature.toUpperCase().trim());
    return i !== -1 && roles[i].allowWrite;
  }

}
