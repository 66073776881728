import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { PatientInfoModalData } from 'src/app/models/app-modal-data';
import { ApiService } from 'src/app/services/api.service';
import { MainService } from 'src/app/services/main.service';
import { CustomerService } from 'src/app/services/customer.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-adjustment-modal',
  templateUrl: './adjustment-modal.component.html',
  styleUrls: ['./adjustment-modal.component.scss']
})
export class AdjustmentModalComponent implements OnInit {
  stage = 'confirm' //edit/confirm/complete
  submitBtn = "SUBMIT ADJUSTMENTS";
  newAmount: any;
  serviceDate!: UntypedFormControl;
  disabled = false;
  iconCheck = faCheckCircle as IconProp;
  iconTimes = faTimesCircle as IconProp;
  error = '';
  startUrl = '';
  loading = false;

  constructor(public dialogRef: MatDialogRef<AdjustmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientInfoModalData,
    private customerService: CustomerService,
    private main: MainService,
    private apiService: ApiService,
    private authService: AuthService) {

  }

  ngOnInit(): void {
    this.newAmount = this.getCurrentAmount();
  }

  submit() {
    if (this.stage === 'confirm') {
      this.confirmed();
    }
    if (this.stage === 'complete') {
      this.dialogRef.close('updated');
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  stayHere() {
    this.authService.logout(this.startUrl);
  }

  confirmed() {
    this.loading = true;
    const obj = {
      customerGuid: this.data.customerGuid,
      branchGuid: this.data.branchGuid,
      patientGuid: this.data.patientGuid,
      loanAmount: this.cleanAmount(this.data.newAmount),
      serviceDate: new Date(this.data.serviceDate as string).toISOString(),
      doctorName: this.data!.patientInfo!.doctorName,
      financedBy: this.data.financedBy,
      reasonDesc: this.data.reasonDesc,
      reasonId: this.data.reasonId.toString()
    };
    this.disabled = true;
    this.apiService.postStringResponse(`app/patient-adjustment`, obj)
      .subscribe((res: any) => {
        this.loading = false;
        if (res.toLowerCase().indexOf('error') > -1) {  // ERROR OCCURED
          this.disabled = false;
          this.error = res || "Error Occured";
          this.stage = 'error';
          console.error(res);
        }
        else {
          this.main.refreshApps(true);
          this.stage = 'complete';
        }
      },
        (err: any) => {
          this.disabled = false;
          this.resetStage();
        }
      );
  }

  getCurrentAmount() {
    return this.cleanAmount(this.data!.patientInfo!.loanAmtInitial);
  }

  cleanAmount(amt: string | undefined) {
    if (!amt) return 0;
    return Number(amt.toString().replace(',', '').replace(' ', ''));
  }

  resetStage() {
    this.dialogRef.close();
  }
}
