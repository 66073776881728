import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AppStorageKeys } from 'src/app/configs/app-storage-keys';
import { AppSearchModel } from 'src/app/models/app-search-model';

@Component({
  selector: 'app-search-apps-popup',
  templateUrl: './search-apps-popup.component.html',
  styleUrls: ['./search-apps-popup.component.scss']
})
export class SearchAppsPopupComponent implements OnInit {
  @Output() onSearch = new EventEmitter<AppSearchModel>();
  searchForm = this.fb.group({
    firstName: [''],
    lastName: [''],
    patientId: [''],
    phone: [''],
    email: ['']
  });
  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    var lastSearchedStr = localStorage.getItem(AppStorageKeys.bopAppsLastSearchStorageKey);
    if (lastSearchedStr){
      var lastSearched = JSON.parse(lastSearchedStr);
      this.searchForm.patchValue(lastSearched);
    }
  }

  search(){
    this.onSearch.emit(this.searchForm.value);
  }

}
