import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function getMsalConfig(settings: any){
    var msalConfig: Configuration = {
        auth: {
            clientId: settings['AD_AppReg_ClientId'],
            authority: getPolicy(settings),
            knownAuthorities: [settings['B2C_AuthorityDomain']],
            redirectUri: '/', 
        },
       cache: {
           cacheLocation: BrowserCacheLocation.LocalStorage,
           storeAuthStateInCookie: true
       },
       system: {
           loggerOptions: {
               loggerCallback(logLevel: LogLevel, message: string){
                   // console.log(message);
               },
               logLevel: LogLevel.Error,
               piiLoggingEnabled: false
           }
       }
    }

    return msalConfig;
}

 function getRedirectUrl(settings: any){
    var hostname = window.location.hostname.toLocaleLowerCase();
    if (hostname.indexOf('ally') > -1) {
        return settings['URL_ALLY'];
    }else if (hostname.indexOf('powerpay') > -1){
        return settings['URL_POWERPAY'];
    }else{
        return settings['URL_BOP'];
    }
}

export function getPolicy(settings: any) {
    var hostname = window.location.hostname;
    let policy = settings['B2C_SignInPolicy'];
    // let policy = settings['B2C_SignInPolicy_Ally'];
    if (hostname.indexOf('ally') > -1) {
        policy = settings['B2C_SignInPolicy_Ally'];
    }
    if (hostname.indexOf('powerpay') > -1) {
        policy = settings['B2C_SignInPolicy_PowerPay'];
    }
    return policy;
}
