import { AppAccess } from 'src/app/models/app-access';
import { AccessService } from 'src/app/services/access.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BranchModel } from 'src/app/models/branch-model';
import { UserModel } from 'src/app/models/user-model';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { environment } from 'src/environments/environment';
import { MainService } from '../../services/main.service';
import { DynamicComponentDirective } from '../../directives/dynamic-component.directive';
import { DynamicComponent } from '../shared/dynamic/dynamic.component';
import { DynamicComponentService } from '../../services/dynamic-component.service';
import { ThemeService } from 'src/app/services/theme.service';
import { combineLatest } from 'rxjs';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit, OnDestroy {
  @Input() isMenuOpened: boolean = false;
  @ViewChild(DynamicComponentDirective)
  private dynComponentHost: DynamicComponentDirective = {} as DynamicComponentDirective;

  @ViewChild(DynamicComponent)
  private myPostComponent = {} as DynamicComponent;


  user: UserModel = new UserModel;
  homeUrl = '/';
  customerGuid: string | null = "";
  providerName = "";
  branch: BranchModel | null = null;
  showBranchInfo = false;
  reportsUrl = '';
  fullName: string = '';
  hasAllProviders: boolean = false;
  hasAdminAccess: boolean = false;
  hasBOPAcess: boolean = false;
  hasCSAccess: boolean = false;
  hasMultiplePortfolios: boolean = false;

  constructor(
    private customerService: CustomerService,
    private router: Router,
    private authService: AuthService,
    private accessService: AccessService,
    private settings: SettingService,
    private main: MainService,
    private dynamicComponentService: DynamicComponentService,
    private themeService: ThemeService
  ) {
  }

  ngOnInit(): void {
    let accessSub = this.accessService.access$
      .subscribe({
        next: (access: AppAccess) => {
          this.hasAdminAccess = access.admin;
          this.hasAllProviders = access.allProviders;
          this.hasBOPAcess = access.bop;
          this.hasCSAccess = access.cs;
          this.fullName = access.fullName;
        },
        error: (err: any) => {
          console.error(err);
        },
        complete: () => {
          accessSub.unsubscribe();
        }
      });

    const dataSub = combineLatest([this.customerService.portfolios$, this.customerService.customer$])
      .subscribe({
        next: ([portfolios, cust]) => {
        this.hasMultiplePortfolios = portfolios && portfolios.portfolios && portfolios.portfolios.length > 1;
        if (cust && cust.customerGUID) {
          this.main.removeClassFromBody(this.themeService.Theme);
          this.customerGuid = cust.customerGUID;
          this.providerName = cust.customerName;
          this.homeUrl = '/home/' + cust.customerGUID;
          this.main.addClassToBody(this.themeService.Theme);
          const componentName = this.themeService.getHeaderComponentName();
          this.dynamicComponentService.loadComponent(
            this.dynComponentHost.viewContainerRef,
            { componentName, data: {} }
          );
        }
      },
      error: (err: any) => { console.error(err); },
      complete: () => { dataSub.unsubscribe(); }
    });
    this.customerService.branch$.subscribe(branch => {
      if (branch && branch.branchGuid) {
        this.branch = branch;
      }

    });
    this.authService.user$.subscribe((u) => {
      if (u) {
        this.user = u;
      }
    });
    this.showBranchInfo = this.router.url.indexOf('branch/') !== -1;
  }

  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }

  menuAction(action: string) {
    switch (action) {
      case 'providers':
        this.router.navigate([`/select-provider`]);
        break;
      case 'admin':
        var adminUrl = `${this.settings.getValue('URL_Admin')}dashboard/${this.customerGuid}`;
        window.location.href = adminUrl;
        break;
      case 'cs':
        var csUrl = `${this.settings.getValue('URL_CS')}home/${this.customerGuid}`;
        window.location.href = csUrl;
        break;
      case 'logout':
        this.authService.logout();
        break;
      default:
      // code block//
    }
  }

  ngOnDestroy(): void {
    this.main.removeClassFromBody(this.themeService.Theme);
  }

}
