<div class="page-view form">
  <div class="breadcrumbs" *ngIf="report?.reportDescription">
    <a tabindex="0" (click)="onCancel()" (keyup.enter)="onCancel()" href="javascript:void(0)">
      Select Report</a> <span>&gt;</span> <span>{{ report?.reportDescription}}</span>
  </div>
  <app-error-banner *ngIf="error"
                    [message]="error"></app-error-banner>
  <div class="page-view__loading" *ngIf="loading">
    <div class="card-body">
      <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
    </div>
  </div>
  <div class="page-view__body report-details">
    <powerbi-report
      [embedConfig]="reportConfig"
      [cssClassName]="reportClass"
      [phasedEmbedding]="phasedEmbeddingFlag"
      [eventHandlers]="eventHandlersMap">
    </powerbi-report>
  </div>
</div>
