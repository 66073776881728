<div class="page-view">
  <div class="page-view__loading card" *ngIf="loading">
    <div class="card-body">
      <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
    </div>
  </div>
  <div *ngIf="!loading" class="page-view__body support">
    <h2>Support</h2>
    <app-error-banner *ngIf="error"
                      [message]="error"></app-error-banner>
    <div class="support__container">
      <div class="grid support__info" *ngFor="let item of data">
        <div class="support__info-label col-12 md:col-6">
          <div class="support__info-label__title">{{item.title}}</div>
          <div class="support__info-label__description">{{item.description}}</div>
        </div>
        <div class="support__info-value col-12 md:col-6">
          <div>Phone: <a [attr.href]="'tel:' + item.contactPhone"
                         [attr.title]="item.contactPhone" class="normal">{{item.contactPhone}}</a></div>
          <div [innerHtml]="item.customerServiceHours"></div>
          <div class="support__info-value email">
            <div><a [attr.href]="'mailto:'+item.contactEmail"
                    [attr.title]="item.contactEmail">{{item.contactEmail}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
