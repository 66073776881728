import {Component, OnInit} from '@angular/core';
import {CustomerService} from '../../services/customer.service';
import {select, Store} from '@ngrx/store';
import {getCurrentRoute} from '../../store/router/router.selector';
import {AppState} from '../../store/app.state';

export interface ISupportInfo {
  contactPhone: string,
  contactEmail: string,
  customerServiceHours: string,
  startTime: string,
  endTime: string,
  daysOpen: string,
  timezone: string,
  title: string,
  description: string,
  startDT: Date,
  endDT: Date
}

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  data: ISupportInfo[] = [];
  customerGuid: string | null = "";
  loading = true;
  error = '';
  private subscriptions: { [key: string]: any } = {};


  constructor(private cs: CustomerService, private store$: Store<AppState>) {
  }

  ngOnInit(): void {

    let isAlly = window.location.hostname.toLowerCase().indexOf('ally') > -1;

    this.data = [
      {
        contactPhone: '(888) 589-5444',
        contactEmail: 'clientcare@coveredcare.com',
        customerServiceHours: 'Monday - Friday 8am-7pm CST',
        startTime: '08:00',
        endTime: '18:00',
        daysOpen: 'Monday - Friday',
        timezone: 'CST',
        title: 'Provider Support',
        description: 'For any technical assistance, or any provider related questions.',
        startDT: new Date(),
        endDT: new Date()
      }
    ];

    if (isAlly) {
      this.data.push({
        contactPhone: '(888) 568-0186',
        contactEmail: 'borrowersupport.lending@ally.com',
        customerServiceHours: 'Monday - Saturday 8am-5pm ET',
        startTime: '08:00',
        endTime: '22:00',
        daysOpen: 'Monday - Saturday',
        timezone: 'ET',
        title: 'Ally Bank Account Support',
        description: 'For questions regarding accounts financed by Ally Bank.',
        startDT: new Date(),
        endDT: new Date()        
      })
    }

    this.data.push({
      contactPhone: '(888) 211-6015',
        contactEmail: 'wpmcustomercare@wpmservicing.com',
        customerServiceHours: 'Monday - Friday 5am-9pm PST<br>Saturday - Sunday 5am-2pm PST',
        startTime: '9:00',
        endTime: '21:00',
        daysOpen: 'Monday - Friday',
        timezone: 'PST',
        title: 'Covered Care Account Support',
        description: 'For questions regarding accounts financed by Covered Care.',
        startDT: new Date(),
        endDT: new Date()        
    });
    this.loading = false;



    //    NO LONGER NEEDED AS ALL INFO IS "HARD CODED" PER #1529
    // this.subscriptions.routerSelector = this.store$
    //   .pipe(select(getCurrentRoute))
    //   .subscribe((route: any) => {
    //     this.customerGuid = route.params.customerGuid;
    //   });
    // this.cs.getSupportInfo(this.customerGuid).subscribe(
    //   (data: any) => {
    //     this.data = Array.isArray(data) ? data : [data];
    //     this.loading = false;
    //   }, () => {
    //     this.error = "Oops... Error occurred";
    //   });
  }
}
