import {ComponentFactoryResolver, Injectable, ViewContainerRef} from '@angular/core';
import {AllyFooterComponent} from '../components/footer/ally-footer/ally-footer.component';
import {AllyHeaderComponent} from '../components/header-nav/ally-header/ally-header.component';
import {CcareFooterComponent} from '../components/footer/ccare-footer/ccare-footer.component';
import {CcareHeaderComponent} from '../components/header-nav/ccare-header/ccare-header.component';
import { PowerpayHeaderComponent } from '../components/header-nav/powerpay-header/powerpay-header.component';
import { PowerpayFooterComponent } from '../components/footer/powerpay-footer/powerpay-footer.component';

export interface IDynamicComponentModal {
  componentName: string,
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
  }

  loadComponent(viewContainerRef: ViewContainerRef, item: IDynamicComponentModal): boolean {
    if (!(ComponentMap[item.componentName] && viewContainerRef)) {
      return false;
    }
    let componentFactory = this.componentFactoryResolver
      .resolveComponentFactory(ComponentMap[item.componentName]);
    viewContainerRef.clear();

    const componentRef: any = viewContainerRef.createComponent(componentFactory);
    componentRef.instance.data = item.data;

    return true;
  }
}

const ComponentMap: any = {
  allyFooter: AllyFooterComponent,
  allyHeader: AllyHeaderComponent,
  ccareFooter: CcareFooterComponent,
  ccareHeader: CcareHeaderComponent,
  powerpayHeader: PowerpayHeaderComponent,
  powerpayFooter: PowerpayFooterComponent
};
