import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplace'
})
export class StringReplacePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    Object.keys(args).forEach((key) => {
      value = value.split(key).join(args[key]);
    });

    return value;
  }

}
