<div class="page-modal-content">
  <h2>Change location</h2>
  <mat-progress-bar mode="indeterminate" class="py-2 my-5" *ngIf="loading"></mat-progress-bar>
  <div *ngIf="!loading">
    <div class="search-container">
      <div class="search">
        <mat-icon svgIcon="search"></mat-icon>
        <input placeholder="SEARCH LOCATION" matInput [formControl]="searchControl"/>
      </div>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" aria-label="Location">
        <!-- Location Name Column -->
        <ng-container matColumnDef="branchName">
          <th mat-header-cell *matHeaderCellDef> Location Name</th>
          <td mat-cell *matCellDef="let location"> {{location.branchName}} </td>
        </ng-container>

        <!-- Location Id Column -->
        <ng-container matColumnDef="branchID">
          <th mat-header-cell *matHeaderCellDef> Location ID</th>
          <td mat-cell *matCellDef="let location"> {{location.branchID}} </td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef> Address</th>
          <td mat-cell
              *matCellDef="let location">
            <div>{{location.branchAddress1}}</div>
            <div>{{ location.branchCity + ', ' + location.branchState + ', ' + location.branchZip}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="zip">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let location" class="text-center">
            <mat-radio-group color="primary" [(ngModel)]="selectedBranch">
              <mat-radio-button [value]="location"
                                class="cc-radio-button"></mat-radio-button>
            </mat-radio-group>
          </td>
        </ng-container>
        <tr *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length" class="no-data-row">
            <div class="heading">No results are found</div>
            There weren’t any results matching your search.
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="footer">
        <mat-paginator [pageSizeOptions]="[4]" aria-label="Select page" class="paginator"></mat-paginator>
      </div>
    </div>
    <div class="actions">
      <button class="button button-primary" (click)="transfer()">
        Update location
      </button>
      <button class="button button-medium button-secondary" (click)="cancel()">BACK</button>
    </div>
  </div>
</div>
