import {EffectsModule} from '@ngrx/effects';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {StoreModule} from '@ngrx/store';
import {CrossroadsEffects} from './provider/provider.effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../environments/environment';
import {RouterModule} from '@angular/router';
import {appReducer} from './app.state';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {CustomSerializer} from './router/custom-serializer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(appReducer),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    // EffectsModule.forFeature([CrossroadsEffects]),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
    })
  ],
})
export class AppStoreModule {
}
