import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { BranchModel } from 'src/app/models/branch-model';
import { MenuItem } from 'primeng/api';
import { BopSettlementReportID, BopRemittance } from 'src/app/models/settlement';
import { TableColumn } from '@covered/covered-ui';
import { fromISODate } from 'src/app/services/util.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-remittance-report',
  templateUrl: './remittance-report.component.html',
  styleUrls: ['./remittance-report.component.scss']
})
export class RemittanceReportComponent implements OnInit {

  customerGuid: string | null = null;
  branches: BranchModel[] = [];

  loading: boolean = true;
  hasError: boolean = false;
  error: string | null = "";
  showCard: boolean = false;
  showFiles: boolean = false;
  showRemits: boolean = false;

  bcItems: MenuItem[] = [];
  selectedBranch: BranchModel | null = null;
  startDate  = new Date(new Date().setDate(new Date().getDate() - 30));
  endDate = new Date();
  fileIds: any[] = [];
  fileFirst: number = 0;
  remitIds: any[] = [];
  remitFirst: number = 0;

  fileColumns: TableColumn[] = [];
  remitColumns: TableColumn[] = [];
  remitMenuLabel: string = 'Remittances';
  reportMenuLabel: string = 'Remittance Files';


  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private toastService: ToastService,
    private mainService: MainService
  ) { }

  ngOnInit(): void {

    this.customerGuid = this.activatedRoute.snapshot.params['customerGuid'];

    if (this.customerGuid) {

      this.bcItems = [
        { label: 'Home', routerLink: [`/home/${this.customerGuid}`] },
        { label: 'Reporting', routerLink: [`/home/${this.customerGuid}/reports`] },
        { label: this.reportMenuLabel }
      ];

      this.apiService.get(`provider/locations/${this.customerGuid}`)
        .subscribe({
          next: (branches: BranchModel[]) => {
            this.branches = branches;
            var b = new BranchModel();
            b.branchGuid = '00000000-0000-0000-0000-000000000000';
            b.branchName = "All Branches";
            this.branches.unshift(b);
            this.selectedBranch = b;
            this.initColumns();
            this.loading = false;
            this.showCard = true;
          },
          error: (err: any) => {
            this.toastService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Unable to pull list of branches. See log for details.'
            }, 'center');
            console.error(err);
            this.error = err.message || null;
            this.hasError = true;
          }
        })
    }
    else {
      this.loading = false;
      this.error = "Unable to determine Customer ID";
      this.hasError = true;
    }

  }

  initColumns() {
    this.fileColumns = [
      {
        field: 'referenceCode',
        header: 'Reference Code',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'branchName',
        header: 'Branch Name',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'customerName',
        header: 'Customer Name',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'amount',
        header: 'Amount',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'sentDate',
        header: 'Sent Date',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: '',
        header: '',
        clickable: false,
        show: true,
        sortable: false,
        class: null
      }
    ];

    this.remitColumns = [
      {
        field: 'loanNumber',
        header: 'Loan Number',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'patientID',
        header: 'Patient ID',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'firstName',
        header: 'First Name',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'lastName',
        header: 'Last Name',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'originationDate',
        header: 'Origination Date',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'serviceDate',
        header: 'Service Date',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'loanTerm',
        header: 'Loan Term',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'totalAmount',
        header: 'Total Amount',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'purchasePricePercentage',
        header: 'Purchase Price Percent',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'feeToCovered',
        header: 'Fee to Covered',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'dueToProvider',
        header: 'Due to Provider',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'locationID',
        header: 'Location ID',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      },
      {
        field: 'locationName',
        header: 'Location Name',
        clickable: false,
        show: true,
        sortable: true,
        class: null
      }
    ];

  }

  showReport(){
    this.mainService.showLoading();
    var model = {
      BranchGuid: this.selectedBranch.branchGuid,
      StartDate: this.startDate.toISOString(),
      EndDate: this.endDate.toISOString()
    }
    let branchSub = this.apiService.post(`BopReports/branch/file-list`, JSON.stringify(model)).subscribe({
      next: (list: any[]) => {
        list.forEach(item => {
          item.SentDate = fromISODate(item.SentDate);
        })
        this.fileIds = list;

        let reportItem = this.bcItems.find(b => b.label == this.reportMenuLabel);
        if (reportItem && reportItem.command) {
          reportItem.command();
        }
        else {
          this.showFiles = true;
          this.showRemits = false;
        }
        this.mainService.dismissLoading();
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to pull list remittance files for branch. See log for details.'
        }, 'center');
        console.error(err);
        this.mainService.dismissLoading();
      },
      complete: () => { branchSub.unsubscribe(); }
    });
  }

  branchSelected(branch: BranchModel) {
    // let branchSub = this.apiService.get(`BopReports/branch/${branch.branchGuid}/file-list`).subscribe({
    //   next: (list: BopSettlementReportID[]) => {
    //     this.fileIds = list;

    //     let reportItem = this.bcItems.find(b => b.label == this.reportMenuLabel);
    //     if (reportItem && reportItem.command) {
    //       reportItem.command();
    //     }
    //     else {
    //       this.showFiles = true;
    //       this.showRemits = false;
    //     }
    //   },
    //   error: (err: any) => {
    //     this.toastService.add({
    //       severity: 'error',
    //       summary: 'Error',
    //       detail: 'Unable to pull list remittance files for branch. See log for details.'
    //     }, 'center');
    //     console.error(err);
    //   },
    //   complete: () => { branchSub.unsubscribe(); }
    // });
  }

  selectRemitFile(file: any) {

    let branchSub = this.apiService.get(`BopReports/remittance/${file.branchGUID}/${file.DailySettlement_NACHAFileID}`).subscribe({
      next: (list: any[]) => {
        // console.log(list);
        this.remitIds = list;

        let prevItem = this.bcItems.pop();
        if (prevItem) {
          prevItem.command = () => {
            this.showFiles = true;
            this.showRemits = false;
            this.remitIds = [];
            this.bcItems.pop();
            this.removeBcCommand(prevItem?.label);
          };
          this.bcItems.push(prevItem);
        }
        this.bcItems.push({
          label: this.remitMenuLabel
        });
        this.showFiles = false;
        this.showRemits = true;
      },
      error: (err: any) => {
        this.toastService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Unable to pull list remittance data for branch. See log for details.'
        }, 'center');
        console.error(err);
      },
      complete: () => { branchSub.unsubscribe(); }
    });
  }

  removeBcCommand(label: string | undefined) {
    if (!label) return;
    let item = this.bcItems.find(b => b.label === label);
    if (item) {
      let index = this.bcItems.indexOf(item);
      item.command = undefined;
      this.bcItems.splice(index, 1, item);
    }
  }

}
