import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppMaterialModule} from './material-module';
import {MsalRedirectComponent} from '@azure/msal-angular';
import {HeaderNavComponent} from './components/header-nav/header-nav.component';
import {PatientInfoModalComponent} from './components/patient-info-modal/patient-info-modal.component';
import {ResendInviteModalComponent} from './components/resend-invite-modal/resend-invite-modal.component';
import {AdjustmentModalComponent} from './components/adjustment-modal/adjustment-modal.component';
import {CancelLoanModalComponent} from './components/cancel-loan-modal/cancel-loan-modal.component';
import {SearchAppsPopupComponent} from './components/search-apps-popup/search-apps-popup.component';
import {CcInputComponent} from './components/form-controls/cc-input/cc-input.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CcRadioComponent} from './components/form-controls/cc-radio/cc-radio.component';
import {CcNumberComponent} from './components/form-controls/cc-number/cc-number.component';
import {CcDatePickerComponent} from './components/form-controls/cc-date-picker/cc-date-picker.component';
import {AuditAppModalComponent} from './components/audit-app-modal/audit-app-modal.component';
import {ApplicationEntryComponent} from './components/app-form/application-entry/application-entry.component';
import {ApplicationConfirmComponent} from './components/app-form/application-confirm/application-confirm.component';
import {PatientInfoFormComponent} from './components/app-form/patient-info-form/patient-info-form.component';
import {ApplicationStartComponent} from './components/app-form/application-start/application-start.component';
import {CcSelectComponent} from './components/form-controls/cc-select/cc-select.component';
import {CcInputDecimalComponent} from './components/form-controls/cc-input-decimal/cc-input-decimal.component';
import {CcTelInputComponent} from './components/form-controls/cc-tel-input/cc-tel-input.component';
import {PhoneMaskDirective} from './directives/phone-mask.directive';
import {SsnMaskDirective} from './directives/ssn-mask.directive';
import {DecimalMaskDirective} from './directives/decimal-mask.directive';
import {HostNameCheckComponent} from './components/host-name-check/host-name-check.component';
import {FormatMoneyPipe} from './pipes/format-money.pipe';
import {ReportsPageComponent} from './pages/reports-page/reports-page.component';
import {PowerBIEmbedModule} from 'powerbi-client-angular';
import {FullscreenLoadingComponent} from './components/fullscreen-loading/fullscreen-loading.component';
import {ReportsListComponent} from './components/reports/reports-list/reports-list.component';
import {SelectedReportComponent} from './components/reports/selected-report/selected-report.component';
import {TransferLocationComponent} from './components/transfer-location/transfer-location.component';
import {SuccessModalComponent} from './components/shared/success-modal/success-modal.component';
import {ErrorModalComponent} from './components/shared/error-modal/error-modal.component';
import {ApplicationPageComponent} from './pages/application-page/application-page.component';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {CdkColumnDef} from '@angular/cdk/table';
import {PatternsComponent} from './components/patterns/patterns.component';
import {CcMatSelectComponent} from './components/form-controls/cc-mat-select/cc-mat-select.component';
import {ErrorBannerComponent} from './components/shared/error-banner/error-banner.component';
import {ApplicationsPageComponent} from './pages/applications-page/applications-page.component';
import {LocationsPageComponent} from './pages/locations-page/locations-page.component';
import {LearnMoreComponent} from './pages/learn-more/learn-more.component';
import {SupportComponent} from './pages/support/support.component';
import {AppStoreModule} from './store/app-store.module';
import {StringReplacePipe} from './pipes/string-replace.pipe';
import {RouterModule} from '@angular/router';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {SelectProviderPageComponent} from './pages/select-provider-page/select-provider-page.component';
import {ChipComponent} from './components/shared/chip/chip.component';
import {AdjustApplicationPageComponent} from './pages/adjust-application-page/adjust-application-page.component';
import {NgxMaskModule} from 'ngx-mask';
import {FooterComponent} from './components/footer/footer.component';
import {CcareFooterComponent} from './components/footer/ccare-footer/ccare-footer.component';
import {AllyFooterComponent} from './components/footer/ally-footer/ally-footer.component';
import {AllyHeaderComponent} from './components/header-nav/ally-header/ally-header.component';
import {CcareHeaderComponent} from './components/header-nav/ccare-header/ccare-header.component';
import {DynamicComponentDirective} from './directives/dynamic-component.directive';
import {DynamicComponent} from './components/shared/dynamic/dynamic.component';
import {CoveredLibModule} from '@covered/covered-ui';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PowerpayHeaderComponent } from './components/header-nav/powerpay-header/powerpay-header.component';
import { PowerpayFooterComponent } from './components/footer/powerpay-footer/powerpay-footer.component';
import { ApplicantTransferComponent } from './pages/applicant-transfer/applicant-transfer.component';
import { RemittanceReportComponent } from './pages/remittance-report/remittance-report.component';
import { MsalApplicationModule } from './msal-application.module';

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        ApplicationsPageComponent,
        LocationsPageComponent,
        HeaderNavComponent,
        PatientInfoModalComponent,
        ResendInviteModalComponent,
        AdjustmentModalComponent,
        CancelLoanModalComponent,
        SearchAppsPopupComponent,
        CcInputComponent,
        CcRadioComponent,
        CcNumberComponent,
        CcDatePickerComponent,
        AuditAppModalComponent,
        ApplicationEntryComponent,
        ApplicationConfirmComponent,
        PatientInfoFormComponent,
        ApplicationStartComponent,
        CcSelectComponent,
        CcInputDecimalComponent,
        CcTelInputComponent,
        PhoneMaskDirective,
        SsnMaskDirective,
        DecimalMaskDirective,
        DynamicComponentDirective,
        HostNameCheckComponent,
        FormatMoneyPipe,
        StringReplacePipe,
        ReportsPageComponent,
        FullscreenLoadingComponent,
        ReportsListComponent,
        SelectedReportComponent,
        TransferLocationComponent,
        SuccessModalComponent,
        ErrorModalComponent,
        ApplicationPageComponent,
        SideNavComponent,
        PatternsComponent,
        CcMatSelectComponent,
        ErrorBannerComponent,
        LearnMoreComponent,
        SupportComponent,
        ChipComponent,
        SelectProviderPageComponent,
        AdjustApplicationPageComponent,
        FooterComponent,
        CcareFooterComponent,
        AllyFooterComponent,
        AllyHeaderComponent,
        CcareHeaderComponent,
        DynamicComponent,
        PowerpayHeaderComponent,
        PowerpayFooterComponent,
        ApplicantTransferComponent,
        RemittanceReportComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AppMaterialModule,
        CoveredLibModule,
        TableModule,
        BreadcrumbModule,
        InputTextModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        PowerBIEmbedModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule,
        MsalApplicationModule.forRoot(),
        FontAwesomeModule,
        AppStoreModule
    ],
    providers: [
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: MsalInterceptor,
        //     multi: true
        // },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: ApiInterceptor,
        //     multi: true
        // },
        // MsalGuard,
        CdkColumnDef
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
