<div class="page-view">
  <div class="page-view__loading card" *ngIf="loading">
    <div class="card-body">
      <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
    </div>
  </div>
  <div *ngIf="!loading" class="page-view__body">
    <div class="page-view__header">
      <h3>Select location</h3>
      <div class="search">
        <mat-icon svgIcon="search"></mat-icon>
        <input placeholder="SEARCH LOCATION" matInput [formControl]="searchControl"/>
      </div>
    </div>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" aria-label="Location">
        <!-- Location Name Column -->
        <ng-container matColumnDef="branchName">
          <th mat-header-cell *matHeaderCellDef> Location Name</th>
          <td mat-cell *matCellDef="let location"> {{location.branchName}} </td>
        </ng-container>

        <!-- Location Id Column -->
        <ng-container matColumnDef="branchId">
          <th mat-header-cell *matHeaderCellDef> Location ID</th>
          <td mat-cell *matCellDef="let location"> {{location.branchId}} </td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef> Address</th>
          <td mat-cell
              *matCellDef="let location"> {{location.address + ', ' + location.city + ', ' + location.state + ', ' + location.zip}} </td>
        </ng-container>

        <ng-container matColumnDef="zip">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let location">
            <div class="align-right">
              <button (click)="goToBranch(location)" class="button button-medium button-secondary">Select</button>
            </div>
          </td>
        </ng-container>
        <tr *matNoDataRow>
          <td [attr.colspan]="displayedColumns.length" class="no-data-row">
            <div class="heading">No results are found</div>
            There weren’t any results matching your search.
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="footer"></div>
    </div>
  </div>
</div>
