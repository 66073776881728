export const INPUT_FORMAT = {
  zipCode: {
    mask: '9{5}',
    regex: /^[0-9]{5}(?:-[0-9]{4})?$/
  },
  date: {
    mask: '09/09/0099',
    regex: ''
  }
};
