import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-host-name-check',
  templateUrl: './host-name-check.component.html',
  styleUrls: ['./host-name-check.component.scss']
})
export class HostNameCheckComponent implements OnInit {
  hostname = window.location.hostname;
  constructor() { }

  ngOnInit(): void {
  }

}
