import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AppStorageKeys} from '../configs/app-storage-keys';
import {BranchModel} from '../models/branch-model';
import {CustomerModel, ICrossroadLink} from '../models/customer-model';
import {ApiService} from './api.service';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';
import {PortfolioItem} from '../models/portfolio-item';
import {PortfoliosModel} from '../models/portfolios-model';
import {ISupportInfo} from '../pages/support/support.component';
import {environment} from '../../environments/environment';
import { UserRoleModel } from '../models/user-role-model';
import { SettingService } from './setting.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private _userRoles = new BehaviorSubject<UserRoleModel[]>([]);
  private portfolios = new BehaviorSubject<PortfoliosModel | null>(null);
  private _customer = new BehaviorSubject<CustomerModel | null>(null);
  private _customerGuid = new BehaviorSubject<string>('');
  private _branchGuid = new BehaviorSubject<string>('');
  private _branch = new BehaviorSubject<BranchModel | null>(null);
  private _customerSetting = new BehaviorSubject<any | null>(null);
  portfolios$ = this.portfolios.asObservable();
  customer$ = this._customer.asObservable();
  branch$ = this._branch.asObservable();
  customerGuid$ = this._customerGuid.asObservable();
  branchGuid$ = this._branchGuid.asObservable();
  customerSetting$ = this._customerSetting.asObservable();
  userRoles$ = this._userRoles.asObservable();

  constructor(private apiService: ApiService,
      private settings: SettingService,
     private auth: AuthService) {
  }

  setCustomer(item: CustomerModel) {
    localStorage.setItem(AppStorageKeys.selectedCustomerStorageKey, JSON.stringify(item));
    this._customer.next(item);
  }

  getPrevCustomerGuid() {
    var json = localStorage.getItem(AppStorageKeys.selectedCustomerStorageKey);
    if (json) {
      let customer: CustomerModel = JSON.parse(json);
      if (customer && customer.customerGUID) {
        return customer.customerGUID;
      }
    }
    return null;
  }

  setCustomerGuid(guid: string | undefined | null, skipCustomer?: boolean) {
    if (guid) {
      this._customerGuid.next(guid);
      if (!skipCustomer) {
        this.getCustomer();
      }
      return;
    }
  }

  setBranch(item: BranchModel | null, skipCustomer?: boolean) {
    this._branch.next(item);
    if (!skipCustomer) {
      this.setCustomerSetting();
    }
  }

  getCustomerSetting() {
    return this._customerSetting.getValue();
  }

  setCustomerSetting() {
    var customerGuid = this._customerGuid.getValue();
    var branch = this._branch.getValue();
    var branchGuid = this._branchGuid.getValue();
    var apiPath = `app/customer-settings/${customerGuid}/${branch?.branchGuid || branchGuid}`;
    this.apiService.get(apiPath).subscribe((res: any) => {
        this._customerSetting.next(res);
      },
      (err: any) => {
      });
  }

  setBranchGuid(item: string, skipBranchApi?: boolean) {
    this._branchGuid.next(item);
    if (item && !skipBranchApi) {
      this.getBranch();
    }
  }

  getCustomerName() {
    var customer = this._customer.getValue();
    return customer?.customerName;
  }

  getCustomerId() {
    var customer = this._customer.getValue();
    return customer?.customerID;
  }

  getCustomerGuid() {
    var customer = this._customer.getValue();
    return customer?.customerGUID;
  }

  getCustomer() {
    this.auth.userGuid$.subscribe((user) => {
      if (user && user.loggedUserGuid) {
        var customerGuid = this._customerGuid.getValue();
        var apiPath = `app/customer/${customerGuid}/bop`;
        this.apiService.get(apiPath).subscribe((res: CustomerModel) => {
            this.setCustomer(res);
            var custGuid = this._customerGuid.getValue();
            if (custGuid !== res.customerGUID)
              this._customerGuid.next(res.customerGUID);
          },
          (err: any) => {
            console.error(err);
          });
      }
    });
  }

  getBranch() {
    return this._branch.getValue();
  }

  getBranchWithParams(customerGuid: string, branchGuid: string): any {
    const apiPath = `provider/branch-location/${customerGuid}/${branchGuid}`;
    this.apiService.get(apiPath).subscribe(res => {
        this.setBranch(res, true);
      },
      (err: any) => {
        console.error(err);
      });

    return of([]);
  }

  getSelectedBranch(customerGuid: string, branchGuid: string) {
    const apiPath = `provider/branch-location/${customerGuid}/${branchGuid}`;
    return this.apiService.get(apiPath);
  }

  getCustomerSettings(customerGuid: string, branchGuid?: string) {
    const apiPath = `app/customer-settings/${customerGuid}/${branchGuid}`;
    return this.apiService.get(apiPath);
  }

  setCustomerSettingWithParams(customerGuid: string, branchGuid?: string) {
    const apiPath = `app/customer-settings/${customerGuid}/${branchGuid}`;
    this.apiService.get(apiPath).subscribe((res: any) => {
        this._customerSetting.next(res);
      },
      (err: any) => {
      });
    return of([]);
  }

  getCrossRoads(customerId: string | null): Observable<ICrossroadLink[]> {
    const url = 'app/bop-customer-settings';
    if (!customerId || customerId.length == 0) {
      customerId = '00000000-0000-0000-0000-000000000000';
    }
    const payload = {
      customerGuid: customerId,
      domainName: window.location.hostname
    };


    return this.apiService.post(url, payload)
      .pipe(
        map((data: any) => {
          // environment.smartyStreets = {
          //   url: data.SmartyStreetsApiBaseURL,
          //   key: data.SmartyStreetsApiKey
          // };
          let hasCSAccess = data.HasCSAccess == "1";
          let hasAdminAccess = data.HasAdminAccess == "1";
          
          data = data && data.links.filter(d => d.app == "bop") || [];
          data = data.sort(function (a: any, b: any) {
            return a.order - b.order
          });
          const crossroadLinks: ICrossroadLink[] = [];
          for (let key in data) {
            crossroadLinks.push({...data[key], id: key, itemId: data[key]['id']});
          }

          let custGuid = this._customerGuid.getValue();
          let index = data.length;
        var adminUrl = this.settings.getValue('URL_Admin');
        var csUrl = this.settings.getValue('URL_CS');
          if (hasAdminAccess) {
            crossroadLinks.push({
              app: "admin",
              icon: "circle-user",
              id: index,
              isExternal: 1,
              itemId: "Admin",
              klass: "admin",
              order: index,
              target: "_self",
              title: "Admin",
              url: `${adminUrl}?customerGuid=${custGuid}`
            });
            index++;
          }
          if (hasCSAccess) {
            crossroadLinks.push({
              app: "cs",
              icon: "link",
              id: index,
              isExternal: 1,
              itemId: "CS",
              klass: "cs",
              order: index,
              target: "_self",
              title: "CS",
              url: `${csUrl}home/${custGuid}`
            });
          }
          return crossroadLinks;
        })
      );
  }

  setPortfolios() {
    var result = this.portfolios.getValue();
    if (result && result.portfolios && result.portfolios.length)
      return;

    this.auth.userGuid$.subscribe(user => {
      if (!user) return;
      if (user.loggedUserGuid) {
        result = new PortfoliosModel();
        result.loading = true;
        this.portfolios.next(result);
        this.apiService.get(`user/portfolios`)
          .subscribe((res: any) => {
              var list = res || [];
              if (list.length) {
                list.forEach((item: PortfolioItem) => {
                  if (item.adminIcon.indexOf('bytearchive') !== -1)
                    item.adminIcon = 'bytepay.ico';

                  item.dashboardUrl = `/dashboard/${item.customerGuid}`;
                  item.adminIcon = (item.adminIcon && item.adminIcon.indexOf('blank') === -1)
                    ? `/assets/images/${item.adminIcon}`
                    : '';
                });
              }
              result = new PortfoliosModel();
              result.loading = false;
              result.portfolios = list;
              result.error = list.length ? '' : 'No portfolio found for authenticated user';
              this.portfolios.next(result);
            },
            (err: any) => {
              result = new PortfoliosModel();
              result.loading = false;
              result.error = 'Error occurred while getting portfolios';
              this.portfolios.next(result);
            }
          );
      } else {
        result = new PortfoliosModel();
        result.loading = false;
        result.portfolios = [];
        result.error = 'Account setup not complete. Please contact support';
        this.portfolios.next(result);
      }
    });
  }

  getSupportInfo(customerId: string | null): Observable<ISupportInfo> {
    const url = `app/provider-support-info/${customerId}`;
    return this.apiService.get(url);
  }

  getProviderName(provider: string) {
    const name = provider.split('-').map(element => {
      return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
    }).join().replace(/,/g, '');

    return name.charAt(0).toLowerCase() + name.substring(1);
  }
}
