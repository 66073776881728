import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PatientInfoModalData} from 'src/app/models/app-modal-data';
import {ApiService} from 'src/app/services/api.service';
import {faCircleCheck} from '@fortawesome/free-regular-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {LookupModel} from '../../models/lookup-model';

//import {CcMatSelectComponent} from 'src/app/components/form-controls/cc-mat-select';

@Component({
  selector: 'app-cancel-loan-modal',
  templateUrl: './cancel-loan-modal.component.html',
  styleUrls: ['./cancel-loan-modal.component.scss']
})
export class CancelLoanModalComponent implements OnInit {
  confirmForm: UntypedFormGroup = new UntypedFormGroup({});
  stage = 'confirmed';
  loading = false;
  reasons: LookupModel[] = [];
  iconCheck = faCircleCheck as IconProp;
  errorMessage = '';

  constructor(public dialogRef: MatDialogRef<CancelLoanModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: PatientInfoModalData,
              private fb: UntypedFormBuilder,
              private apiService: ApiService) {
  }


  ngOnInit(): void {
    this.getReasons();
    this.confirmForm = this.fb.group({
      reason: ['', Validators.required]
    })
  }

  getReasons() {
    this.loading = true;
    this.apiService.get(`app/bop-lookups/${this.data.customerGuid}`)
      .subscribe((res: any) => {
          this.loading = false;
          this.reasons = res.cancelReasons || [];
        },
        (err: any) => {
          console.error(err);
          this.loading = false;
        }
      );
  }

  cancel() {
    if (this.stage === 'success') {
      this.dialogRef.close('updated');
    } else {
      this.dialogRef.close();
    }
  }

  onSubmit(): boolean {
    this.errorMessage = '';
    if (this.stage === 'edit') {
      this.stage = "confirmed";
      return true;
    } else {
      if (this.confirmForm.invalid) {
        this.confirmForm.controls.reason.markAsDirty();
        this.confirmForm.controls.reason.markAsTouched();
        this.confirmForm.controls.reason.updateValueAndValidity();
        return false;
      }

      this.loading = true;
      const selectedReasonId = this.confirmForm.controls.reason.value;
      const path = "app/cancel-loan";
      this.apiService.post(path,
        {
          customerGuid: this.data.customerGuid,
          branchGuid: this.data.branchGuid,
          patientGuid: this.data.patientGuid,
          reasonDesc: this.reasons.find((reason: LookupModel) => reason.id === selectedReasonId)!.desc,
          reasonId: Number.parseInt(selectedReasonId)
        })
        .subscribe((res: any) => {
            this.loading = false;
            this.dialogRef.close('updated');
          },
          (err: any) => {
            console.error(err);
            this.loading = false;
            this.errorMessage = err && err.error && err.error.text ? err.error.text : 'Error Occurred';
            if (this.errorMessage === 'Success') {
              this.stage = "success";
            }
            // this.resetStage();
          }
        );
      return true;
    }
  }
}
