<div class="page-modal-content">
  <div class="page-view__loading card" *ngIf="submitting">
    <div class="card-body">
      <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
    </div>
  </div>
  <div class="bop-application" *ngIf="!submitting">
    <div class="expansion" *ngIf="step === 1">
      <app-application-entry
        (onAction)="onAction($event)"
        [customerGuid]="data.customerGuid"
        [branchGuid]="data.branchGuid">
      </app-application-entry>
    </div>
    <div *ngIf="step === 2">
      <app-application-confirm
        [amount]="amountFinanced"
        [submitting]="submitting"
        (goToStep)="goStep($event)"
        [error]="error">
      </app-application-confirm>
    </div>
    <div *ngIf="step === 3">
      <h2>Link sent successfully</h2>
      <p class="description" *ngIf="this.formObj.LeadInformation.SendEmail && this.formObj.LeadInformation.SendSMS">
        The application link was sent to the phone number and email address that was provided.
      </p>
      <p class="description" *ngIf="this.formObj.LeadInformation.SendEmail && !this.formObj.LeadInformation.SendSMS">
        The application link was sent to the email address that was provided.
      </p>
      <p class="description" *ngIf="!this.formObj.LeadInformation.SendEmail && this.formObj.LeadInformation.SendSMS">
        The application link was sent to the phone number that was provided.
      </p>
      <fa-icon [icon]="iconCheck" size="5x" class="success"></fa-icon>
      <div class="actions">
        <button class="button button-primary" (click)="close()">Okay</button>
        <button class="button button-medium button-secondary" (click)="stayHere()" *ngIf="startUrl">
          Continue On This Device
        </button>
      </div>
    </div>
  </div>
</div>
