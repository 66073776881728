<mat-tab-group [selectedIndex]="selected.value">
  <mat-tab label="Headings">
    <h1>H1 Heading</h1>
    <h2>H2 Heading</h2>
    <h3>H3 Heading</h3>
  </mat-tab>
  <mat-tab label="Buttons">
    <h2>Main buttons</h2>
    <button class="button button-primary">Primary</button>
    <button class="button button-primary" disabled="disabled">Disabled</button>
    <button class="button button-secondary">Secondary</button>
    <button class="button button-secondary" disabled="disabled">Disabled</button>
    <h2>Mini buttons</h2>
    <button class="button button-primary button-medium">Mini Primary</button>
    <button class="button button-primary button-medium" disabled="disabled">Mini Primary</button>
    <button class="button button-secondary button-medium">Mini</button>
    <button class="button button-secondary button-medium" disabled="disabled">Mini medium</button>
  </mat-tab>
  <mat-tab label="Inputs">
    Inputs
    <div class="row">
      <div class="col-md-4">
        <app-cc-input
          controlName="test1"
          [group]="fg"
          label="Test Input">
        </app-cc-input>
      </div>
      <div class="col-md-4">
        <app-cc-input
          controlName="test2"
          [group]="fg"
          label="Test Input">
        </app-cc-input>
      </div>
      <div class="col-md-4">
        <app-cc-input
          controlName="test3"
          [group]="fg"
          disabled="true"
          label="Test Input">
        </app-cc-input>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Error Banner" class="active">
    <h3>Less content</h3>
    <app-error-banner
      message="There's something wrong with your application"></app-error-banner>
    <h3>Content wrap</h3>
    <app-error-banner message="ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR"></app-error-banner>
    <h3>More content</h3>
    <app-error-banner message="ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR"></app-error-banner>
  </mat-tab>
</mat-tab-group>
