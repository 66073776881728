export const environment = {
  production: false,
  redirectUrl: "/",
  appVersion: require('../../package.json').version + '-' + require('../../package.json').buildNumber + '-dev',
  apiEndpoint: "https://dev-admin-coveredcare-api.azurewebsites.net/api/",
  appInsights: {
    instrumentationKey: "5d938af6-44be-41c9-824c-9446c90f3c39"
  },
  // smartyStreets: {
  //   url: '',
  //   key: 0
  // }
};