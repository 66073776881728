import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
  @Input() statusId: string | null = '';
  @Input() status: string | null = '';
  klass: string | null = '';

  constructor() {
  }

  ngOnInit(): void {
    this.klass = this.getStatusClass();
  }

  getStatusClass() {
    if (this.status && this.status.toLocaleLowerCase().trim() === 'paid in full') {
      return 'status-green-bold';
    }

    switch (this.statusId) {
      case '8':
      case '7':
        return 'status-green';
      case '16':
      case '201':
      case '272':
        return 'status-gray';
      case '246':
        return 'status-black';
      case '193':
        return'status-gray-empty';
    }

    if (this.status && this.status.toLocaleLowerCase().trim() === 'no offer') {
      return 'status-black';
    }

    return 'status-bluelight'
  }
}
