import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ally-footer',
  templateUrl: './ally-footer.component.html',
  styleUrls: ['./ally-footer.component.scss']
})
export class AllyFooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
