import {CrossroadsState} from './provider/provider.state';
import {routerReducer, RouterReducerState} from '@ngrx/router-store';
import {CROSSROADS_STATE_NAME} from './provider/provider.selector';
import {crossroadsReducer} from './provider/provider.reducer';

export interface AppState {
  [CROSSROADS_STATE_NAME]: CrossroadsState
  router: RouterReducerState;
}

export const appReducer = {
  [CROSSROADS_STATE_NAME]: crossroadsReducer,
  router: routerReducer,
};
