import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {BopAppActions} from '../application-start/application-start.component';
import {maskDecimal} from '../../../services/util.service';

@Component({
  selector: 'app-application-confirm',
  templateUrl: './application-confirm.component.html',
  styleUrls: ['./application-confirm.component.scss']
})
export class ApplicationConfirmComponent implements OnInit {
  @Input() amount: number = 0;
  @Input() submitting = false;
  @Input() error = '';
  amountMismatch = false;
  // get amount(): number { return this._amount; }
  // set amount(amount: number) {
  //   this._amount = amount || 0;
  //   this.confirmForm.patchValue({
  //     amount: amount
  //   });
  // }
  // private _amount = 0;
  @Output() goToStep = new EventEmitter<BopAppActions>();
  confirmForm = this.fb.group({
    amount: ['', Validators.required]
  });

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
  }

  onChange() {
    this.error = "";
  }

  onSubmit() {
    this.amountMismatch = false;
    var enteredAmount = Number(this.getAmountNumber(this.confirmForm.value.amount || '0'));
    var actualAmount = Number(this.getAmountNumber(this.amount.toString() || '0'));
    if (enteredAmount != actualAmount) {
      this.amountMismatch = true;
      return;
    }
    this.error = "";
    const action = {formObject: {}, step: 3, amount: enteredAmount};
    this.goToStep.emit(action);
  }

  onBlur() {
    let val = this.confirmForm.controls['amount'].value;
    if (val) {
      val = val.replace(',', '');
      const masked = maskDecimal(val);
      if (masked != val)
        this.confirmForm.controls['amount'].patchValue(masked);
    }
  }

  gotoApplications() {
    const action = {formObject: {}, step: 0, amount: 0, type: 'close'};
    this.goToStep.emit(action);
  }

  onCancel() {
    const action = {formObject: {}, step: 0, amount: 0};
    this.goToStep.emit(action);
  }

  private getAmountNumber(amt: string) {
    if (amt.indexOf(','))
      amt = amt.replace(',', '');
    return amt;
  }
}
