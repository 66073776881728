import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastPos = new BehaviorSubject<string>('bottom-center');
  toastPos$ = this.toastPos.asObservable();

  toastDefaultPos: string = 'bottom-center';
  toastKey: string = 'appCompToast';

  constructor(
    private messageService: MessageService
  ) {
    this.toastPos.next(this.toastDefaultPos);
   }

   addError(message: string, summary = "Error"){
    this.add({ severity: 'error', summary: summary, detail: message })
   }

  add(message: Message, position?: string): void {
    this.toastPos.next(position ?? this.toastDefaultPos);
    message.key = message.key ?? this.toastKey;
    this.messageService.add(message);    
  }

  addAll(messages: Message[], position?: string): void {
    this.toastPos.next(position ?? this.toastDefaultPos);
    messages.forEach(m => { m.key = m.key ?? this.toastKey; });
    this.messageService.addAll(messages);
  }

  clear(key?: string): void {
    this.messageService.clear(key);
  }

  underConstruction() {    
    this.messageService.add({
      key: this.toastKey,
      severity: 'warn',
      summary: "Under Construction",
      detail: "Link/Feature is under construction and not yet working."
    });
  }

  showCancelToast() {
    this.messageService.add({
      key: this.toastKey,
      severity: 'warn',
      summary: 'Operation Cancelled' 
    });
  }
}
