import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  private referralUrl = '';
  private currentUrl = '';

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.referralUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public getReferralUrl() {
    return this.referralUrl;
  }
}
