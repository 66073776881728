import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {faAsterisk} from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-cc-tel-input',
  templateUrl: './cc-tel-input.component.html',
  styleUrls: ['./cc-tel-input.component.scss']
})
export class CcTelInputComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() maxLen: number = 20;
  @Input() id?: string;
  @Input() label: string = "";
  @Input() type: string = "text";
  @Input() placeholder: string = "placeholder";
  @Input() val: string = "";
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  @Input() isRequired = false;
  @Input() errorMessage: string = "This field is invalid";
  mask = '000-000-0000';
  iconRequired = faAsterisk as IconProp;

  constructor() {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
  }

}
