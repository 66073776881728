<div class="page-modal-content">
  <h2 *ngIf="!error">Verify amount to be financed</h2>
  <h2 *ngIf="error">There was a problem</h2>
  <p class="description" *ngIf="!error">Please make sure that the procedure amount is correct by re-entering it
    below.</p>
  <p class="description">{{error}}</p>
  <app-error-banner *ngIf="amountMismatch"
                    message="The amount you entered does not match the original input financed amount"></app-error-banner>
  <form [formGroup]="confirmForm" class="amount-form" (ngSubmit)="onSubmit()">
    <mat-icon svgIcon="exclamation-circle" *ngIf="error" class="error-icon"></mat-icon>
    <div class="special-input-container" *ngIf="!error">
      <div class="special-input amount" [ngClass]="{'error': amountMismatch}">
        <p *ngIf="amountMismatch" class="error-message">Amount financed must match.</p>
        <input
          class="form-control"
          placeholder="0.00"
          formControlName="amount"
          (blur)="onBlur()"
          pattern="\d*"
        />
      </div>
    </div>
    <div class="actions" *ngIf="!error">
      <button [disabled]="submitting" class="button button-primary" type="submit">Continue</button>
      <button [disabled]="submitting" class="button button-medium button-secondary" type="button" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="actions" *ngIf="error">
      <button [disabled]="submitting" class="button button-primary" type="button" (click)="gotoApplications()">
        Okay
      </button>
    </div>
  </form>
</div>
