<mat-toolbar class="header">
  <div class="container">
    <div class="d-flex align-items-center">
      <div class="logo">
        <a [routerLink]="homeUrl" class="header-logo">
          <div dynamicComponentHost></div>
          <app-dynamic></app-dynamic>
        </a>
      </div>
      <div class="mr-auto w-12 p-1 provider-name flex align-items-center justify-content-center"><span *ngIf="hasMultiplePortfolios" class="font-normal">{{providerName}}</span></div>
      <div class="">
        <button mat-button [matMenuTriggerFor]="beforeMenu" class="profile-name">
          <mat-icon [svgIcon]="'user'"></mat-icon> {{fullName}}
          <mat-icon class="ms-1">expand_more</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="after" class="bop-menu">
          <button *ngIf="hasAllProviders" mat-menu-item (click)="menuAction('providers')">
            <mat-icon [svgIcon]="'users'"></mat-icon>
            All Providers
          </button>
          <button *ngIf="hasAdminAccess" mat-menu-item (click)="menuAction('admin')">
            <mat-icon [svgIcon]="'portal'"></mat-icon>
            Admin
          </button>
          <button *ngIf="hasCSAccess" mat-menu-item (click)="menuAction('cs')">
            <mat-icon [svgIcon]="'link'"></mat-icon>
            CS
          </button>
          <button mat-menu-item (click)="menuAction('logout')">
            <mat-icon [svgIcon]="'logout'"></mat-icon>
            Logout
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
