import {Overlay, OverlayConfig, OverlayRef, PositionStrategy} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject, Subject} from 'rxjs';
import {FullscreenLoadingComponent} from '../components/fullscreen-loading/fullscreen-loading.component';
import {ErrorModalComponent} from '../components/shared/error-modal/error-modal.component';
import {SuccessModalComponent} from '../components/shared/success-modal/success-modal.component';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  private fullscreenOverlayFef: OverlayRef | undefined;
  private _fullWidth = new BehaviorSubject<boolean>(false);
  private _refreshApps = new Subject<boolean>();
  private isLoading = false;
  refreshApps$ = this._refreshApps.asObservable();
  fullWidth$ = this._fullWidth.asObservable();
  private renderer: Renderer2 = {} as Renderer2;

  constructor(private overlay: Overlay,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
              rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  refreshApps(refresh: boolean) {
    this._refreshApps.next(refresh);
  }

  showLoading() {
    var hasAttached = this.fullscreenOverlayFef?.hasAttached();
    if (this.isLoading) return;
    var config = this.getOverlayLoadingConfig();
    this.fullscreenOverlayFef = this.overlay.create(config);
    this.fullscreenOverlayFef.attach(new ComponentPortal(FullscreenLoadingComponent));
    this.isLoading = true;
  }

  dismissLoading() {
    this.fullscreenOverlayFef?.dispose();
    this.isLoading = false;
  }

  setPageWidth(val: boolean) {
    this._fullWidth.next(val);
  }

  showSuccessModal(header: any, message: any) {
    return this.showModal(SuccessModalComponent, '350px', {header, message})
  }

  showErrorModal(header: string, message: string) {
    return this.showModal(ErrorModalComponent, '350px', {header, message})
  }

  showModal(component: any, width: string, data: any) {
    this.addClassToBody();
    return this.dialog.open(component, {
      width: '640px',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'app-dialog',
      data
    });
  }

  showSnackBar(message: string) {
    this._snackBar.open(message, "Close", {
      duration: 4000,
    });
  }

  private getOverlayLoadingConfig() {
    let config = new OverlayConfig();
    config.width = '100vw';
    config.height = '100vh';
    config.panelClass = 'loading-card';
    config.hasBackdrop = true;
    config.scrollStrategy = this.overlay.scrollStrategies.block();
    config.positionStrategy = this.positionGloballyCenter();
    return config;
  }

  private positionGloballyCenter(): PositionStrategy {
    return this.overlay.position().global().centerHorizontally().centerVertically();
  }

  addClassToBody(provider?: string) {
    this.renderer.addClass(document.body, provider || 'modal-open');
  }

  removeClassFromBody(provider?: string) {
    this.renderer.removeClass(document.body, provider || 'modal-open');
  }
}
