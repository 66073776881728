import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientInfoModalData } from 'src/app/models/app-modal-data';
import { PatientAppDetails } from 'src/app/models/patient-app-details';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-patient-info-modal',
  templateUrl: './patient-info-modal.component.html',
  styleUrls: ['./patient-info-modal.component.scss']
})
export class PatientInfoModalComponent implements OnInit {

  loading = true;
  info: PatientAppDetails = new PatientAppDetails;
  isAlly: boolean = false;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: PatientInfoModalData, private apiService: ApiService) { }

  ngOnInit(): void {
    this.getDetails();
  }

  getDetails() {
    this.loading = true;
    this.apiService.get(`app/patient-app-details/${this.data.customerGuid}/${this.data.branchGuid}/${this.data.patientGuid}`)
      .subscribe((res: any) => {
        this.loading = false;
        this.info = res;
        this.isAlly = this.info.financedBy.indexOf('Ally') > -1;
      },
        (err: any) => {
          console.error(err);
          this.loading = false;
        }
      );
  }

}
