<div class="flex flex-column w-9 mx-auto" *ngIf="branchGuid">
    <div class="cds-card p-5 m-5">
        <div class="page-view__body">
            <div class="transfer-title mx-auto text-center pt-6">It's the applicant's time to shine</div>
            <div class="transfer-message mt-5 mb-8 mx-auto text-center">Please hand the device to the applicant to continue the application process</div>
            <div class="transfer-image text-center my-8 py-8">
                <img src="assets/images/transfer-arrows.png" alt="Get started">
            </div>
            <div class="grid pb-8 mb-8">
                <covered-button id="btnCont" label="CONTINUE" class="p-button-lg col-offset-3 col-6 py-3" (click)="continue()"></covered-button>
            </div>
        </div>
    </div>
</div>