<div class="page-modal-content">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" class="py-2 my-5"></mat-progress-bar>
  <div *ngIf="!loading">
    <app-error-banner *ngIf="error" [message]="error"></app-error-banner>
    <div class="resend-invite" *ngIf="stage == 'edit'">
      <h2>Re-send Link</h2>
      <p class="description">Verify patient information</p>
      <form [formGroup]="resendForm" (ngSubmit)="submitBtn.nativeElement.focus(); onSubmit()">
        <app-cc-input [group]="resendForm" controlName="email" label="Email">
        </app-cc-input>
        <app-cc-tel-input *ngIf="!isAllyEnabled" controlName="phone" [group]="resendForm" label="Mobile">
        </app-cc-tel-input>
        <hr />
        <p class="text-center mb-5 mt-4 h4">Service Date</p>
        <app-cc-date-picker controlName="serviceDate" [group]="resendForm" [isSpecial]="true" placeholder="MM/DD/YYYY"
          klass="resend-invite" [addDays]="60" [isRequired]="true" message="Service Date must be in the future and must be between {{minDate.toLocaleDateString('en-029')}} and
            {{maxDate.toLocaleDateString('en-029')}}">
        </app-cc-date-picker>

        <div class="app-type-container my-4">
          <app-cc-radio [disabled]="readonly" [options]="resendTypes" [group]="resendForm" controlName="resendType"
            klass="app-type" (changed)="resendTypeChange($event)">
          </app-cc-radio>
          <div class="mb-3 m-2 checkboxes" *ngIf="resendForm.controls.resendType.value === 'Link' && !isAllyEnabled">
            <mat-checkbox formControlName="notifyEmail" class="mx-3" disableRipple>Email</mat-checkbox>
            <mat-checkbox formControlName="notifySms" class="mx-3" disableRipple>Cell Phone</mat-checkbox>
            <p class="text-danger m-0"
              *ngIf="!resendForm.controls.notifyEmail.value && !resendForm.controls.notifySms.value">
              One or more must be selected
            </p>
          </div>
        </div>

        <div class="my-4" *ngIf="showLang">
          <p class="description">Language Preference</p>
          <app-cc-radio [disabled]="readonly" [options]="languages" [group]="resendForm" controlName="language"
            klass="app-type">
          </app-cc-radio>
        </div>
        <div class="actions">
          <button class="button button-primary" type="submit" [disabled]="loading" #submitCta>Resend Link</button>
          <button class="button button-secondary button-medium" type="button" (click)="cancel()" [disabled]="loading">
            Back
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="stage == 'complete'">
      <h2>Link re-sent successfully</h2>
      <fa-icon [icon]="iconCheck" size="5x" class="success"></fa-icon>
      <div class="actions">
        <button class="button button-primary" (click)="cancel()">Okay</button>
        <!-- <button class="button button-medium button-secondary" (click)="stayHere()" *ngIf="!hideContinueDeviceBtn">
          Continue On This Device
        </button> -->
      </div>
    </div>
  </div>
</div>