import { Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appDecimalMask]'
})
export class DecimalMaskDirective implements OnChanges {
  // @Input() formControl: FormControl = new FormControl;
  constructor() { }
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\s+/g, '');
    trimmed = trimmed.replace(/[^0-9]*/g, '');
    trimmed = trimmed.replace(/-/g, '');
    if (trimmed.length <= 2) {
      input.value = trimmed;
      return;
    }
    var scale = trimmed.substring(trimmed.length - 2);
    var nums = trimmed.substring(0, trimmed.length - 2);
    var numSet = [];
    let numbers = [];
    for (var i = nums.length - 1; i >= 0; i--) {
      numSet.unshift(nums[i]);
      if (numSet.length == 3) {
        numbers.unshift(numSet.join(''));
        numSet = [];
      }
    }
    if (numSet.length)
      numbers.unshift(numSet.join(''));

    input.value = `${numbers.join(',')}.${scale}`;
    // if (this.formControl){
    //   this.formControl.patchValue(input.value);
    // }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.input){
      
    }
  }
}
