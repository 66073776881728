<div [formGroup]="group" class="form-control-container" *ngIf="group && !autocomplete">
  <div class="form-floating mb-1">
    <div class="error-message">
      <span *ngIf="group.controls[controlName].touched && group.controls[controlName]?.invalid">
        {{ errorMessage }}
      </span>
    </div>
    <input
      class="form-control form-control-sm"
      [ngClass]="group.controls[controlName].touched && group.controls[controlName]?.invalid ? 'is-invalid' : ''"
      [mask]="mask"
      [formControlName]="controlName"
      [readonly]="readonly"
      [id]="id"
      [placeholder]="placeholder"
      [maxLength]="maxLen"
      [type]="isNumber ? 'tel' : 'text'"
    >
    <label *ngIf="label" [for]="id"
           [ngClass]="{'required': isRequired}">{{label}}</label>
  </div>
</div>
<div [formGroup]="group" class="form-control-container" *ngIf="group && autocomplete">
  <div class="form-floating mb-1">
    <div class="error-message">
      <span *ngIf="group.controls[controlName].touched && group.controls[controlName]?.invalid">
        {{ errorMessage }}
      </span>
    </div>
    <input
      class="form-control form-control-sm"
      [ngClass]="group.controls[controlName].touched && group.controls[controlName]?.invalid ? 'is-invalid' : ''"
      [formControlName]="controlName"
      [readonly]="readonly"
      [id]="id"
      [placeholder]="placeholder"
      [maxLength]="maxLen"      
      type="text"
    >
    <label *ngIf="label" [for]="id"
           [ngClass]="{'required': isRequired}">{{label}}</label>
    <!-- <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let address of addressOptions" [value]="address.street_line"
                  (onSelectionChange)="onAutoCompleteOptionSelected(address)">
        {{ address.street_line }} {{address.city}} {{address.state}} {{address.zipcode}}
      </mat-option>
    </mat-autocomplete> -->
  </div>
</div>

<covered-toast [key]="toastKey" [position]="toastPos" [preventDuplicates]="preventDupMsg"></covered-toast>