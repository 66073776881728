import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSsnMask]'
})
export class SsnMaskDirective {

  constructor() { }
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\s+/g, '');
    trimmed = trimmed.replace(/[^0-9]*/g, '');
    if (trimmed.length > 11) {
      trimmed = trimmed.substr(0, 11);
    }

    trimmed = trimmed.replace(/-/g, '');
    let numbers = [];

    numbers.push(trimmed.substr(0, 3));
    if (trimmed.substr(3, 2) !== "")
      numbers.push(trimmed.substr(3, 2));
    if (trimmed.substr(5, 4) != "")
      numbers.push(trimmed.substr(5, 4));
    input.value = numbers.join('-');
  }
}
