<div class="" *ngIf="!hideSideNav">
  <mat-nav-list [ngClass]="{'expanded': expanded}" class="side-nav-list" disableRipple
                *ngIf="crossroadLinks">
    <mat-list-item class="side-nav-list__toggle" [title]="expanded?'Collapse Menu':'Expand Menu'">
      <mat-icon svgIcon="chevron-right" (click)="toggleSidenav()"
                (keyup.enter)="toggleSidenav()" class="side-nav-list__toggle-icon" tabindex="0"></mat-icon>
    </mat-list-item>
    <mat-list-item title="Home" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a [routerLink]="crossroadUrl">
        <mat-icon svgIcon="apps"></mat-icon>
        <span mat-line>Home</span>
      </a>
      <a [routerLink]="'/home/' + customerGuid" style="display: none;">
        <mat-icon svgIcon="apps"></mat-icon>
        <span mat-line>Home</span>
      </a>
    </mat-list-item>
    <mat-list-item [routerLink]="item.url"
                   (click)="navTo(item)"
                   (keyup.enter)="navTo(item)"
                   [title]="item.title"
                   class="{{item.klass}} {{url.toLowerCase().includes((item.url || '').toLowerCase()) ? 'active' : ''}}"
                   *ngFor="let item of crossroadLinks">
      <mat-icon [svgIcon]="(item.icon || '')"></mat-icon>
      <span mat-line>{{item.title}}</span>
    </mat-list-item>
  </mat-nav-list>
</div>
