export class PatientAppDetails {
  address = "";
  address2 = "";
  address2_Original = "";
  address_Original = "";
  assignTo = "";
  city = "";
  city_Original = "Dallas"
  dOB = "";
  doctorName = "";
  downPayment = "";
  emailAddress = "";
  firstName = "Sam"
  isPatientSameAsLead = "True"
  lastName = "Cooke"
  loanAmt = "1801.00"
  loanAmtInitial = "2000.00"
  patAddress1 = "";
  patAddress2 = "";
  patAppLinkMethod = "link"
  patCellPhone = "";
  patCity = "";
  patDOB = "";
  patEmail = "";
  patFirstName = "Sam"
  patLanguage = "EN"
  patLastName = "";
  patSSN = "";
  patState = "";
  patZipCode = "";
  patientID = "";
  patientRelationship = "";
  phoneNumber = "";
  postDataXML = "";
  sSN = "";
  sendEmail = "";
  sendSMS = "";
  serviceDate = "";
  serviceDateMax = "";
  serviceDate_Original = "";
  serviceProcedure = "";
  state = "";
  state_Original = "";
  zipCode = "";
  zipCode_Original = "";
  branchGUID = "";
  branchName = "";
  financedBy = "";
}
