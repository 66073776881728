<div class="container-fluid admin-bg">
  <div class="d-flex justify-content-center">
    <div class="card portfolio-list shadow my-4 mx-3">
      <div class="logo mb-3">
        <img [src]="logoUrl" class="img-center" alt="Logo" />
      </div>
      <div class="page-view__loading card" *ngIf="loadingPortfolios">
        <div class="card-body">
          <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
        </div>
      </div>
      <p *ngIf="error" class="mb-3 text-danger">{{error}}</p>
      <div *ngIf="!loadingPortfolios" class="mx-auto w-6">
        <div class="flex flex-row-reverse pb-2">
          <input #txtSearch [(ngModel)]="searchFilter" type="text" class="w-15rem" pInputText placeholder="Search"
            (input)="dtPortfolios.filterGlobal(txtSearch.value, 'contains')" />
        </div>
        <p-table #dtPortfolios [value]="portfolios" styleClass="p-datatable-lg"
          [globalFilterFields]="displayedColumns" [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]"
          [rows]="rows" [(first)]="first" [showCurrentPageReport]="false" responsiveLayout="scroll" [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="name" class="font-medium" scope="col">Provider Name <p-sortIcon field="name"></p-sortIcon>
              </th>
              <th class="text-center" scope="col"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-portfolio>
            <tr>
              <td>
                <img *ngIf="portfolio.adminIcon" [src]="portfolio.adminIcon" alt="" height="25" class="pr-3">               
                {{ portfolio.name }}
              </td>
              <td class="flex flex-row-reverse">
                <covered-button id="btn{{portfolio.customerGuid}}" label="Select" class="outlined px-4" (click)="gotoPortfolio(portfolio)"></covered-button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">No providers to display.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>