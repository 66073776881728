import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {LookupModel} from 'src/app/models/lookup-model';

@Component({
  selector: 'app-cc-radio',
  templateUrl: './cc-radio.component.html',
  styleUrls: ['./cc-radio.component.scss']
})
export class CcRadioComponent implements OnInit {
  @Input() controlName: string = '';
  @Input() id?: string;
  @Input() options: LookupModel[] = [];
  @Input() label: string = '';
  @Input() disabled = false;
  @Input() klass = '';
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  @Output() changed = new EventEmitter<string>();

  constructor() {
    var d = new Date();
    this.id = d.getTime().toString();
  }

  ngOnInit(): void {
  }

  change(e: any) {
    if (this.changed) {
      this.changed.emit(e.value);
    }
  }

}
