import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-applicant-transfer',
  templateUrl: './applicant-transfer.component.html',
  styleUrls: ['./applicant-transfer.component.scss']
})
export class ApplicantTransferComponent implements OnInit {

  branchGuid: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

  continue() {
    // do something
  }

}
