import {Injectable} from '@angular/core';
import {LookupModel} from '../models/lookup-model';
import { Validation } from '../models/validation';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  validationsCache: Validation[] = [];
  constructor(private apiService: ApiService) {
    this.setValidationsCache().then(() => {});
   }

  getStates(): LookupModel[] {
    var states = [
      {id: 'AL', desc: 'Alabama'},
      {id: 'AK', desc: 'Alaska'},
      {id: 'AZ', desc: 'Arizona'},
      {id: 'AR', desc: 'Arkansas'},
      {id: 'CA', desc: 'California'},
      {id: 'CO', desc: 'Colorado'},
      {id: 'CT', desc: 'Connecticut'},
      {id: 'DE', desc: 'Delaware'},
      {id: 'DC', desc: 'District Of Columbia'},
      {id: 'FL', desc: 'Florida'},
      {id: 'GA', desc: 'Georgia'},
      {id: 'HI', desc: 'Hawaii'},
      {id: 'ID', desc: 'Idaho'},
      {id: 'IL', desc: 'Illinois'},
      {id: 'IN', desc: 'Indiana'},
      {id: 'IA', desc: 'Iowa'},
      {id: 'KS', desc: 'Kansas'},
      {id: 'KY', desc: 'Kentucky'},
      {id: 'LA', desc: 'Louisiana'},
      {id: 'ME', desc: 'Maine'},
      {id: 'MD', desc: 'Maryland'},
      {id: 'MA', desc: 'Massachusetts'},
      {id: 'MI', desc: 'Michigan'},
      {id: 'MN', desc: 'Minnesota'},
      {id: 'MS', desc: 'Mississippi'},
      {id: 'MO', desc: 'Missouri'},
      {id: 'MT', desc: 'Montana'},
      {id: 'NE', desc: 'Nebraska'},
      {id: 'NV', desc: 'Nevada'},
      {id: 'NH', desc: 'New Hampshire'},
      {id: 'NJ', desc: 'New Jersey'},
      {id: 'NM', desc: 'New Mexico'},
      {id: 'NY', desc: 'New York'},
      {id: 'NC', desc: 'North Carolina'},
      {id: 'ND', desc: 'North Dakota'},
      {id: 'OH', desc: 'Ohio'},
      {id: 'OK', desc: 'Oklahoma'},
      {id: 'OR', desc: 'Oregon'},
      {id: 'PA', desc: 'Pennsylvania'},
      {id: 'RI', desc: 'Rhode Island'},
      {id: 'SC', desc: 'South Carolina'},
      {id: 'SD', desc: 'South Dakota'},
      {id: 'TN', desc: 'Tennessee'},
      {id: 'TX', desc: 'Texas'},
      {id: 'UT', desc: 'Utah'},
      {id: 'VT', desc: 'Vermont'},
      {id: 'VA', desc: 'Virginia'},
      {id: 'WA', desc: 'Washington'},
      {id: 'WV', desc: 'West Virginia'},
      {id: 'WI', desc: 'Wisconsin'},
      {id: 'WY', desc: 'Wyoming'}
    ]
    return states;
  }

  async setValidationsCache() {
    let valSub = this.apiService.get('lookup/validations')
    .subscribe({
      next: (validations: Validation[]) => { 
        if (validations && validations.length) {
          this.validationsCache = validations;
        }
        else {
          this.validationsCache = [];
          console.warn("No lookup Validations returned");
        }
      },
      error: (err: any) => { 
        console.error(err);
        this.validationsCache = [];
      },
      complete: () => { valSub.unsubscribe(); }
    });
  }

  async getAllValidations(forceRefresh: boolean = false): Promise<Validation[]> {
    if (forceRefresh || this.validationsCache.length == 0) {
      await this.setValidationsCache();
    }
    return this.validationsCache;    
  }

  async getValidationByName(valName: string, forceRefresh: boolean = false): Promise<Validation[]> {
    if (forceRefresh || this.validationsCache.length == 0) {
      await this.setValidationsCache();
    }
    return this.validationsCache.filter(vc => vc.validationName.indexOf(valName) > -1);
  }

  async getValidationById(valId: number, forceRefresh: boolean = false): Promise<Validation> {
    if (forceRefresh || this.validationsCache.length == 0) {
      await this.setValidationsCache();
    }
    return this.validationsCache.find(vc => vc.validationID == valId);
  }
}
