import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import {
  ApplicationStartComponent,
  BopAppActions
} from '../../components/app-form/application-start/application-start.component';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { getCurrentRoute } from '../../store/router/router.selector';
import { AppState } from '../../store/app.state';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.scss']
})
export class ApplicationPageComponent implements OnInit, OnDestroy {
  private subscriptions: { [key: string]: any } = {};
  customerGuid = "";
  branchGuid = "";

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private router: Router, public dialog: MatDialog,
    private store$: Store<AppState>,
    private main: MainService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.routerSelector = this.store$
      .pipe(select(getCurrentRoute))
      .subscribe((route: any) => {
        this.customerGuid = route.params.customerGuid;
        this.branchGuid = route.params.branchGuid;
      });
  }

  confirmFinanceAmount(action?: BopAppActions) {
    this.main.addClassToBody();
    const dialogRef = this.dialog.open(ApplicationStartComponent, {
      data: { action, patientGuid: "", branchGuid: this.branchGuid, customerGuid: this.customerGuid },
      width: '640px',
      maxHeight: '90vh',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      panelClass: 'app-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.main.removeClassFromBody();
      this.handleResult(result);
    });
  }

  handleResult(result: string | null = null) {
    if (result === 'updated' || result === 'completed') {
      this.router.navigate([`/home/${this.customerGuid}/admin/${this.branchGuid}/applications`]);
    }
  }

  ngOnDestroy(): void {
    try {
      this.main.removeClassFromBody();
      if (this.subscriptions && this.subscriptions.routerSelector)
        this.subscriptions.routerSelector.unsubscribe();
    } catch (error) {
      console.error(`Error application page.OnDestroy: ${JSON.stringify(error)}`);
    }
  }
}
