import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function adjustServiceDateValidator(existing?: any, current: any = null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (control.pristine || !value) {
      return null;
    }
    let isSame = false;
    if (current) {
      isSame = new Date(current).setHours(0, 0, 0, 0) == new Date(value).setHours(0, 0, 0, 0);
      if (isSame) {
        return null;
      }
    }

    isSame = new Date(value).setHours(0, 0, 0, 0) == new Date(existing).setHours(0, 0, 0, 0);
    if (isSame) {
      return null;
    }

    let isValid = new Date(value).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
    return !isValid ? {isFuture: true} : null;
  }
}
