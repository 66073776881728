import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-patterns',
  templateUrl: './patterns.component.html',
  styleUrls: ['./patterns.component.scss']
})
export class PatternsComponent implements OnInit {
  selected = new UntypedFormControl(0);
  fg: UntypedFormGroup = new UntypedFormGroup({});

  constructor(private router: Router, private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    if (environment.production) {
      this.router.navigate(['/']);
    }

    this.fg = this.fb.group({
      test1: ['test1', Validators.required],
      test2: ['', Validators.required],
      test3: ['test3', Validators.required],
    }, { updateOn: 'blur'});
    this.initForm();
    this.selected.setValue(3);
  }

  initForm() {
    this.form['test2'].markAsTouched();
    this.form['test3'].disable();
  }

  get form() {
    return this.fg.controls;
  }
}
