<div class="page-view">
  <div class="breadcrumbs" *ngIf="branch?.branchId && !singleLocationOnly">
    <a tabindex="0" (click)="onCancel()" (keyup.enter)="onCancel()" href="javascript:void(0)">
      Select Location</a> <span>&gt;</span> <span>{{ branch?.branchName + ' - ' + branch?.branchId}}</span>
  </div>
  <div class="page-view__loading card" *ngIf="loading">
    <div class="card-body">
      <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
    </div>
  </div>
  <div *ngIf="!loading" class="page-view__body application">
    <app-error-banner *ngIf="error" [message]="error"></app-error-banner>
    <div class="page-view__header">
      <h3>All applications</h3>
    </div>
    <div class="table-container">
      <div class="flex flex-row-reverse pt-4 pb-2">
        <span class="p-input-icon-left">
          <i class="pi pi-search" aria-hidden="true"></i>
          <input #txtSearch [(ngModel)]="searchFilter" type="text" class="w-15rem" pInputText placeholder="Search"
            (input)="dtPortfolios.filterGlobal(txtSearch.value, 'contains')" />
        </span>
      </div>
      <p-table #dtPortfolios [value]="applications" styleClass="p-datatable-lg" [globalFilterFields]="displayedColumns"
        [paginator]="true" [rowsPerPageOptions]="[15, 30, 50, 100]" [rows]="rows" [(first)]="first"
        [showCurrentPageReport]="false" responsiveLayout="scroll" [rowHover]="true">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="lastName" class="font-medium" scope="col">Last Name <p-sortIcon field="lastName"></p-sortIcon>
            </th>
            <th pSortableColumn="firstName" class="font-medium" scope="col">First Name <p-sortIcon field="firstName"></p-sortIcon>
            </th>
            <th pSortableColumn="financedBy" class="font-medium" scope="col">Financing By <p-sortIcon field="financedBy"></p-sortIcon>
            </th>
            <th pSortableColumn="patientID" class="font-medium" scope="col">Patient Id <p-sortIcon field="patientID"></p-sortIcon>
            </th>
            <th pSortableColumn="startDate" class="font-medium" scope="col">Service Date <p-sortIcon field="startDate"></p-sortIcon>
            </th>
            <th pSortableColumn="loanStatusDesc" class="font-medium" scope="col">Status <p-sortIcon field="loanStatusDesc"></p-sortIcon>
            </th>
            <th scope="col"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-app>
          <tr>
            <td>{{app.lastName}}</td>
            <td>{{app.firstName}}</td>
            <td>{{app.financedBy}}</td>
            <td>{{app.patientID}}</td>
            <td>{{app.startDate | date: 'MM/dd/yyyy'}}</td>
            <td>
              <app-chip [statusId]="app.loanStatusId" [status]="app.loanStatusDesc"></app-chip>
            </td>
            <td class="application__actions">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Application menu options">
                <mat-icon svgIcon="kabob_horiz"></mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [disabled]="disableMenuLink(app.enablePatientInformation, app.appMenuPatientInfo)"
                  (click)="doMenu(app, 'info')">
                  <span>Patient Information</span>
                </button>
                <button mat-menu-item [disabled]="disableMenuLink(app.enableResendLink, app.appMenuResendLink)"
                  (click)="doMenu(app, 'resend')">
                  <span>Resend link</span>
                </button>
                <button mat-menu-item [disabled]="disableMenuLink(app.enableMakeAdjustment, app.appMenuMakeAdjustment)"
                  (click)="doMenu(app, 'adjustment')">
                  <span>Make Adjustment</span>
                </button>
                <button mat-menu-item [disabled]="disableMenuLink(app.enableCancelApplication, app.appMenuCancelApp)"
                  (click)="doMenu(app, 'cancel')">
                  <span>Cancel Loan</span>
                </button>
                <button mat-menu-item *ngIf="app.loanStatusId && app.loanStatusId === '8'"
                  [disabled]="disableMenuLink(app.appMenuCreditContract, 'TRUE')"
                  (click)="openContract(app.creditContractUrl)">
                  <span>Credit Contract</span>
                </button>
                <button mat-menu-item *ngIf="(app.enableAssignLocation === '1' || app.loanStatusId === '193') && canTransferApp"
                  (click)="doMenu(app, 'transfer')">
                  <span>Transfer Application</span>
                </button>
              </mat-menu>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">No applications to display.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
