import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BranchLocationModel} from 'src/app/models/branch-location-model';
import {CustomerProductModel} from 'src/app/models/customer-product-model';
import {LookupModel} from 'src/app/models/lookup-model';
import {PatientAppDetails} from 'src/app/models/patient-app-details';
import {ApiService} from 'src/app/services/api.service';
import {CustomerService} from 'src/app/services/customer.service';
import {GuidService} from 'src/app/services/guid.service';
import {LookupService} from 'src/app/services/lookup.service';
import {MainService} from 'src/app/services/main.service';
import {
  capitalizeFirstLetter,
  emailRegex,
  maskDecimal,
  nameRegex,
  phoneRegex,
  ssnRegex,
  validateMinMax
} from 'src/app/services/util.service';
import {TransferLocationComponent, transferLocationData} from '../../transfer-location/transfer-location.component';
import {BopAppActions} from '../application-start/application-start.component';
import {ActivatedRoute, Router} from '@angular/router';
import {BranchModel} from '../../../models/branch-model';
import {forkJoin, of} from 'rxjs';
import {CustomerModel} from '../../../models/customer-model';
import {UserRoleService} from 'src/app/services/user-role.service';

@Component({
  selector: 'app-application-entry',
  templateUrl: './application-entry.component.html',
  styleUrls: ['./application-entry.component.scss']
})
export class ApplicationEntryComponent implements OnInit, OnDestroy {
  @ViewChild('submitCta') submitBtn: ElementRef<HTMLButtonElement>;
  @Output() onAction = new EventEmitter<BopAppActions>();
  @Input() customerGuid = "";
  @Input() branchGuid = "";
  @Input() readonly = false;
  branch: BranchModel = {} as BranchModel;
  error: string = '';

  @Input()
  get appDetails(): PatientAppDetails | null | undefined {
    return this._appDetails;
  }

  set appDetails(appDetails: PatientAppDetails | null | undefined) {
    this._appDetails = appDetails;
  }

  private _appDetails: PatientAppDetails | null | undefined = null;
  showProductSelect = false;
  storedValuesKeys = {
    app: "storedValuesKeyCC_app",
    patientInfo: "storedValuesKeyCC_patientInfo",
    patientInfo2: "storedValuesKeyCC_patientInfo2"
  };
  patientRelationships: LookupModel[] = [];
  assignableUsers: LookupModel[] = [];
  products: CustomerProductModel[] = [];
  productSelect: LookupModel[] = [];
  states: LookupModel[] = [];
  // customerGuid: string | null = "";
  // branchGuid: string | null = "";
  patientTypes: LookupModel[] = [{id: 'patient', desc: 'Patient'}, {id: "responsibleParty", desc: 'Responsible Party'}];
  languages: LookupModel[] = [{id: 'EN', desc: 'English'}, {id: 'ES', desc: 'Spanish'}];
  appTypes: LookupModel[];
  originalAppTypes: LookupModel[] = [{id: 'Link', desc: 'Send application link (at least one must be selected)'}, 
    {id: 'Device',desc: 'Continue application on this device'}];
  appOneForm: UntypedFormGroup = new UntypedFormGroup({});
  patientInfoForm: UntypedFormGroup = new UntypedFormGroup({});
  patientInfoForm2: UntypedFormGroup = new UntypedFormGroup({});
  minLoanAmt = '0';
  maxLoanAmt = '1000000';
  amountErrorMessage = 'This field is required and Amount must be between $500.00 and $5,000.00';
  loading = true;
  isAllyEnabled = false;
  singleLocationOnly = false;

  constructor(private apiService: ApiService,
              private customerService: CustomerService,
              private lookupService: LookupService,
              private main: MainService,
              private roleService: UserRoleService,
              private fb: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute) {
    this.appTypes = this.originalAppTypes;
    this.setAmountErrorMessage();
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin([
      this.getBopLookups(),
      this.customerService.getBranchWithParams(this.customerGuid, this.branchGuid),
      this.customerService.setCustomerSettingWithParams(this.customerGuid, this.branchGuid)
    ]).subscribe((results) => {
      this.initApp();
      const branch = this.customerService.getBranch();
      if (branch) {
        this.branch = branch;
        this.branchGuid = branch.branchGuid;
      }
    });

    this.customerService.customer$.subscribe((cust: CustomerModel | null) => {
      if (cust && cust.customerGUID) {
        this.isAllyEnabled = cust.isAllyEnabled.toLowerCase() === 'true';
        if (this.isAllyEnabled) {
          let isCA = this.branch.state.trim() == 'California' || this.branch.state.trim() == 'CA';
          this.checkAllyCAOptions(isCA);
          this.appOneForm.controls.appType.markAsTouched();
          this.appOneForm.controls.appType.updateValueAndValidity();
        }
      }
    });
    this.route.queryParams
      .subscribe(params => {
          this.singleLocationOnly = params.single === 'true';
        }
      );
  }
  checkAllyCAOptions(isCalifornia: boolean = false) {
    this.appTypes = this.originalAppTypes;
    const appType = this.appTypes[0];
    appType.desc = 'Email application link';
    if (isCalifornia) {
      this.appTypes = [appType];
    }
  }

  initAppForms() {
    this.main.setPageWidth(false);
    this.states = this.lookupService.getStates();
    const customerSettings = this.customerService.getCustomerSetting() || {
      minLoanAmt: this.minLoanAmt,
      maxLoanAmt: this.maxLoanAmt
    };
    this.minLoanAmt = customerSettings.minLoanAmt;
    this.maxLoanAmt = customerSettings.maxLoanAmt;
    this.setAmountErrorMessage();

    const branch = this.customerService.getBranch();
    if (branch) {
      this.branch = branch;
      this.branchGuid = branch.branchGuid;
    }
    this.appOneForm = this.fb.group({
      applicantType: new UntypedFormControl(this.getPatientType(this.appDetails?.patientRelationship || 'patient'), {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      patientRelationship: [this.appDetails?.patientRelationship],
      serviceDate: [this.appDetails?.serviceDate, Validators.required],
      doctor: [this.appDetails?.doctorName],
      treatments: [this.appDetails?.serviceProcedure || '', Validators.required],
      financeAmount: [this.appDetails?.loanAmt, [Validators.required, validateMinMax(Number(customerSettings.minLoanAmt), Number(customerSettings.maxLoanAmt), false)]],
      assignPatientId: [this.appDetails?.assignTo, Validators.required],
      branch: new UntypedFormControl(branch ? `${branch.branchId} - ${branch.branchName}` : '', {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      language: [this.appDetails?.patLanguage || 'EN'],
      appType: new UntypedFormControl(capitalizeFirstLetter(this.appDetails?.patAppLinkMethod) || 'Link', {updateOn: 'change'}),
      notifyEmail: [true],
      notifySms: [!this.isAllyEnabled]
    }, {updateOn: 'blur'});
    this.patientInfoForm = this.fb.group({
      firstName: [this.appDetails?.firstName, [Validators.required, Validators.pattern(nameRegex)]],
      lastName: [this.appDetails?.lastName, [Validators.required, Validators.pattern(nameRegex)]],
      phone: [this.appDetails?.phoneNumber, [Validators.required, Validators.pattern(phoneRegex)]],
      email: [this.appDetails?.emailAddress, [Validators.required, Validators.pattern(emailRegex)]],
      dob: [this.appDetails?.dOB],
      ssn: [this.appDetails?.sSN, [Validators.pattern(ssnRegex)]],
      address1: new UntypedFormControl(this.appDetails?.address, {updateOn: 'change'}),
      address2: [this.appDetails?.address2],
      city: [this.appDetails?.city],
      state: [this.appDetails?.state || ''],
      zip: [this.appDetails?.zipCode]
    }, {updateOn: 'blur'});
    this.patientInfoForm2 = this.fb.group({
      firstName: [this.appDetails?.patFirstName, [Validators.required, Validators.pattern(nameRegex)]],
      lastName: [this.appDetails?.patLastName, [Validators.required, Validators.pattern(nameRegex)]],
      phone: [this.appDetails?.patCellPhone, [Validators.pattern(phoneRegex)]],
      email: [this.appDetails?.patEmail, [Validators.pattern(emailRegex)]],
      dob: [this.appDetails?.patDOB],
      ssn: [this.appDetails?.sSN, Validators.pattern(ssnRegex)],
      address1: new UntypedFormControl(this.appDetails?.patAddress1, {updateOn: 'change'}),
      address2: [this.appDetails?.patAddress2],
      city: [this.appDetails?.city],
      state: [this.appDetails?.state || ''],
      zip: [this.appDetails?.zipCode]
    }, {updateOn: 'blur'});

    this.appOneForm.controls['treatments'].valueChanges.subscribe(value => {
      if (this.products.length && value) {
        var val = value.trim().toUpperCase();
        this.products.forEach(item => {
          if (item.providerProductDesc.toUpperCase() == val) {
            if (item.providerProductMSRP) {
              // var amount = item.providerProductMSRP.indexOf('.') !== -1 ? item.providerProductMSRP : `${item.providerProductMSRP}.00`;
              var amount = item.providerProductMSRP;
              this.appOneForm.patchValue({
                financeAmount: amount
              });
              // this.appOneForm.controls.financeAmount.markAsTouched();
              // this.appOneForm.controls.financeAmount.updateValueAndValidity();
            }
          }
        })
      }
    });

  }

  initApp() {
    if (!this.readonly) {
      const app = localStorage.getItem(this.storedValuesKeys.app);
      if (app) {
        this.appOneForm.patchValue(JSON.parse(app));
      }

      const patientForm = localStorage.getItem(this.storedValuesKeys.patientInfo);
      if (patientForm) {
        this.patientInfoForm.patchValue(JSON.parse(patientForm));
      }

      const patientForm2 = localStorage.getItem(this.storedValuesKeys.patientInfo2);
      if (patientForm2) {
        this.patientInfoForm2.patchValue(JSON.parse(patientForm2));
      }
    }
  }

  getBopLookups() {
    this.apiService.get(`app/bop-lookups/${this.customerGuid}`)
      .subscribe(
        res => {
          this.patientRelationships = res.patientRelationships || [];
          this.assignableUsers = res.assignableUsers || [];
          this.products = res.products;
          this.formatProducts();
          this.initAppForms();
          this.loading = false;
        },
        err => {
          this.main.showSnackBar("Error retrieving Bop lookups");
          this.loading = false;
        }
      );

    return of([]);
  }

  private formatProducts() {
    if (this.products.length) {
      if (this.products.length === 1) {
        if (!this.readonly) {
          this.appOneForm.patchValue({
            treatments: this.products[0].providerProductDesc,
            financeAmount: this.products[0].providerProductMSRP
          });
        }
      } else {
        this.showProductSelect = true;
        this.amountErrorMessage = "Amount is required";
        this.productSelect = [];
        this.productSelect.push(new LookupModel("", "Treatment*"));
        this.products.forEach(item => {
          this.productSelect.push(new LookupModel(item.providerProductDesc, item.providerProductDesc));
        })
      }
    }
  }

  onSubmit() {
    this.appOneForm.updateValueAndValidity();
    if (this.isValidForm()) {
      this.error = '';
      var formData = this.getFormData();
      var action = {
        formObject: formData,
        step: 2,
        amount: this.appOneForm.value.financeAmount
      };
      localStorage.setItem(this.storedValuesKeys.app, JSON.stringify(this.appOneForm.value));
      localStorage.setItem(this.storedValuesKeys.patientInfo, JSON.stringify(this.patientInfoForm.value));
      localStorage.setItem(this.storedValuesKeys.patientInfo2, JSON.stringify(this.patientInfoForm2.value));
      this.onAction.emit(action);
    } else {
      this.error = 'There\'s something wrong with your application';
      this.appOneForm.markAsDirty();
      this.appOneForm.markAllAsTouched();
      this.patientInfoForm.markAllAsTouched();
      if (this.appOneForm.controls['applicantType'].value === 'responsibleParty') {
        this.patientInfoForm2.markAllAsTouched();
      }
    }
  }

  onCancel() {
    localStorage.setItem(this.storedValuesKeys.app, '');
    localStorage.setItem(this.storedValuesKeys.patientInfo, '');
    localStorage.setItem(this.storedValuesKeys.patientInfo2, '');
    this.gotToBranches();
  }

  isValidForm() {
    if (this.appOneForm.valid && this.patientInfoForm.valid) {
      const controls = this.appOneForm.controls;
      if (controls.appType.value === 'Link' && !controls.notifyEmail.value && !controls.notifySms.value) {
        return false;
      }

      return this.appOneForm.value.applicantType === 'patient'
        ? true
        : this.patientInfoForm2.valid;
    }

    return false;
  }

  assignLocation() {
    let data: transferLocationData = {
      customerGuid: this.customerGuid,
      selected: this.branchGuid,
      dialogType: 'assign'
    };
    this.main.showModal(TransferLocationComponent, '550px', data)
      .afterClosed().subscribe((loc: BranchLocationModel) => {
      if (loc && loc.branchGUID) {
        this.appOneForm.patchValue({
          branch: `${loc.branch_ID} - ${loc.branchName}`
        });
        this.branchGuid = loc.branchGUID;
        if (this.isAllyEnabled) {
          let isCA = loc.branchState.trim() == 'California' || loc.branchState.trim() == 'CA';
          this.checkAllyCAOptions(isCA);
        }
      }
    });
  }

  getFormData() {
    var d = new Date();
    var amt = this.appOneForm.value.financeAmount.toString().replace(',', '');
    var formData = {
      "LeadInformation": {
        "SendAppLink": this.appOneForm.value.appType,
        "SendEmail": this.appOneForm.value.notifyEmail,
        "SendSMS": this.appOneForm.value.notifySms,
        "Language": this.appOneForm.value.language,
        "PartnerGUID": this.customerGuid,
        "BranchLocationGUID": this.branchGuid,
        "TrackingGUID": GuidService.newGuid(),
        "LeadPatientID": this.appOneForm.value.assignPatientId,
        "RequestDate": d.toLocaleDateString(),
        "AssignTo": this.assignableUsers[0].desc,
        "LeadContactInfo": {
          "LeadGuid": GuidService.newGuid(),
          "LeadFirstName": this.patientInfoForm.value.firstName,
          "LeadLastName": this.patientInfoForm.value.lastName,
          "LeadDateOfBirth": this.patientInfoForm.value.dob,
          "LeadAddress": this.patientInfoForm.value.address1,
          "LeadAddress2": this.patientInfoForm.value.address2,
          "LeadCity": this.patientInfoForm.value.city,
          "LeadState": this.patientInfoForm.value.state,
          "LeadZip": this.patientInfoForm.value.zip,
          "LeadEmailAddress": this.patientInfoForm.value.email,
          "LeadPhone": this.patientInfoForm.value.phone,
          "LeadSSN": this.patientInfoForm.value.ssn && this.patientInfoForm.value.ssn.replace('-', '').replace('-', ''),
        },
        "PatientInformation": this.getPatientInfo(),
        "LoanRequest": {
          "RequestedAmount": amt,
          "LoanType": "I",
          "Procedures": {
            "ProcedureService": [{
              "ProcedureId": "1",
              "ProcedureDate": this.appOneForm.value.serviceDate,
              "ProcedureAmount": amt,
              "ProcedureName": this.appOneForm.value.treatments,
              "Doctor": this.appOneForm.value.doctor,
            }]
          }
        }
      }
    };

    return formData;
  }

  getPatientInfo() {
    if (this.appOneForm.value.applicantType === 'patient') {
      return {
        "PatientGuid": GuidService.newGuid(),
        "PatientRelationship": "SELF",
        "IsPatientSameAsLead": "1",
        "PatientFirstName": this.patientInfoForm.value.firstName,
        "PatientLastName": this.patientInfoForm.value.lastName,
      }
    } else {
      return {
        "PatientGuid": GuidService.newGuid(),
        "PatientRelationship": this.appOneForm.value.patientRelationship,
        "IsPatientSameAsLead": "0",
        "PatientFirstName": this.patientInfoForm2.value.firstName,
        "PatientLastName": this.patientInfoForm2.value.lastName,
        "PatientAddress": this.patientInfoForm2.value.address1,
        "PatientAddress2": this.patientInfoForm2.value.address2,
        "PatientCity": this.patientInfoForm2.value.city,
        "PatientState": this.patientInfoForm2.value.state,
        "PatientZip": this.patientInfoForm2.value.zip,
        "PatientEmailAddress": this.patientInfoForm2.value.email,
        "PatientPhone": this.patientInfoForm2.value.phone,
        "PatientSSN": this.patientInfoForm2.value.ssn,
        "PatientDOB": this.patientInfoForm2.value.dob,
      }
    }
  }

  private setAmountErrorMessage() {
    var err = `Amount must be between $${maskDecimal(this.minLoanAmt.toString())} and $${maskDecimal(this.maxLoanAmt.toString())}`;
    this.amountErrorMessage = err;
  }

  private getPatientType(patientType: string | null | undefined) {
    var type = patientType ? patientType.toLocaleLowerCase() : 'patient';
    if (type === 'self' || type === 'patient' || type === '1')
      return 'patient';

    if (type === '2' || type === 'responsibleparty' || type === 'party')
      return 'responsibleParty';

    return patientType;
  }

  gotToBranches() {
    this.router.navigate([`/home/` + this.customerGuid + '/branches']);
  }

  setPatientTypeValidations(selected: any) {
    this.appOneForm.controls.patientRelationship.clearValidators();
    this.appOneForm.controls.patientRelationship.setErrors(null);
    if (selected === 'responsibleParty') {
      this.appOneForm.controls.patientRelationship.setValidators([Validators.required]);
    }
  }

  appTypeChange(appType?: string) {
    this.appOneForm.controls.notifyEmail.setValue(appType === 'Link');
    this.appOneForm.controls.notifySms.setValue(appType === 'Link' && !this.isAllyEnabled);
  }

  ngOnDestroy(): void {
  }
}
