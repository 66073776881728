import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faBriefcase, faLink } from '@fortawesome/free-solid-svg-icons';
import { PortfolioItem } from 'src/app/models/portfolio-item';
import { AuthService } from 'src/app/services/auth.service';
import { CustomerService } from 'src/app/services/customer.service';
import { environment } from 'src/environments/environment';
import { AccessService } from 'src/app/services/access.service';
import { ICrossroadLink } from 'src/app/models/customer-model';
import { combineLatest } from 'rxjs';
import { SettingService } from 'src/app/services/setting.service';

@Component({
  selector: 'app-select-provider-page',
  templateUrl: './select-provider-page.component.html',
  styleUrls: ['./select-provider-page.component.scss']
})
export class SelectProviderPageComponent implements OnInit {
  portfolios: PortfolioItem[] = [];
  iconBriefcase = faBriefcase;
  iconLink = faLink;
  loadingPortfolios = true;
  loadingAccess: boolean = true;
  error: string = '';
  logoUrl = '';

  rows: number = 15;
  first: number = 0;
  searchFilter: string = "";
  displayedColumns: string[] = ['name'];
  links: ICrossroadLink[] = [];
  missingAccess: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router, 
    private customerService: CustomerService,
    private auth: AuthService,
    private settings: SettingService,
    private accessService: AccessService) { 
      this.setLogo();
    }

  ngOnInit(): void {
    
    this.route.queryParams
      .subscribe(params => {
        if (params && params.action && params.action === 'logout'){
          this.auth.logout();
        }else{
          this.customerService.setPortfolios();

          const dataSub = combineLatest([this.customerService.portfolios$, this.accessService.access$, this.accessService.links$])
          .subscribe({
            next: ([portResults, access, links]) => { 
              this.links = links;
              if (portResults){     
                this.portfolios = portResults.portfolios;
                this.loadingPortfolios = portResults.loading;
                this.error = portResults.error;
                if (this.portfolios && this.portfolios.length === 1) {
                  if (access.bop) { 
                    this.missingAccess = false;
                    this.customerService.setCustomerGuid(this.portfolios[0].customerGuid);
                    this.router.navigate(['/home/' + this.portfolios[0].customerGuid]);
                  } else if (access.admin) {
                    this.missingAccess = false;
                    window.location.href = ``;
                  } else if (access.cs) {
                    this.missingAccess = false;
                    window.location.href = ``;
                  } else {
                    this.missingAccess = true;
                    setTimeout(() => {
                      if (this.missingAccess) {
                        console.error(`Missing module access for user. Logging user out.`);
                        this.auth.logout();                      
                      }
                    }, 500);
                  }
                }
              }
            },
            error: (err: any) => { console.error(`select-provider OnInit error: ${JSON.stringify(err)}`); },
            complete: () => { dataSub.unsubscribe(); }
          });
        }
      }
    );
  }
  
  setLogo(){
    let imageUrl = '/assets/images/logo/';
    let hostname = location.hostname;
    if (hostname.indexOf('ally') !== -1){
      imageUrl += 'ally-logo.png';
    }
    else if (hostname.indexOf('powerpay') !== -1) {
      imageUrl += 'powerpay-logo.png'
    } 
    else {
      imageUrl += 'logo.png';
    }
    this.logoUrl = imageUrl;
  }

  gotoPortfolio(item: PortfolioItem) {
    this.router.navigate(['/home/' + item.customerGuid]);
  }

  goToLink(link: ICrossroadLink, portfolio: PortfolioItem) {
    if (link.isExternal && link.isExternal === 1) {
      window.open(link.url, link.target || "_self");
      return;
    }
    
    var url = link.url?.replace('00000000-0000-0000-0000-000000000000', portfolio.customerGuid);

    this.customerService.setCustomerGuid(portfolio.customerGuid);
    if (link.app && link.app === "bop") {
      this.router.navigate([url]);
    }
    else {
      if (url?.startsWith('/')) url = url.substring(1);

      if (link.app && link.app === "admin") {
        window.open(`${this.settings.getValue('URL_Admin')}${url}`, link.target || "_self");
      } else if (link.app && link.app === "cs") {
        window.open(`${this.settings.getValue('URL_CS')}${url}`, link.target || "_self");
      } else {
        console.error("Unable to determine target link and navigate!");
      }
    }    
  }
}

