import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ccare-header',
  templateUrl: './ccare-header.component.html',
  styleUrls: ['./ccare-header.component.scss']
})
export class CcareHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
