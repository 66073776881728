import {Component, OnDestroy, OnInit} from '@angular/core';
import {getCrossroads} from '../../store/provider/provider.selector';
import {Observable} from 'rxjs';
import {ICrossroadLink} from '../../models/customer-model';
import {CustomerService} from '../../services/customer.service';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {getCurrentRoute} from '../../store/router/router.selector';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
  crossroads$: Observable<ICrossroadLink[]> | undefined;
  customerGuid: string | null = '';
  branchGuid: string | null = '';
  private subscriptions: { [key: string]: any } = {};

  constructor(private customerService: CustomerService, private store$: Store<AppState>, private router: Router) {
  }

  ngOnInit(): void {

    this.subscriptions.routerSelector = this.store$
      .pipe(select(getCurrentRoute))
      .subscribe((route: any) => {
        this.customerGuid = route.params.customerGuid;
        this.crossroads$ = this.customerService.getCrossRoads(this.customerGuid);
      });
  }

  navTo(item: ICrossroadLink) {
    if (!!item.isExternal) {
      window.open(item.url, item.target);
    } else {
      this.router.navigate([item.url]);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.routerSelector)
      this.subscriptions.routerSelector.unsubscribe();
  }

}
