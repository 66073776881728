import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReportItemModel} from 'src/app/models/report-item-model';
import {MainService} from 'src/app/services/main.service';
import {EmbedReport} from 'src/app/services/report.service';
import {select, Store} from '@ngrx/store';
import {getCurrentRoute} from '../../store/router/router.selector';
import {AppState} from '../../store/app.state';


@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent implements OnInit, OnDestroy {
  loading = false;
  customerGuid: string | undefined = '';
  report: EmbedReport | undefined = undefined;
  showSelected = false;
  selected: ReportItemModel | undefined;
  private subscriptions: { [key: string]: any } = {};

  constructor(
              private main: MainService,
              private store$: Store<AppState>) {
  }

  ngOnInit(): void {
    this.subscriptions.routerSelector = this.store$
      .pipe(select(getCurrentRoute))
      .subscribe((route: any) => {
        this.customerGuid = route.params.customerGuid;
      });
  }

  onSelect(report: ReportItemModel) {
    this.showSelected = true;
    this.selected = report;
  }

  showReports() {
    this.showSelected = false;
    this.selected = {} as ReportItemModel;
  }

  ngOnDestroy(): void {    
    try {
      this.main.setPageWidth(true);   
      if (this.subscriptions && this.subscriptions.routerSelector)
        this.subscriptions.routerSelector.unsubscribe();  
    } catch (error) {      
      console.error(`Error reports page.OnDestroy: ${JSON.stringify(error)}`);
    }
  }
}
