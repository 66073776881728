import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AppAccess} from '../models/app-access';
import {ICrossroadLink} from '../models/customer-model';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  private _access = new BehaviorSubject<AppAccess>(new AppAccess);
  private _links = new BehaviorSubject<ICrossroadLink[]>([]);

  access$ = this._access.asObservable();
  links$ = this._links.asObservable();

  constructor(
    private apiService: ApiService
  ) {
  }

  getAccess() {
    const url = 'app/bop-customer-settings';
    const payload = {
      customerGuid: '00000000-0000-0000-0000-000000000000',
      domainName: window.location.hostname
    };
    this.apiService.post(url, payload)
      .subscribe({
        next: (data: any) => {
          if (!data) data = {};
          let access: AppAccess = new AppAccess;
          access.fullName = data.FullName || "";
          access.admin = data.HasAdminAccess && data.HasAdminAccess === "1" || false;
          access.bop = data.HasBOPAcess && data.HasBOPAcess === "1" || false;
          access.cs = data.HasCSAccess && data.HasCSAccess === "1" || false;
          access.allProviders = data.HasAllProviders && data.HasAllProviders === "1" || false;

          let links = data && data.links || [];
          links = links.sort(function (a: any, b: any) {
            return a.order - b.order
          });
          const crossroadLinks: ICrossroadLink[] = [];
          for (let key in links) {
            crossroadLinks.push({...links[key], id: key, itemId: links[key]['id']});
          }
          this._access.next(access);
          this._links.next(crossroadLinks);
        },
        error: (err: any) => {
          console.error(err);
        }
      });
  }

  public setAccess(access: AppAccess) {
    this._access.next(access);
  }

  public setLinks(links: ICrossroadLink[]) {
    this._links.next(links);
  }
}
