<div class="app-search card">
    <div class="p-3 mb-2 bg-info text-dark">
        <p class="h4 mb-1">Search</p>
    </div>
    <div class="card-body">
        <form [formGroup]="searchForm" (ngSubmit)="search()">
            <app-cc-input [group]="searchForm" controlName="firstName" label="First Name">
            </app-cc-input>
            <app-cc-input [group]="searchForm" controlName="lastName" label="Last Name">
            </app-cc-input>
            <app-cc-input [group]="searchForm" controlName="patientId" label="Patient Id">
            </app-cc-input>
            <app-cc-input [group]="searchForm" controlName="phone" label="Phone Number">
            </app-cc-input>
            <app-cc-input [group]="searchForm" controlName="email" label="Email">
            </app-cc-input>
            <div class="d-grid gap-2">
                <button class="btn btn-primary btn-main btn-large" type="submit">Search</button>
              </div>
        </form>
    </div>

</div>