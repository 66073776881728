import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IReportEmbedConfiguration, models, service} from 'powerbi-client';
import {hidden, position} from 'src/app/configs/report-config';
import {ReportItemModel} from 'src/app/models/report-item-model';
import {ApiService} from 'src/app/services/api.service';
import {CustomerService} from 'src/app/services/customer.service';
import {EmbeddedReportConfig} from 'src/app/services/report.service';

@Component({
  selector: 'app-selected-report',
  templateUrl: './selected-report.component.html',
  styleUrls: ['./selected-report.component.scss']
})
export class SelectedReportComponent implements OnInit {
  @Output() unselectReport = new EventEmitter<boolean>();

  @Input()
  get report(): ReportItemModel | undefined {
    return this._report;
  }

  set report(report: ReportItemModel | undefined) {
    this._report = report;
    this.getReport();
  }

  error: string = '';

  private _report: ReportItemModel | undefined = undefined;
  embedded: EmbeddedReportConfig | undefined;
  loading = true;
  isEmbedded = false;
  phasedEmbeddingFlag = false;
  reportClass = 'report-container hidden';
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    tokenType: models.TokenType.Embed,
    // settings: {
    //   panes: {
    //     filters: {
    //       expanded: false,
    //       visible: false
    //     }
    //   },
    //   background: models.BackgroundType.Transparent,
    // },
    // permissions: models.Permissions.All,
    id: "",
    embedUrl: undefined,
    accessToken: undefined,
    hostname: "https://app.powerbi.com"
  };
  eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
    ['loaded', () => console.log('Report has loaded')],
    [
      'rendered',
      () => {
        console.log('Report has rendered');
        this.isEmbedded = true;
      },
    ],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => console.log(event)],
  ]);

  constructor(private customerService: CustomerService,
              private element: ElementRef<HTMLDivElement>,
              private api: ApiService) {
  }

  ngOnInit(): void {
  }

  getReport(): boolean {
    this.error = '';
    if (!this.report || !this.report.reportID) {
      return false;
    }

    this.loading = true;
    var model = {
      WorkspaceId: this.report.workspaceID,
      ReportId: this.report.reportID,
      CustomerGuid: this.customerService.getCustomerGuid(),
      CustomerName: this.customerService.getCustomerName()
    };
    var body = JSON.stringify(model);
    this.api.post(`reports/embed-details`, body)
      .subscribe(async (res: any) => {
          this.embedded = res;
          await this.embedReport();
          this.loading = false;
        },
        (err: any) => {
          this.loading = false;
          this.error = err;
        }
      );

    return true;
  }

  async embedReport(): Promise<void> {
    if (!this.embedded?.embedToken)
      return;

    var r = this.embedded.embedReport;
    this.reportConfig = {
      ...this.reportConfig,
      id: r.reportId,
      embedUrl: r.embedUrl,
      accessToken: this.embedded?.embedToken,
    };

    const reportDiv = this.element.nativeElement.querySelector('.report-container');
    if (reportDiv) {
      reportDiv.classList.remove(hidden);
    }

    const displayMessage = this.element.nativeElement.querySelector('.display-message');
    if (displayMessage) {
      displayMessage.classList.remove(position);
    }
  }

  setDataSelectedEvent(): void {
    // Adding dataSelected event in eventHandlersMap
    this.eventHandlersMap = new Map<string, (event?: service.ICustomEvent<any>) => void>([
      ...this.eventHandlersMap,
      ['dataSelected', (event) => console.log(event)],
    ]);
  }

  onCancel() {
    this.unselectReport.emit();
  }

}
