import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {INPUT_FORMAT} from '../../../services/form.service';

@Component({
  selector: 'app-patient-info-form',
  templateUrl: './patient-info-form.component.html',
  styleUrls: ['./patient-info-form.component.scss']
})
export class PatientInfoFormComponent implements OnInit {
  @Input() patientInfoForm: UntypedFormGroup = new UntypedFormGroup({});
  @Input() enforce18Over: boolean = true;
  dobMinDate = new Date();
  dobMaxDate = new Date();
  INPUT_FORMAT = INPUT_FORMAT;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    const today = new Date();
    this.dobMinDate = new Date(today.getFullYear() - 105, today.getMonth(), today.getDate());
    if (this.enforce18Over) {
      this.dobMaxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    }
    else {
      this.dobMaxDate = today;    
    }    
  }


}
