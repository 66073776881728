import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CustomerService} from '../../services/customer.service';
import {DynamicComponentDirective} from '../../directives/dynamic-component.directive';
import {DynamicComponent} from '../shared/dynamic/dynamic.component';
import {DynamicComponentService} from '../../services/dynamic-component.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {
  @Input() name = '';
  @ViewChild(DynamicComponentDirective)
  private dynComponentHost: DynamicComponentDirective = {} as DynamicComponentDirective;

  @ViewChild(DynamicComponent)
  private myPostComponent = {} as DynamicComponent;

  constructor(
    private customerService: CustomerService, 
    private dynamicComponentService: DynamicComponentService,
    private themeService: ThemeService) {
  }

  ngOnInit(): void {
    this.customerService.customer$.subscribe(cust => {
      if (cust && cust.customerGUID) {
        const componentName = this.themeService.getFooterComponentName();
        this.dynamicComponentService.loadComponent(
          this.dynComponentHost.viewContainerRef,
          {componentName, data: {}}
        );
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentName = this.name + 'Footer';
    this.dynamicComponentService.loadComponent(
      this.dynComponentHost.viewContainerRef,
      {componentName, data: {}}
    );
  }
}
