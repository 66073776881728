<div class="page-view breadcrumbs" *ngIf="branch?.branchId && !singleLocationOnly">
  <a tabindex="0" (click)="onCancel()" (keyup.enter)="onCancel()" href="javascript:void(0)">
    Select location</a> <span>&gt;</span> <span>{{ branch.branchName + '  - ' + branch.branchId}}</span>
</div>
<div class="page-view form">
  <div class="page-view__header application">
    <h2>Begin application for financing</h2>
    <p class="page-view__description text-center">
      Fill out the information below. Some fields are required before the application can begin.
      <br/>
      Required fields are marked with a <sup>*</sup>
    </p>
  </div>
  <app-error-banner *ngIf="error"
                    [message]="error"></app-error-banner>
  <div class="page-view__loading" *ngIf="loading">
    <div class="card-body">
      <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
    </div>
  </div>
  <div class="page-view__body" *ngIf="!loading">
    <form [formGroup]="appOneForm" (ngSubmit)="submitBtn.nativeElement.focus(); onSubmit()">
      <fieldset [disabled]="readonly">
        <app-cc-radio
          [options]="patientTypes"
          [disabled]="readonly"
          [group]="appOneForm"
          (changed)="setPatientTypeValidations($event)"
          controlName="applicantType">
        </app-cc-radio>
        <div class="row" *ngIf="appOneForm.value && appOneForm.value.applicantType == 'responsibleParty'">
          <div class="col-xs-12 col-md-6 col-lg-6">
            <app-cc-select
              controlName="patientRelationship"
              [group]="appOneForm"
              [options]="patientRelationships"
              klass="select relationship"
              [isRequired]="true"
              label="Relationship To Patient"
            >
            </app-cc-select>
          </div>
        </div>
        <app-patient-info-form [patientInfoForm]="patientInfoForm"></app-patient-info-form>
        <div *ngIf="appOneForm.value && appOneForm.value.applicantType == 'responsibleParty'">
          <div class="section-heading treatment">Patient Information</div>
          <app-patient-info-form [patientInfoForm]="patientInfoForm2" [enforce18Over]="false"></app-patient-info-form>
        </div>
        <div class="section-heading treatment">Treatment Information</div>
        <div class="row">
          <div class="col-md-6 col-xs-12 col-lg-6">
            <app-cc-date-picker
              controlName="serviceDate"
              [group]="appOneForm"
              label="Service Date (MM/DD/YYYY)"
              placeholder="Service Date (MM/DD/YYYY)"
              [readonly]="readonly"
              [addDays]="60"
              [isRequired]="true">
            </app-cc-date-picker>
          </div>
          <div class="col-md-6 col-xs-12 col-lg-6">
            <app-cc-input
              controlName="doctor"
              [group]="appOneForm"
              label="Healthcare Professional">
            </app-cc-input>

          </div>
          <div class="col-md-6 col-xs-12 col-lg-6">
            <app-cc-input *ngIf="!showProductSelect"
                          controlName="treatments"
                          [group]="appOneForm"
                          label="Treatment"
                          [isRequired]="true"
                          errorMessage="Description is required"
            >
            </app-cc-input>
            <app-cc-select *ngIf="showProductSelect"
                           controlName="treatments"
                           [group]="appOneForm"
                           klass="treatments"
                           [options]="productSelect"
                           label="Treatments"
                           [isRequired]="true"
            >
            </app-cc-select>
          </div>
          <div class="col-md-6 col-xs-12 col-lg-6">
            <app-cc-input-decimal
              controlName="financeAmount"
              [group]="appOneForm"
              [min]="minLoanAmt"
              [max]="maxLoanAmt"
              [errorMessage]="amountErrorMessage"
              [isRequired]="true"
              klass="amount"
              label="Amount To Be Financed">
            </app-cc-input-decimal>
          </div>
          <div class="col-md-6 col-xs-12 col-lg-6">
            <app-cc-input
              controlName="branch"
              [group]="appOneForm"
              label="Location"
              [isRequired]="true"
              [readonly]="true">
            </app-cc-input>
          </div>
          <div
            *ngIf="((customerService.customer$ | async )?.assignLocationEnabled === 'True') && !readonly && roleService.hasRole('BOP_ASSIGN_LOCATION_ENABLED')"
            class="col-md-6 col-xs-12 col-lg-6 assign-location">
            <button class="button button-medium button-secondary" (click)="assignLocation()" type="button">
              Change Location
            </button>
          </div>
          <div class="col-md-6 col-xs-12 col-lg-6">
            <app-cc-input
              controlName="assignPatientId"
              [group]="appOneForm"
              [isRequired]="true"
              label="Assign Unique Patient ID"
              errorMessage="ID is required">
            </app-cc-input>
          </div>
        </div>
        <div class="app-type-container my-4">
          <app-cc-radio
            [disabled]="readonly"
            [options]="appTypes"
            [group]="appOneForm"
            controlName="appType"
            klass="app-type"
            (changed)="appTypeChange($event)"
          >
          </app-cc-radio>
          <div class="mb-3 m-2 checkboxes" *ngIf="appOneForm.controls.appType.value === 'Link' && !isAllyEnabled">
            <mat-checkbox formControlName="notifyEmail" class="mx-3" disableRipple>Email</mat-checkbox>
            <mat-checkbox formControlName="notifySms" class="mx-3" disableRipple>Cell Phone</mat-checkbox>
            <p class="text-danger m-0"
               *ngIf="!appOneForm.controls.notifyEmail.value && !appOneForm.controls.notifySms.value">
              One or more must be selected
            </p>
          </div>
        </div>
        <div *ngIf="!readonly" class="page-view__actions">
          <button class="button button-primary" type="submit"
                  #submitCta>{{isAllyEnabled ? 'Get started' : 'Continue'}}</button>
          <button class="button button-medium button-secondary" type="button" (click)="onCancel()">Back</button>
        </div>
      </fieldset>
    </form>
  </div>
</div>
