<div [formGroup]="group" class="mb-0" *ngIf="group">
  <div class="form-floating mb-1">
    <div class="error-message">
      <span *ngIf="group.controls[controlName].touched && group.controls[controlName]?.invalid">
        {{ errorMessage }}
      </span>
    </div>
    <input
      type="tel"
      maxlength="12"
      [mask]="mask"
      class="form-control form-control-sm"
      [ngClass]="group.controls[controlName].touched && group.controls[controlName]?.invalid ? 'is-invalid' : ''"
      [formControlName]="controlName"
      [id]="id"
      [placeholder]="placeholder"/>
    <label *ngIf="label" [for]="id"
           [ngClass]="{'required': isRequired}">{{label}}</label>
  </div>
</div>
