import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientInfoModalData } from 'src/app/models/app-modal-data';
import { LookupModel } from 'src/app/models/lookup-model';
import { PatientAppDetails } from 'src/app/models/patient-app-details';
import { ApiService } from 'src/app/services/api.service';
import { emailRegex, getDateObjFromString, phoneRegex } from 'src/app/services/util.service';
import { MainService } from 'src/app/services/main.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../services/auth.service';
import * as moment from 'moment';
import { CustomerService } from '../../services/customer.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-resend-invite-modal',
  templateUrl: './resend-invite-modal.component.html',
  styleUrls: ['./resend-invite-modal.component.scss']
})
export class ResendInviteModalComponent implements OnInit {
  @ViewChild('submitCta') submitBtn: ElementRef<HTMLButtonElement>;
  stage = 'edit';
  iconCheck = faCircleCheck as IconProp;
  resendForm: UntypedFormGroup;

  loading = true;
  info: PatientAppDetails = new PatientAppDetails;
  languages: LookupModel[] = [{ id: 'EN', desc: 'English' }, { id: 'ES', desc: 'Spanish' }];
  showLang: boolean = false;
  resendTypes: LookupModel[];
  originalResendTypes: LookupModel[] = [{ id: 'Link', desc: 'Send application link (at least one must be selected)' },
  { id: 'Device', desc: 'Continue application on this device' }];
  minDate: Date = new Date();
  maxDate: Date = new Date();
  error = '';
  startUrl = '';
  isAllyEnabled = false;
  hideContinueDeviceBtn = false;

  constructor(public dialogRef: MatDialogRef<ResendInviteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatientInfoModalData,
    private fb: UntypedFormBuilder,
    private main: MainService,
    private apiService: ApiService,
    private authService: AuthService,
    private customerService: CustomerService
  ) {
    this.resendTypes = this.originalResendTypes;
    this.maxDate.setDate(new Date().getDate() + 90);
  }

  ngOnInit(): void {
    this.loading = true;
    this.error = '';

    let dataSub = combineLatest([this.apiService.get(`app/patient-app-details/${this.data.customerGuid}/${this.data.branchGuid}/${this.data.patientGuid}`),
    this.customerService.customer$])
      .subscribe({
        next: ([patient, cust]) => {
          this.info = patient;
          this.initForm();
          if (cust && cust.customerGUID) {
            this.isAllyEnabled = cust.isAllyEnabled.toLowerCase() === 'true';
            if (this.isAllyEnabled) {
              let isCA = this.data.branchState.trim() == 'California' || this.data.branchState.trim() == 'CA';
              this.checkAllyCAOptions(isCA);
              this.resendForm.controls.resendType.markAsTouched();
              this.resendForm.controls.resendType.updateValueAndValidity();
            }
          }
          this.loading = false;
        },
        error: (err: any) => {
          console.error(err);
          this.error = 'Error! Unable to get patient/customer details.';
          this.loading = false;
        },
        complete: () => { dataSub.unsubscribe(); }
      });
  }

  checkAllyCAOptions(isCalifornia: boolean = false) {
    this.resendTypes = this.originalResendTypes;
    const appType = this.resendTypes[0];
    appType.desc = 'Email application link';
    if (isCalifornia) {
      this.resendTypes = [appType];
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  initForm() {
    var existingServiceDate = getDateObjFromString(this.info.serviceDate);
    this.maxDate = new Date(existingServiceDate);
    var max = new Date(existingServiceDate).getDate();
    this.maxDate.setDate(max);

    this.resendForm = this.fb.group({
      email: [this.info?.emailAddress, [Validators.required, Validators.pattern(emailRegex)]],
      phone: [this.info?.phoneNumber],
      serviceDate: [existingServiceDate, Validators.required],
      notifyEmail: [true],
      notifySms: [!this.isAllyEnabled],
      language: ['EN'],
      // resendType: ['Link']
      resendType: new UntypedFormControl('Link', { updateOn: 'change' }),
    }, { updateOn: 'blur' });

    let phoneCtrl = this.resendForm.get('phone');
    if (!this.isAllyEnabled) {
      phoneCtrl.setValidators([Validators.required, Validators.pattern(phoneRegex)]);
    }
    else {
      phoneCtrl.clearValidators();
    }
    phoneCtrl.updateValueAndValidity();

  }

  onSubmit() {
    if (!this.resendForm.valid) {
      this.resendForm.markAllAsTouched();
      return;
    }

    const sDate = this.resendForm.value.serviceDate;
    const serviceDate = sDate && moment(sDate).format('MM/DD/YYYY');

    const obj = {
      "resend": {
        "email": this.resendForm.value.email,
        "mobile": this.resendForm.value.phone,
        "servicedate": serviceDate,
        "sendlinkapp": this.resendForm.value.resendType === 'Link' ? 0 : 1,
        "sendlinkapp_email": this.resendForm.value.notifyEmail ? 1 : 0,
        "sendlinkapp_sms": this.resendForm.value.notifySms ? 1 : 0,
        "continueondevice": this.resendForm.value.resendType === 'Link' ? 0 : 1,
        "language": this.resendForm.value.language
      }
    }
    this.loading = true;
    const json = JSON.stringify(JSON.stringify(obj));
    const path = `app/resend-invite/${this.data.customerGuid}/${this.data.branchGuid}/${this.data.patientGuid}`;
    this.apiService.post(path, json)
      .subscribe((res: any) => {
        this.loading = false;
        if (res) {
          if (res.COACK && res.COACK.ERRSTATUS && res.COACK.ERRSTATUS.ERRORNUM == 0) {
            this.startUrl = res.COACK.ERRSTATUS.STARTURL;
            if (this.resendForm.value.resendType === 'Link') {
              this.stage = 'complete';
              this.main.refreshApps(true);
              this.startUrl = res.COACK.ERRSTATUS.STARTURL;
            }
            else {
              this.stayHere();
            }
          } else {
            this.error = res.COACK.ERRSTATUS.ERRORDESCRIPTION || "Unknown Error Occurred";
          }
        } else {
          // this.resetStage();
        }

      },
        (err: any) => {
          console.error(err);
          this.error = err.error.message || "Unknown error occurred";
          this.loading = false;
          // this.resetStage();
        }
      );
  }

  resendTypeChange(resendType?: string) {
    this.resendForm.controls.notifyEmail.setValue(resendType === 'Link');
    this.resendForm.controls.notifySms.setValue(resendType === 'Link' && !this.isAllyEnabled);
  }

  stayHere() {
    this.authService.logout(this.startUrl);
  }
}
