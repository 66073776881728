<div [formGroup]="group" class="mb-4">
  <div class="form-floating mb-1">
    <div class="error-message">
      <span *ngIf="group.controls[controlName].touched && group.controls[controlName]?.invalid">
        {{errorMessage}}
      </span>
    </div>
    <input
      type="tel"
      [min]="min"
      [max]="max"
      class="form-control form-control-sm {{klass}}"
      [ngClass]="group && group.controls[controlName].touched && group.controls[controlName]?.invalid ? 'is-invalid' : ''"
      [formControlName]="controlName"
      (blur)="onBlur()"
      [id]="id"
      [placeholder]="placeholder">
    <span class="dollar">$</span>
    <label [for]="id" [ngClass]="{'required': isRequired}"><span>&nbsp;&nbsp;</span>{{label}}</label>
  </div>
</div>
