import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {MainService} from '../../../services/main.service';
import {faCircleCheck} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  iconCheck = faCircleCheck as IconProp;

  constructor(
    private main: MainService,
    public dialogRef: MatDialogRef<SuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { header: '', message: '' },
  ) {
  }

  ngOnInit(): void {
  }

  cancel() {
    this.main.removeClassFromBody();
    this.dialogRef.close('updated');
  }

}
