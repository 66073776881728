<div id="adjustmentModal" class="page-modal-content">
  <div class="page-view__loading card" *ngIf="loading">
    <div class="card-body">
      <mat-progress-bar mode="indeterminate" class="py-2 my-3"></mat-progress-bar>
    </div>
  </div>
  <div *ngIf="!loading">
    <div *ngIf="stage === 'confirm'">
      <h2>Verify adjustment</h2>
      <p class="description">Please confirm that the adjusted amount is accurate before
        hitting “Confirm”.</p>
      <div class="section procedure">
        <div class="item">
          <div class="label">Current Financed Amount</div>
          <div class="value">${{data!.patientInfo!.loanAmtInitial | formatMoney}}</div>
        </div>
        <div class="item highlight">
          <div class="label">New amount financed</div>
          <div class="value">${{data.newAmount  | formatMoney}}</div>
        </div>
        <div class="item">
          <div class="label">Service Date</div>
          <div class="value">{{data.serviceDate | date:'MM/dd/yyyy'}}</div>
        </div>
      </div>
      <div class="actions">
        <button class="button button-primary" type="submit" (click)="submit()">Confirm</button>
        <button class="button button-medium button-secondary" type="button" (click)="cancel()">
          Back
        </button>
      </div>
    </div>
    <div *ngIf="stage === 'complete'">
      <h2>Adjustment Submitted</h2>
      <p class="description">The adjustment has been sent successfully.</p>
      <fa-icon [icon]="iconCheck" size="5x" class="success"></fa-icon>
      <div class="actions">
        <button class="button button-primary" (click)="submit()">Okay</button>
        <button class="button button-medium button-secondary" (click)="stayHere()" *ngIf="startUrl">
          Continue On This Device
        </button>
      </div>
    </div>
    <div *ngIf="stage === 'error'">
      <h2>Adjustment Error</h2>
      <p class="description">Unable to make adjustment. See log for details.</p>
      <fa-icon [icon]="iconTimes" size="5x" [classes]="['m-3 text-red-500']"></fa-icon>
      <div class="actions">
        <button class="button button-primary" (click)="cancel()">Okay</button>
      </div>
      <p class="text-xs">Error message: {{error}}</p>
    </div>
  </div>
</div>
