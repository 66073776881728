import { Component, Input, OnInit, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
@Component({

  selector: 'app-cc-date-picker',
  templateUrl: './cc-date-picker.component.html',
  styleUrls: ['./cc-date-picker.component.scss']
})
export class CcDatePickerComponent implements AfterViewChecked {
  @Input() id?: string = '';
  @Input() controlName: string = '';
  @Input() minDate: Date = new Date();
  @Input() maxDate: Date = new Date();
  @Input() group: UntypedFormGroup = new UntypedFormGroup({});
  @Input() errorMessage: string = 'This field is invalid';
  @Input() isSpecial = false;
  @Input() placeholder = '';
  @Input() label = '';
  @Input() isRequired: boolean = false;
  @Input() readonly = false;
  @Input() addDays = 0;
  @Input() addYears = 0;
  @Input() klass = '';

  private valueUpdated: boolean;
  private selectionStart: number;
  private selectionEnd: number;
  private selectionDirection: 'forward' | 'backward' | 'none';
  private inputType : string;


  @ViewChild('myinput') inputRef : ElementRef<HTMLInputElement>;
  
  constructor() {
    const d = new Date();
    this.id = d.getTime().toString();
    this.valueUpdated = false;
    this.selectionStart = 0;
    this.selectionEnd = 0;
    this.selectionDirection = 'forward';
    this.inputType = "insert";
    //this.inputRef = new ElementRef<HTMLInputElement>({} as HTMLInputElement);
  }

  ngOnInit(): void {
    this.enableDates();
    
    if (this.controlName === 'serviceDate') {
      this.errorMessage = 'Select a future date and must be between ' + this.minDate.toLocaleDateString('en-029') + ' and ' + this.maxDate.toLocaleDateString('en-029');
    } 
  }

  ngAfterViewChecked() {
    if (this.valueUpdated && this.inputRef.nativeElement) {

      this.valueUpdated = false;
      Promise.resolve().then(() => {
        // Make sure you update this to deal with colons
        this.inputRef.nativeElement.setSelectionRange(
          this.selectionStart || 0,
          this.selectionEnd || 0,
          this.selectionDirection
        );
      });
    }
  }

  onKeyUp(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    var origLength = input.value.length;
    const dateFormat = /^([0-9]{0,2})(\/[0-9]{0,2})?(\/[0-9]{0,4})?/;
    let trimmed = input.value.replace(/\s+/g, '');
    trimmed = trimmed.replace(/[^0-9]*/g, '');
    if (trimmed.length > 8) {
      trimmed = trimmed.slice(0, 8);
    }
    let numbers = [];
    trimmed = trimmed.replace(/-/g, '');
    var match = input.value.match(dateFormat)
    if ((match == null || match.length == 0 || match[0].length < input.value.length) &&
    (this.inputType == "insertText" || this.inputType == "insertFromPaste")) {
      var offset = input.selectionStart || 0;

      switch (trimmed.length) {
        case 0:
        case 1:
          input.value = trimmed;
          break;
        case 2:
          input.value = trimmed + "/";
          break;
        case 3:
          numbers.push(trimmed.slice(0, 2));
          numbers.push(trimmed.slice(2));
          input.value = numbers.join('/');
          break;
        case 4:
          numbers.push(trimmed.slice(0, 2));
          numbers.push(trimmed.slice(2));
          input.value = numbers.join('/') + '/';
          break;
        case 5:
        case 6:
        case 7:
        case 8:
          numbers.push(trimmed.slice(0, 2));
          numbers.push(trimmed.slice(2, 4));
          numbers.push(trimmed.slice(4));
          input.value = numbers.join('/');
          break;
      }
      this.selectionStart = offset + input.value.length - origLength;
      this.selectionEnd = this.selectionStart;
      this.valueUpdated = true;
    }
    return true;
  }

  onKeyPress(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    const numRegex = /[0-9\/]+/;
    // if (event?.key === "Backspace") {
    //   return true;
    // }
    this.inputType = event.inputType;
    if ((event.inputType === "insertText" || event.inputType === "insertFromPaste") && (event.data != null)) {
      var match = event.data?.match(numRegex);
      if (match == null || match?.length == 0 || match[0].length < event.data.length) {
        return false;
      }
      return true;
    }
    return true;
  }

  onBlur(event: Event) {
    var input = event.target as HTMLInputElement;
    if (!input?.value) {
      return false;
    }
    var numbers = input.value.split('/');
    if (numbers.length == 3) {
      numbers[2] = numbers[2].slice(0, 4);
    }

    var date = new Date(numbers.join('/'));
    if (!date) {
      return false;
    }
    var dateString = date.toLocaleDateString('en-US', { year: "numeric", month: "2-digit", day: "2-digit" });
    if (dateString != "Invalid Date") {
      input.value = dateString;
    }
    return true;
  }

  enableDates() {
    if (this.addDays !== 0) {
      if (this.addDays < 0) {
        this.minDate.setDate(new Date().getDate() + this.addDays);
      } else {
        this.maxDate.setDate(new Date().getDate() + this.addDays);
      }
    }

    if (this.addYears !== 0) {
      if (this.addYears < 0) {
        this.minDate = new Date(this.minDate.setFullYear(this.minDate.getFullYear() + this.addYears));
      } else {
        this.maxDate = new Date(this.maxDate.setFullYear(this.maxDate.getFullYear() + this.addYears));
      }
    }
  }

  get control() {
    return this.group.get(this.controlName) as UntypedFormControl
  }
}
