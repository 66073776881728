<div class="page-modal-content cancel-app">
  <mat-progress-bar mode="indeterminate" class="py-2 my-5" *ngIf="loading"></mat-progress-bar>
  <div *ngIf="!loading">
    <div class="cancel-confirm" *ngIf="stage === 'confirmed'">
      <h2>Are you sure?</h2>
      <p class="description">Canceling a loan can’t be undone.</p>
      <app-error-banner *ngIf="errorMessage && errorMessage !== 'Success'"
                        [message]="errorMessage"></app-error-banner>
      <form [formGroup]="confirmForm" class="amount-form" (ngSubmit)="onSubmit()">
        <app-cc-select
          controlName="reason"
          [group]="confirmForm"
          type="reasons"
          klass="select reason"
          label="Reason for adjustment"
          [options]="reasons"
          [isRequired]="true"
        >
        </app-cc-select>
        <div class="actions">
          <button class="button button-primary" type="submit">yes i’m sure</button>
          <button class="button button-medium button-secondary" (click)="cancel()" [disabled]="loading">
            Nevermind, Take me back
          </button>
        </div>
      </form>
    </div>
    <div class="cancel-success" *ngIf="stage === 'success'">
      <h2>Loan cancelled sucessfully</h2>
      <fa-icon [icon]="iconCheck" size="5x" class="success"></fa-icon>
      <div class="actions">
        <button class="button button-primary" (click)="cancel()">Okay</button>
      </div>
    </div>
  </div>
</div>
