import {createAction, props} from '@ngrx/store';
import {ICrossroadLink} from '../../models/customer-model';

export const LOAD_CROSSROADS = '[Crossroads] Load Crossroads';
export const LOAD_CROSSROADS_SUCCESS = '[Crossroads] Load Crossroads Success';

export const loadCrossroads = createAction(LOAD_CROSSROADS, props<{ customerGuid: string | null }>());
export const loadCrossroadsSuccess = createAction(
  LOAD_CROSSROADS_SUCCESS,
  props<{ links: ICrossroadLink[] }>()
);
export const dummyAction = createAction('[dummy action]');
