import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export const nameRegex = "^[a-zA-Z-,'\\s]{2,30}$";
export const phoneRegex = "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$";
export const ssnRegex = '^(\\d{3}-?\\d{2}-?\\d{4}|XXX-XX-XXXX)$';
export const emailRegex = '^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$';

export const getDateFromStr = (val: string) => {
  if (!val) return null;
  if (val.indexOf('/') !== -1) {

    var year = 0;
    var arr = val.split('/');
    var mn = Number(arr[0]);
    var day = Number(arr[1]);
    if (arr[2].length > 4)
      year = Number(arr[2].substring(0, 4));
    else
      year = Number(arr[2]);
    return new Date(year, mn - 1, day);
  }

  var parsed = Date.parse(val);
  return new Date(parsed);

}

export function fromISODate(dt: string){
  if (!dt) return dt;
  
  var res = dt.substring(0, 10);
  var arr = res.split('-');
  return `${arr[1]}/${arr[2]}/${arr[0]}`
}

export const maskPhone = (val: string) => {
  let trimmed = val.replace(/\s+/g, '');
  trimmed = trimmed.replace(/[^0-9]*/g, '');
  if (trimmed.length > 12) {
    trimmed = trimmed.substr(0, 12);
  }

  trimmed = trimmed.replace(/-/g, '');
  let numbers = [];

  numbers.push(trimmed.substr(0, 3));
  if (trimmed.substr(3, 3) !== "")
    numbers.push(trimmed.substr(3, 3));
  if (trimmed.substr(6, 4) != "")
    numbers.push(trimmed.substr(6, 4));
  var result = numbers.join('-');
  return result;
}

export const maskDate = (val: string) => {
  let trimmed = val.toString().replace(/\s+/g, '');
  trimmed = trimmed.replace(/[^0-9]*/g, '');
  if (trimmed.length > 10) {
    trimmed = trimmed.substr(0, 10);
  }

  trimmed = trimmed.replace(/-/g, '');
  let numbers = [];

  numbers.push(trimmed.substr(0, 2));
  if (trimmed.substr(2, 2) !== "")
    numbers.push(trimmed.substr(2, 2));
  if (trimmed.substr(4, 4) != "")
    numbers.push(trimmed.substr(4, 4));
  var result = numbers.join('/');
  return result;
}

export const maskSsn = (val: string) => {
  let trimmed = val.replace(/\s+/g, '');
  trimmed = trimmed.replace(/[^0-9]*/g, '');
  if (trimmed.length > 11) {
    trimmed = trimmed.substr(0, 11);
  }

  trimmed = trimmed.replace(/-/g, '');
  let numbers = [];

  numbers.push(trimmed.substr(0, 3));
  if (trimmed.substr(3, 2) !== "")
    numbers.push(trimmed.substr(3, 2));
  if (trimmed.substr(5, 4) != "")
    numbers.push(trimmed.substr(5, 4));
  var result = numbers.join('-');
  return result;
}

export const maskDecimal = (value: string) => {
  if (!value) return value;

  let trimmed = value.toString().replace(/\s+/g, '');
  trimmed = Number(trimmed).toFixed(2).toString();
  var beforeDecimal = trimmed;
  var afterDecimal = '00';
  var hasDecimal = trimmed.indexOf('.') !== -1;
  if (hasDecimal) {
    beforeDecimal = trimmed.substring(0, trimmed.indexOf('.'));
    afterDecimal = trimmed.substring(trimmed.indexOf('.') + 1);
  }
  beforeDecimal = beforeDecimal.replace(/[^0-9]*/g, '');
  beforeDecimal = beforeDecimal.replace(/-/g, '');
  if (beforeDecimal.length <= 2) {
    return `${beforeDecimal}.${afterDecimal}`;
  }
  // var scale = trimmed.substring(trimmed.length - 2);
  // var nums = trimmed.substring(0, trimmed.length - 2);
  var nums = beforeDecimal;
  var numSet = [];
  let numbers = [];
  for (var i = nums.length - 1; i >= 0; i--) {
    numSet.unshift(nums[i]);
    if (numSet.length == 3) {
      numbers.unshift(numSet.join(''));
      numSet = [];
    }
  }
  if (numSet.length)
    numbers.unshift(numSet.join(''));
  var result = `${numbers.join(',')}`;
  return `${result}.${afterDecimal}`;
}

export function validateMinMax(minVal: number, maxVal: number, override: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (override) return null;

    var value = control.value && control.value.indexOf(",") ? control.value.replace(',', '') : control.value;
    var amount = Number(value || 0);
    var min = Number(minVal || 0);
    var max = Number(maxVal || Number.MAX_VALUE);
    if (amount < min || amount > max) {
      return {minMaxError: {value: control.value}}
    }
    return null;
  };
}

export function capitalizeFirstLetter(str: string | null | undefined) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getDateObjFromString(str: string) {
  var dt = str.split(' ');
  var parts = [];
  if (dt[0].indexOf('-') !== -1) {
    parts = dt[0].split('-');
  } else {
    parts = dt[0].split('/');
  }
  var sDate = new Date(Number(parts[2]), Number(parts[0]) - 1, Number(parts[1]));
  return sDate;
}

export const getDateFormat = (dt: Date) => {
  if (!dt) return dt;

  var val = dt.toLocaleDateString('en-029');
  var arr = val.split('/');
  var m = Number(arr[0]) < 10 && arr[0].length == 1 ? `0${arr[0]}` : arr[0];
  var d = Number(arr[1]) < 10 && arr[1].length == 1 ? `0${arr[1]}` : arr[1];
  var y = arr[2];
  return `${m}/${d}/${y}`;
}
